@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_default_styles {
  --mixin-qP3g6Hd5AdC_text-decoration-line: none;
  --mixin-qP3g6Hd5AdC_font-family: "Inter", sans-serif;
  --mixin-qP3g6Hd5AdC_color: var(--token-0IloF6TmFvF);
  --mixin-qP3g6Hd5AdC_font-size: 12px;
  --mixin-qP3g6Hd5AdC_white-space: pre-wrap;
  --mixin-qP3g6Hd5AdC_line-height: 1.5;
  --mixin-2zEfljePq9P_color: var(--token-VUsIDivgUss);
  --mixin-2zEfljePq9P_white-space: pre-wrap;
  --mixin-EEKi5Tu2fbK_white-space: pre-wrap;
  --mixin-YQD_Uc8Md__font-family: "Inter", sans-serif;
  --mixin-YQD_Uc8Md__font-size: 72px;
  --mixin-YQD_Uc8Md__font-weight: 500;
  --mixin-YQD_Uc8Md__white-space: pre-wrap;
  --mixin-vEOXQLfcbC_font-family: "Inter", sans-serif;
  --mixin-vEOXQLfcbC_font-size: 48px;
  --mixin-vEOXQLfcbC_font-weight: 500;
  --mixin-vEOXQLfcbC_white-space: pre-wrap;
  --mixin-EXCWDILscU_font-family: "Inter", sans-serif;
  --mixin-EXCWDILscU_font-size: 32px;
  --mixin-EXCWDILscU_font-weight: 500;
  --mixin-EXCWDILscU_white-space: pre-wrap;
  --mixin-N7cG0Ri48QP_font-family: "Inter", sans-serif;
  --mixin-N7cG0Ri48QP_font-size: 24px;
  --mixin-N7cG0Ri48QP_font-weight: 500;
  --mixin-N7cG0Ri48QP_white-space: pre-wrap;
  --mixin-__gfw12lSVA_font-family: "Inter", sans-serif;
  --mixin-__gfw12lSVA_font-size: 20px;
  --mixin-__gfw12lSVA_font-weight: 500;
  --mixin-__gfw12lSVA_white-space: pre-wrap;
  --mixin-eoQXVRNaCyL_font-family: "Inter", sans-serif;
  --mixin-eoQXVRNaCyL_font-size: 16px;
  --mixin-eoQXVRNaCyL_font-weight: 500;
  --mixin-eoQXVRNaCyL_white-space: pre-wrap;
  --mixin-fkU_lzw4PF5_white-space: pre-wrap;
  --mixin-v9e0yiTlX_o_white-space: pre-wrap;
  --mixin-MMatKfNT024_white-space: pre-wrap;
  --mixin-EuhGUWboGh2_position: relative;
  --mixin-EuhGUWboGh2_white-space: pre-wrap;
  --mixin-_MYD1z_SMDp_position: relative;
  --mixin-_MYD1z_SMDp_white-space: pre-wrap;
  --mixin-Yot8xJYsc_white-space: pre-wrap;
  --mixin-985HZFQW4_white-space: pre-wrap;
  --mixin-3i6_2FI7G_white-space: pre-wrap;
  --mixin-3HZrBcpB6_white-space: pre-wrap;
  --mixin-n1REaG4FH_white-space: pre-wrap;
  --mixin-Hk5zzHaLS_white-space: pre-wrap;
  --mixin-B4DR1AgPG_white-space: pre-wrap;
  --mixin-bhSle9dw7_white-space: pre-wrap;
  --mixin-5d8gGYi39_white-space: pre-wrap;
  --mixin-sxjZ0YFFF_white-space: pre-wrap;
  --mixin-GZm4AQ_Ek_white-space: pre-wrap;
  --mixin-qjB654aOL_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
.root_reset {
  font-family: var(--mixin-qP3g6Hd5AdC_font-family);
  font-size: var(--mixin-qP3g6Hd5AdC_font-size);
  color: var(--mixin-qP3g6Hd5AdC_color);
  line-height: var(--mixin-qP3g6Hd5AdC_line-height);
  white-space: var(--mixin-qP3g6Hd5AdC_white-space);
}

:where(.root_reset .plasmic_default__a),
:where(.root_reset .a),
:where(.root_reset :global(.__wab_expr_html_text) a),
:where(.root_reset.plasmic_default__a) {
  color: var(--mixin-2zEfljePq9P_color);
}

:where(.root_reset .plasmic_default__a:hover),
:where(.root_reset .a:hover),
:where(.root_reset :global(.__wab_expr_html_text) a:hover),
:where(.root_reset.plasmic_default__a:hover) {
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset :global(.__wab_expr_html_text) h1),
:where(.root_reset.plasmic_default__h1) {
  font-family: var(--mixin-YQD_Uc8Md__font-family);
  font-size: var(--mixin-YQD_Uc8Md__font-size);
  font-weight: var(--mixin-YQD_Uc8Md__font-weight);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset :global(.__wab_expr_html_text) h2),
:where(.root_reset.plasmic_default__h2) {
  font-family: var(--mixin-vEOXQLfcbC_font-family);
  font-size: var(--mixin-vEOXQLfcbC_font-size);
  font-weight: var(--mixin-vEOXQLfcbC_font-weight);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset :global(.__wab_expr_html_text) h3),
:where(.root_reset.plasmic_default__h3) {
  font-family: var(--mixin-EXCWDILscU_font-family);
  font-size: var(--mixin-EXCWDILscU_font-size);
  font-weight: var(--mixin-EXCWDILscU_font-weight);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset :global(.__wab_expr_html_text) h4),
:where(.root_reset.plasmic_default__h4) {
  font-family: var(--mixin-N7cG0Ri48QP_font-family);
  font-size: var(--mixin-N7cG0Ri48QP_font-size);
  font-weight: var(--mixin-N7cG0Ri48QP_font-weight);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset :global(.__wab_expr_html_text) h5),
:where(.root_reset.plasmic_default__h5) {
  font-family: var(--mixin-__gfw12lSVA_font-family);
  font-size: var(--mixin-__gfw12lSVA_font-size);
  font-weight: var(--mixin-__gfw12lSVA_font-weight);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset :global(.__wab_expr_html_text) h6),
:where(.root_reset.plasmic_default__h6) {
  font-family: var(--mixin-eoQXVRNaCyL_font-family);
  font-size: var(--mixin-eoQXVRNaCyL_font-size);
  font-weight: var(--mixin-eoQXVRNaCyL_font-weight);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset :global(.__wab_expr_html_text) blockquote),
:where(.root_reset.plasmic_default__blockquote) {
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset :global(.__wab_expr_html_text) code),
:where(.root_reset.plasmic_default__code) {
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset :global(.__wab_expr_html_text) pre),
:where(.root_reset.plasmic_default__pre) {
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset :global(.__wab_expr_html_text) ol),
:where(.root_reset.plasmic_default__ol) {
  position: var(--mixin-EuhGUWboGh2_position);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset :global(.__wab_expr_html_text) ul),
:where(.root_reset.plasmic_default__ul) {
  position: var(--mixin-_MYD1z_SMDp_position);
}

:where(.root_reset .plasmic_default__a:not(:hover)),
:where(.root_reset .a:not(:hover)),
:where(.root_reset :global(.__wab_expr_html_text) a:not(:hover)),
:where(.root_reset.plasmic_default__a:not(:hover)) {
}

:where(.root_reset .plasmic_default__a:active),
:where(.root_reset .a:active),
:where(.root_reset :global(.__wab_expr_html_text) a:active),
:where(.root_reset.plasmic_default__a:active) {
}

:where(.root_reset .plasmic_default__a:not(:active)),
:where(.root_reset .a:not(:active)),
:where(.root_reset :global(.__wab_expr_html_text) a:not(:active)),
:where(.root_reset.plasmic_default__a:not(:active)) {
}

:where(.root_reset .plasmic_default__a:focus),
:where(.root_reset .a:focus),
:where(.root_reset :global(.__wab_expr_html_text) a:focus),
:where(.root_reset.plasmic_default__a:focus) {
}

:where(.root_reset .plasmic_default__a:not(:link)),
:where(.root_reset .a:not(:link)),
:where(.root_reset :global(.__wab_expr_html_text) a:not(:link)),
:where(.root_reset.plasmic_default__a:not(:link)) {
}

:where(.root_reset .plasmic_default__blockquote:not(:link)),
:where(.root_reset .blockquote:not(:link)),
:where(.root_reset :global(.__wab_expr_html_text) blockquote:not(:link)),
:where(.root_reset.plasmic_default__blockquote:not(:link)) {
}

:where(.root_reset .plasmic_default__blockquote:hover),
:where(.root_reset .blockquote:hover),
:where(.root_reset :global(.__wab_expr_html_text) blockquote:hover),
:where(.root_reset.plasmic_default__blockquote:hover) {
}

:where(.root_reset .plasmic_default__code:hover),
:where(.root_reset .code:hover),
:where(.root_reset :global(.__wab_expr_html_text) code:hover),
:where(.root_reset.plasmic_default__code:hover) {
}

:where(.root_reset .plasmic_default__li),
:where(.root_reset .li),
:where(.root_reset :global(.__wab_expr_html_text) li),
:where(.root_reset.plasmic_default__li) {
}

:where(.root_reset .plasmic_default__p),
:where(.root_reset .p),
:where(.root_reset :global(.__wab_expr_html_text) p),
:where(.root_reset.plasmic_default__p) {
}

:where(.root_reset .plasmic_default__ul:hover),
:where(.root_reset .ul:hover),
:where(.root_reset :global(.__wab_expr_html_text) ul:hover),
:where(.root_reset.plasmic_default__ul:hover) {
}

:where(.root_reset .plasmic_default__li:hover),
:where(.root_reset .li:hover),
:where(.root_reset :global(.__wab_expr_html_text) li:hover),
:where(.root_reset.plasmic_default__li:hover) {
}
