.viewDocs {
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  padding: 8px 8px 8px 12px;
}
.viewDocs > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.viewDocs > :global(.__wab_flex-container) > *,
.viewDocs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.viewDocs > :global(.__wab_flex-container) > picture > img,
.viewDocs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.viewDocs:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.slotTargetText {
  font-size: 14px;
  color: var(--token-UunsGa2Y3t3);
  line-height: 20px;
}
.viewDocs:hover .slotTargetText {
  color: var(--token-D666zt2IZPL);
}
.viewDocs:hover .slotTargetText > :global(.__wab_text),
.viewDocs:hover .slotTargetText > :global(.__wab_expr_html_text),
.viewDocs:hover .slotTargetText > :global(.__wab_slot-string-wrapper),
.viewDocs:hover .slotTargetText > :global(.__wab_slot) > :global(.__wab_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocs:hover
  .slotTargetText
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.viewDocsalt:hover .slotTargetTextalt {
  color: var(--token-0IloF6TmFvF);
}
.viewDocsalt:hover .slotTargetTextalt > :global(.__wab_text),
.viewDocsalt:hover .slotTargetTextalt > :global(.__wab_expr_html_text),
.viewDocsalt:hover .slotTargetTextalt > :global(.__wab_slot-string-wrapper),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.viewDocsalt:hover
  .slotTargetTextalt
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
}
.slotTargetIcon {
  color: var(--token-fVn5vRhXJxQ);
  font-size: 16px;
}
.viewDocs:hover .slotTargetIcon {
  color: var(--token-D666zt2IZPL);
}
.svg__edT07 {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-UunsGa2Y3t3);
  flex-shrink: 0;
}
