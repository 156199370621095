.root {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  transition-property: all;
  transition-duration: 1s;
  background: rgb(255, 255, 255);
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  height: auto;
}
.referenceItem__wCs9F:global(.__wab_instance) {
  position: relative;
}
.referenceItem__neR8:global(.__wab_instance) {
  position: relative;
}
.listSectionSeparator__fzv6I:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 9px;
  flex-shrink: 0;
}
.referenceItem__vzlUr:global(.__wab_instance) {
  position: relative;
}
.referenceItem__zvn0M:global(.__wab_instance) {
  position: relative;
}
.listSectionSeparator__ovwDe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 9px;
  flex-shrink: 0;
}
.referenceItem__hoMjp:global(.__wab_instance) {
  position: relative;
}
.listSectionSeparator__hxJsm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 9px;
  flex-shrink: 0;
}
.referenceItem__nvqG7:global(.__wab_instance) {
  position: relative;
}
.referenceItem__llpa3:global(.__wab_instance) {
  position: relative;
}
.referenceItem__fZfrj:global(.__wab_instance) {
  position: relative;
}
.text__jtGud {
  padding-bottom: 0px;
}
.referenceItem__gaIcX:global(.__wab_instance) {
  position: relative;
}
.listSectionSeparator___67Frw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 9px;
  flex-shrink: 0;
}
.referenceItem__ohg1X:global(.__wab_instance) {
  position: relative;
}
.listSectionSeparator__lHr48:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 9px;
  flex-shrink: 0;
}
.referenceItem__l6IUh:global(.__wab_instance) {
  position: relative;
}
