.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  align-items: center;
}
.rootisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root___focusVisibleWithin {
  outline: none;
}
.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 16px;
  flex-shrink: 0;
}
.root .toggle___focusVisibleWithin {
  outline: none;
  border-style: dashed;
  border-color: #007aff;
}
.track {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  transition-property: background;
  transition-duration: 0.2s;
  background: var(--token-eBt2ZgqRUCz);
  box-shadow: none;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.2s;
  border-radius: 16px;
}
.trackisChecked {
  background: var(--token-D666zt2IZPL);
}
.trackvalueSetState_isSet {
  box-shadow: none;
}
.trackvalueSetState_isUnset {
  box-shadow: none;
}
.trackvalueSetState_isInherited {
  box-shadow: none;
}
.root:hover .track {
  background: var(--token-PTyaboLP9ZK);
}
.rootisChecked:hover .trackisChecked {
  background: var(--token-mr2NiPmIlt2);
}
.root .track___focusVisibleWithin {
  box-shadow: inset 0px 0px 0px 1px #0091ff80;
  outline: none;
  border-color: #007aff;
}
.thumb {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  transition-property: all;
  transition-duration: 0.2s;
  background: var(--token-iR8SeEwQZ);
  box-shadow: 0px 0px 0px 1px var(--token-JrjdlBU-a5Ju);
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.thumbisChecked {
  left: 14px;
  right: auto;
  background: var(--token-iR8SeEwQZ);
}
.root .thumb___focusVisibleWithin {
  outline: none;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.root .labelContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildrenvalueSetState_isSet {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetChildrenvalueSetState_isUnset {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetChildrenvalueSetState_isInherited {
  color: var(--token-s0HBak9It);
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
