.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.rootimage_withImage {
  box-shadow: 0px 8px 24px -16px #00000038, 0px 8px 15px -16px #00000024;
  border-radius: 8px;
}
.rootwithDropShadow {
  box-shadow: 0px 8px 24px -16px #00000038, 0px 8px 15px -16px #00000024;
  border-radius: 8px;
}
.rootimage_withImage:hover {
  box-shadow: 0px 8px 32px -8px #00000038, 0px 8px 20px -16px #00000024;
}
.rootwithDropShadow:hover {
  box-shadow: 0px 8px 32px -8px #00000038, 0px 8px 20px -16px #00000024;
}
.root:focus {
  outline: none;
}
.rootimage_withImage:focus {
  outline: none;
}
.rootwithDropShadow:focus {
  outline: none;
}
.button {
  opacity: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: stretch;
  height: 100%;
  min-height: 0;
  padding: 0px;
  margin: 0px;
  border-width: 0px;
}
.buttonimage_withImage {
  padding-bottom: 0px;
}
.buttonwithDropShadow {
  padding-bottom: 0px;
}
.root:focus .button {
  outline: none;
}
.rootimage_withImage:focus .buttonimage_withImage {
  outline: none;
}
.rootwithDropShadow:focus .buttonwithDropShadow {
  outline: none;
}
.freeBox__vAmgL {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  background: var(--token-iR8SeEwQZ);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-shrink: 0;
  min-width: 0;
  padding: 0px;
}
.freeBoxwithDropShadow__vAmgLqdgSu {
  display: none;
}
.root:focus .freeBox__vAmgL {
  outline: none;
}
.rootimage_withImage:focus .freeBoximage_withImage__vAmgLPe6Kv {
  outline: none;
}
.rootwithDropShadow:focus .freeBoxwithDropShadow__vAmgLqdgSu {
  outline: none;
}
.img___8JfR1 {
  position: relative;
  width: 100%;
  height: 100%;
  object-position: center top;
  object-fit: cover;
  min-width: 0;
  min-height: 0;
}
.img___8JfR1 > picture > img {
  object-fit: cover;
  object-position: center top;
}
.freeBox__yWsHw {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-iR8SeEwQZ);
  transition-property: background;
  transition-duration: 0.1s;
  height: 100%;
  min-height: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.1s;
  border-radius: 8px;
}
.freeBoxtype_first__yWsHwnPTsW {
  background: var(--token-h-5XbcO2WhcA);
}
.freeBoxtype_second__yWsHwIg5DJ {
  background: var(--token-y1vKiLsumIj);
}
.freeBoxtype_third__yWsHw2NMma {
  background: var(--token-kHJfvUnoGiO);
}
.freeBoximage_withImage__yWsHwPe6Kv {
  border-radius: 0px 0px 8px 8px;
}
.freeBoxwithDropShadow__yWsHwQdgSu {
  border-radius: 0px 0px 8px 8px;
}
.roottype_first:hover .freeBoxtype_first__yWsHwnPTsW {
  background: var(--token-dv0BWWyaHl7H);
}
.roottype_second:hover .freeBoxtype_second__yWsHwIg5DJ {
  background: var(--token-JkjCicJF7tK);
}
.roottype_third:hover .freeBoxtype_third__yWsHw2NMma {
  background: var(--token-_x2R5Ogaeo5);
}
.root:focus .freeBox__yWsHw {
  outline: none;
}
.rootimage_withImage:focus .freeBoximage_withImage__yWsHwPe6Kv {
  outline: none;
}
.rootwithDropShadow:focus .freeBoxwithDropShadow__yWsHwQdgSu {
  outline: none;
}
.roottype_first:active .freeBoxtype_first__yWsHwnPTsW {
  background: var(--token-3AptjBfMqvPS);
}
.roottype_second:active .freeBoxtype_second__yWsHwIg5DJ {
  background: var(--token-8BJRlW0CGyAS);
}
.roottype_third:active .freeBoxtype_third__yWsHw2NMma {
  background: var(--token-wn5N3ILTddm);
}
.divider {
  position: relative;
  width: 100%;
  height: 1px;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  background: rgb(228, 230, 235);
  min-width: 0;
  flex-shrink: 0;
  display: none;
}
.dividertype_first {
  background: rgb(208, 247, 242);
}
.dividertype_second {
  background: rgb(203, 249, 215);
}
.dividertype_third {
  background: rgb(255, 242, 166);
}
.dividerimage_withImage {
  background: var(--token-hoA5qaM-91G);
}
.dividerwithDropShadow {
  background: var(--token-hoA5qaM-91G);
}
.roottype_first:hover .dividertype_first {
  background: rgb(42, 187, 167);
}
.roottype_second:hover .dividertype_second {
  background: rgb(69, 189, 98);
}
.roottype_third:hover .dividertype_third {
  background: rgb(247, 185, 40);
}
.root:focus .divider {
  outline: none;
}
.roottype_first:active .dividertype_first {
  background: rgb(42, 187, 167);
}
.roottype_second:active .dividertype_second {
  background: rgb(69, 189, 98);
}
.roottype_third:active .dividertype_third {
  background: rgb(247, 185, 40);
}
.freeBox__h0Gn {
  display: flex;
  height: 100%;
  min-height: 0;
  padding: 20px 16px;
}
.freeBox__h0Gn > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__h0Gn > :global(.__wab_flex-container) > *,
.freeBox__h0Gn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__h0Gn > :global(.__wab_flex-container) > picture > img,
.freeBox__h0Gn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxicon_withIcon__h0GNn8MlJ > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxicon_withIcon__h0GNn8MlJ > :global(.__wab_flex-container) > *,
.freeBoxicon_withIcon__h0GNn8MlJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxicon_withIcon__h0GNn8MlJ
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxicon_withIcon__h0GNn8MlJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.freeBoxshowPreview__h0GNtvNg7 > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: center;
}
.root:focus .freeBox__h0Gn {
  outline: none;
}
.freeBox___15Zn9 {
  display: flex;
  position: relative;
}
.freeBoxicon_withIcon___15Zn9N8MlJ {
  border-radius: 6px;
}
.freeBoxicon_withIcon_type_first___15Zn9N8MlJNPTsW {
  background: var(--token-dv0BWWyaHl7H);
}
.freeBoxtype_second_icon_withIcon___15Zn9Ig5DJN8MlJ {
  background: var(--token-JkjCicJF7tK);
}
.freeBoxtype_third_icon_withIcon___15Zn92NMmaN8MlJ {
  background: var(--token-_x2R5Ogaeo5);
}
.slotTargetChildrenicon_withIcon_type_first {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetChildrentype_second_icon_withIcon {
  color: var(--token-pZdFekfDfX21);
}
.slotTargetChildrentype_third_icon_withIcon {
  color: var(--token-TSEOUn2Kjps);
}
.svg__oYrWt {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.freeBox__wHm4F {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__wHm4F > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__wHm4F > :global(.__wab_flex-container) > *,
.freeBox__wHm4F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wHm4F > :global(.__wab_flex-container) > picture > img,
.freeBox__wHm4F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.root:focus .freeBox__wHm4F {
  outline: none;
}
.frame317 {
  opacity: 1;
  transform: scale(1, 1) rotate(0deg) translate(0px, 0px);
  transform-origin: top left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 0px;
}
.frame317 > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.frame317 > :global(.__wab_flex-container) > *,
.frame317 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame317 > :global(.__wab_flex-container) > picture > img,
.frame317
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.root:focus .frame317 {
  outline: none;
}
.slotTargetName {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
}
.slotTargetNametype_first {
  color: var(--token-AKguBXlLTkKh);
}
.slotTargetNametype_second {
  color: var(--token-JImKKlVvAhso);
}
.slotTargetNametype_third {
  color: var(--token-csYd16wjJi9);
}
.slotTargetNameimage_withImage {
  font-size: 11px;
  line-height: 16px;
}
.slotTargetNamewithDropShadow {
  font-size: 11px;
  line-height: 16px;
}
.text__fj0Dj {
  position: relative;
  left: auto;
  top: auto;
}
.frame308 {
  opacity: 1;
  transform: scale(1, 1) rotate(0deg) translate(0px, 0px);
  position: relative;
  transform-origin: top left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  padding: 0px;
}
.frame308 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame308 > :global(.__wab_flex-container) > *,
.frame308 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame308 > :global(.__wab_flex-container) > picture > img,
.frame308
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.slotTargetInstruction {
  text-align: left;
}
.slotTargetInstructionwithDescrip {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetInstructionwithDescrip_type_first {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetInstructiontype_second_withDescrip {
  color: var(--token-pZdFekfDfX21);
}
.slotTargetInstructiontype_third_withDescrip {
  color: var(--token-TSEOUn2Kjps);
}
.root:focus .slotTargetInstruction > *,
.root:focus .slotTargetInstruction > :global(.__wab_slot) > *,
.root:focus
  .slotTargetInstruction
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetInstruction
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetInstruction > picture > img,
.root:focus .slotTargetInstruction > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetInstruction
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetInstruction
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetPreviewIcon {
  color: var(--token-UunsGa2Y3t3);
}
.svg__llXDw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}
.bordersoutlinesstrokesPlaceholder {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition-property: border-color;
  transition-duration: 0.1s;
  min-width: 0;
  min-height: 0;
  display: none;
  -webkit-transition-property: border-color;
  -webkit-transition-duration: 0.1s;
  border-radius: 8px;
}
.bordersoutlinesstrokesPlaceholdertype_first {
  border: 1px solid var(--token-n4S9_H7k9rsR);
}
.bordersoutlinesstrokesPlaceholdertype_second {
  border: 1px solid var(--token-EXdSicMU4Kn4);
}
.bordersoutlinesstrokesPlaceholdertype_third {
  border: 1px solid var(--token-_KfwvRipM3e);
}
.bordersoutlinesstrokesPlaceholderimage_withImage {
  border: 1px solid var(--token-eBt2ZgqRUCz);
}
.bordersoutlinesstrokesPlaceholderwithDropShadow {
  border: 1px solid var(--token-eBt2ZgqRUCz);
}
.root:hover .bordersoutlinesstrokesPlaceholder {
  border-color: var(--token-PTyaboLP9ZK);
}
.roottype_first:hover .bordersoutlinesstrokesPlaceholdertype_first {
  box-shadow: none;
  border-color: var(--token-7z1QOgRqHDWD);
}
.roottype_second:hover .bordersoutlinesstrokesPlaceholdertype_second {
  box-shadow: none;
  border-color: var(--token-BBlfXifFkRyp);
}
.roottype_third:hover .bordersoutlinesstrokesPlaceholdertype_third {
  box-shadow: none;
  border-color: var(--token-aMA_Go-ENBl);
}
.root:focus .bordersoutlinesstrokesPlaceholder {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.rootimage_withImage:focus .bordersoutlinesstrokesPlaceholderimage_withImage {
  outline: none;
}
.rootwithDropShadow:focus .bordersoutlinesstrokesPlaceholderwithDropShadow {
  outline: none;
}
.root:active .bordersoutlinesstrokesPlaceholder {
  border-color: var(--token-PTyaboLP9ZK);
}
.roottype_first:active .bordersoutlinesstrokesPlaceholdertype_first {
  box-shadow: none;
  border-color: var(--token-n4S9_H7k9rsR);
}
.roottype_second:active .bordersoutlinesstrokesPlaceholdertype_second {
  box-shadow: none;
  border-color: var(--token-EXdSicMU4Kn4);
}
.roottype_third:active .bordersoutlinesstrokesPlaceholdertype_third {
  box-shadow: none;
  border-color: var(--token-_KfwvRipM3e);
}
