.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  min-width: 0;
  border-radius: 8px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.permissionRule__lhnJ2:global(.__wab_instance) {
  position: relative;
}
.permissionRule__nvdAj:global(.__wab_instance) {
  position: relative;
}
