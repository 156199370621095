@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-YU1H6d7MUtVL: 12px;
  --plasmic-token-normal-font-size: var(--token-YU1H6d7MUtVL);
  --token-bs5B88d0PFb-: 32px;
  --plasmic-token-h-3-font-size: var(--token-bs5B88d0PFb-);
  --token-eTQczG7SkS0J: 20px;
  --plasmic-token-h-5-font-size: var(--token-eTQczG7SkS0J);
  --token-FBT6YORk3zaj: 72px;
  --plasmic-token-h-1-font-size: var(--token-FBT6YORk3zaj);
  --token-VptBKSDIaVWr: 48px;
  --plasmic-token-h-2-font-size: var(--token-VptBKSDIaVWr);
  --token-BZpInOSee8Mz: 24px;
  --plasmic-token-h-4-font-size: var(--token-BZpInOSee8Mz);
  --token-EhILfm3mLM3w: 16px;
  --plasmic-token-h-6-font-size: var(--token-EhILfm3mLM3w);
  --token-AyOBufhd1IOg: 16px;
  --plasmic-token-medium-font-size: var(--token-AyOBufhd1IOg);
  --token-uOl9dOQ3904J: #161615;
  --plasmic-token-enterprise-background: var(--token-uOl9dOQ3904J);
  --token-mlyauzeAvywY: #36394a;
  --plasmic-token-neutral-600: var(--token-mlyauzeAvywY);
  --token-4wrna4fBEDIp: #666d80;
  --plasmic-token-neutral-500: var(--token-4wrna4fBEDIp);
}

@media (max-width: 768px) {
  .plasmic_tokens {
    --token-uOl9dOQ3904J: #272835;
    --plasmic-token-enterprise-background: var(--token-uOl9dOQ3904J);
  }
}

.plasmic_tokens:where(.global_environment_website) {
  --token-YU1H6d7MUtVL: 16px;
  --plasmic-token-normal-font-size: var(--token-YU1H6d7MUtVL);
  --token-bs5B88d0PFb-: 48px;
  --plasmic-token-h-3-font-size: var(--token-bs5B88d0PFb-);
  --token-eTQczG7SkS0J: 32px;
  --plasmic-token-h-5-font-size: var(--token-eTQczG7SkS0J);
  --token-FBT6YORk3zaj: 80px;
  --plasmic-token-h-1-font-size: var(--token-FBT6YORk3zaj);
  --token-VptBKSDIaVWr: 72px;
  --plasmic-token-h-2-font-size: var(--token-VptBKSDIaVWr);
  --token-BZpInOSee8Mz: 40px;
  --plasmic-token-h-4-font-size: var(--token-BZpInOSee8Mz);
  --token-EhILfm3mLM3w: 24px;
  --plasmic-token-h-6-font-size: var(--token-EhILfm3mLM3w);
  --token-AyOBufhd1IOg: 20px;
  --plasmic-token-medium-font-size: var(--token-AyOBufhd1IOg);
  --token-uOl9dOQ3904J: #0d0d12;
  --plasmic-token-enterprise-background: var(--token-uOl9dOQ3904J);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles,
.plasmic_default_styles:where(.global_environment_website) {
  --mixin-RBjx1wUxrAoL_font-family: "Inter", sans-serif;
  --mixin-RBjx1wUxrAoL_font-size: var(--token-YU1H6d7MUtVL);
  --mixin-RBjx1wUxrAoL_font-weight: 400;
  --mixin-RBjx1wUxrAoL_font-style: normal;
  --mixin-RBjx1wUxrAoL_color: var(--token-0IloF6TmFvF);
  --mixin-RBjx1wUxrAoL_text-align: left;
  --mixin-RBjx1wUxrAoL_text-transform: none;
  --mixin-RBjx1wUxrAoL_line-height: 1.5;
  --mixin-RBjx1wUxrAoL_letter-spacing: normal;
  --mixin-RBjx1wUxrAoL_white-space: pre-wrap;
  --mixin-RBjx1wUxrAoL_user-select: text;
  --mixin-RBjx1wUxrAoL_text-decoration-line: none;
  --mixin-RBjx1wUxrAoL_text-overflow: clip;
  --mixin-rX4aWk4Y-dum_color: #000000;
  --mixin-rX4aWk4Y-dum_font-weight: 700;
  --mixin-rX4aWk4Y-dum_font-size: var(--token-FBT6YORk3zaj);
  --mixin-rX4aWk4Y-dum_line-height: 1;
  --mixin-rX4aWk4Y-dum_letter-spacing: -1px;
  --mixin-rX4aWk4Y-dum_font-family: "Inter", sans-serif;
  --mixin-rX4aWk4Y-dum_white-space: pre-wrap;
  --mixin-FEQeiL-RWW2s_color: #000000;
  --mixin-FEQeiL-RWW2s_font-size: var(--token-VptBKSDIaVWr);
  --mixin-FEQeiL-RWW2s_font-weight: 700;
  --mixin-FEQeiL-RWW2s_letter-spacing: -0.5px;
  --mixin-FEQeiL-RWW2s_line-height: 1.1;
  --mixin-FEQeiL-RWW2s_font-family: "Inter", sans-serif;
  --mixin-FEQeiL-RWW2s_white-space: pre-wrap;
  --mixin-6aHhUSri_EHo_color: var(--token-VUsIDivgUss);
  --mixin-6aHhUSri_EHo_white-space: pre-wrap;
  --mixin-Z-seKnQRX53o_color: #000000;
  --mixin-Z-seKnQRX53o_font-size: var(--token-bs5B88d0PFb-);
  --mixin-Z-seKnQRX53o_font-weight: 600;
  --mixin-Z-seKnQRX53o_line-height: 1.2;
  --mixin-Z-seKnQRX53o_font-family: "Inter", sans-serif;
  --mixin-Z-seKnQRX53o_white-space: pre-wrap;
  --mixin-vyRZtXYbGmGi_color: #000000;
  --mixin-vyRZtXYbGmGi_font-size: var(--token-BZpInOSee8Mz);
  --mixin-vyRZtXYbGmGi_font-weight: 600;
  --mixin-vyRZtXYbGmGi_line-height: 1.3;
  --mixin-vyRZtXYbGmGi_font-family: "Inter", sans-serif;
  --mixin-vyRZtXYbGmGi_white-space: pre-wrap;
  --mixin-sed6VuY9C4ln_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-sed6VuY9C4ln_border-bottom-color: #dddddd;
  --mixin-sed6VuY9C4ln_border-bottom-style: solid;
  --mixin-sed6VuY9C4ln_border-bottom-width: 1px;
  --mixin-sed6VuY9C4ln_border-left-color: #dddddd;
  --mixin-sed6VuY9C4ln_border-left-style: solid;
  --mixin-sed6VuY9C4ln_border-left-width: 1px;
  --mixin-sed6VuY9C4ln_border-right-color: #dddddd;
  --mixin-sed6VuY9C4ln_border-right-style: solid;
  --mixin-sed6VuY9C4ln_border-right-width: 1px;
  --mixin-sed6VuY9C4ln_border-top-color: #dddddd;
  --mixin-sed6VuY9C4ln_border-top-style: solid;
  --mixin-sed6VuY9C4ln_border-top-width: 1px;
  --mixin-sed6VuY9C4ln_border-bottom-left-radius: 3px;
  --mixin-sed6VuY9C4ln_border-bottom-right-radius: 3px;
  --mixin-sed6VuY9C4ln_border-top-left-radius: 3px;
  --mixin-sed6VuY9C4ln_border-top-right-radius: 3px;
  --mixin-sed6VuY9C4ln_font-family: "Inconsolata";
  --mixin-sed6VuY9C4ln_padding-bottom: 1px;
  --mixin-sed6VuY9C4ln_padding-left: 4px;
  --mixin-sed6VuY9C4ln_padding-right: 4px;
  --mixin-sed6VuY9C4ln_padding-top: 1px;
  --mixin-sed6VuY9C4ln_white-space: pre-wrap;
  --mixin-37SwOrXL_yCf_border-left-color: #dddddd;
  --mixin-37SwOrXL_yCf_border-left-style: solid;
  --mixin-37SwOrXL_yCf_border-left-width: 3px;
  --mixin-37SwOrXL_yCf_color: #888888;
  --mixin-37SwOrXL_yCf_padding-left: 10px;
  --mixin-37SwOrXL_yCf_white-space: pre-wrap;
  --mixin--6ELTAP-wORP_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin--6ELTAP-wORP_border-bottom-color: #dddddd;
  --mixin--6ELTAP-wORP_border-bottom-style: solid;
  --mixin--6ELTAP-wORP_border-bottom-width: 1px;
  --mixin--6ELTAP-wORP_border-left-color: #dddddd;
  --mixin--6ELTAP-wORP_border-left-style: solid;
  --mixin--6ELTAP-wORP_border-left-width: 1px;
  --mixin--6ELTAP-wORP_border-right-color: #dddddd;
  --mixin--6ELTAP-wORP_border-right-style: solid;
  --mixin--6ELTAP-wORP_border-right-width: 1px;
  --mixin--6ELTAP-wORP_border-top-color: #dddddd;
  --mixin--6ELTAP-wORP_border-top-style: solid;
  --mixin--6ELTAP-wORP_border-top-width: 1px;
  --mixin--6ELTAP-wORP_border-bottom-left-radius: 3px;
  --mixin--6ELTAP-wORP_border-bottom-right-radius: 3px;
  --mixin--6ELTAP-wORP_border-top-left-radius: 3px;
  --mixin--6ELTAP-wORP_border-top-right-radius: 3px;
  --mixin--6ELTAP-wORP_font-family: "Inconsolata";
  --mixin--6ELTAP-wORP_padding-bottom: 3px;
  --mixin--6ELTAP-wORP_padding-left: 6px;
  --mixin--6ELTAP-wORP_padding-right: 6px;
  --mixin--6ELTAP-wORP_padding-top: 3px;
  --mixin--6ELTAP-wORP_white-space: pre-wrap;
  --mixin-18yImBnqN3GP_display: flex;
  --mixin-18yImBnqN3GP_flex-direction: column;
  --mixin-18yImBnqN3GP_align-items: stretch;
  --mixin-18yImBnqN3GP_justify-content: flex-start;
  --mixin-18yImBnqN3GP_list-style-position: outside;
  --mixin-18yImBnqN3GP_padding-left: 40px;
  --mixin-18yImBnqN3GP_position: relative;
  --mixin-18yImBnqN3GP_list-style-type: disc;
  --mixin-18yImBnqN3GP_white-space: pre-wrap;
  --mixin-8ZIJeEzf99QZ_display: flex;
  --mixin-8ZIJeEzf99QZ_flex-direction: column;
  --mixin-8ZIJeEzf99QZ_align-items: stretch;
  --mixin-8ZIJeEzf99QZ_justify-content: flex-start;
  --mixin-8ZIJeEzf99QZ_list-style-position: outside;
  --mixin-8ZIJeEzf99QZ_padding-left: 40px;
  --mixin-8ZIJeEzf99QZ_position: relative;
  --mixin-8ZIJeEzf99QZ_list-style-type: decimal;
  --mixin-8ZIJeEzf99QZ_white-space: pre-wrap;
  --mixin-840KEmb7ceaw_color: #000000;
  --mixin-840KEmb7ceaw_font-size: var(--token-eTQczG7SkS0J);
  --mixin-840KEmb7ceaw_font-weight: 600;
  --mixin-840KEmb7ceaw_line-height: 1.5;
  --mixin-840KEmb7ceaw_font-family: "Inter", sans-serif;
  --mixin-840KEmb7ceaw_white-space: pre-wrap;
  --mixin-IPKv5q6d8IZd_color: #000000;
  --mixin-IPKv5q6d8IZd_font-size: var(--token-EhILfm3mLM3w);
  --mixin-IPKv5q6d8IZd_font-weight: 600;
  --mixin-IPKv5q6d8IZd_line-height: 1.5;
  --mixin-IPKv5q6d8IZd_font-family: "Inter", sans-serif;
  --mixin-IPKv5q6d8IZd_white-space: pre-wrap;
  --mixin-gyYcY9sj9MmF_color: #3291ff;
  --mixin-gyYcY9sj9MmF_white-space: pre-wrap;
  --mixin-j-yz5OIrEqNf_white-space: pre-wrap;
  --mixin--kEUPe-_M35X_white-space: pre-wrap;
}

.plasmic_mixins,
.plasmic_mixins:where(.global_environment_website) {
  --mixin-ID7LgJl6y2t5_box-shadow: 0px 0px 0px 1px #12376914,
    0px 1px 2px 0px #a4acb929;
  --plasmic-mixin-card-shadow_box-shadow: var(--mixin-ID7LgJl6y2t5_box-shadow);
  --mixin-_IKeJhP7E7FO_box-shadow: 0px 6px 12px 0px #1a1b251f,
    0px 1px 3px 0px #1a1b251f, 0px 0px 0px 1px #1a1b250f;
  --plasmic-mixin-standard-shadow_box-shadow: var(
    --mixin-_IKeJhP7E7FO_box-shadow
  );
  --mixin-2GWGCAfCNFx1_box-shadow: 0px 0px 0px 1px #12376914,
    0px 1px 2px 0px #a4acb929;
  --plasmic-mixin-card-shadow-0000_box-shadow: var(
    --mixin-2GWGCAfCNFx1_box-shadow
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-RBjx1wUxrAoL_font-family);
  font-size: var(--mixin-RBjx1wUxrAoL_font-size);
  font-weight: var(--mixin-RBjx1wUxrAoL_font-weight);
  font-style: var(--mixin-RBjx1wUxrAoL_font-style);
  color: var(--mixin-RBjx1wUxrAoL_color);
  text-align: var(--mixin-RBjx1wUxrAoL_text-align);
  text-transform: var(--mixin-RBjx1wUxrAoL_text-transform);
  line-height: var(--mixin-RBjx1wUxrAoL_line-height);
  letter-spacing: var(--mixin-RBjx1wUxrAoL_letter-spacing);
  white-space: var(--mixin-RBjx1wUxrAoL_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-rX4aWk4Y-dum_color);
  font-weight: var(--mixin-rX4aWk4Y-dum_font-weight);
  font-size: var(--mixin-rX4aWk4Y-dum_font-size);
  line-height: var(--mixin-rX4aWk4Y-dum_line-height);
  letter-spacing: var(--mixin-rX4aWk4Y-dum_letter-spacing);
  font-family: var(--mixin-rX4aWk4Y-dum_font-family);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-FEQeiL-RWW2s_color);
  font-size: var(--mixin-FEQeiL-RWW2s_font-size);
  font-weight: var(--mixin-FEQeiL-RWW2s_font-weight);
  letter-spacing: var(--mixin-FEQeiL-RWW2s_letter-spacing);
  line-height: var(--mixin-FEQeiL-RWW2s_line-height);
  font-family: var(--mixin-FEQeiL-RWW2s_font-family);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-6aHhUSri_EHo_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-Z-seKnQRX53o_color);
  font-size: var(--mixin-Z-seKnQRX53o_font-size);
  font-weight: var(--mixin-Z-seKnQRX53o_font-weight);
  line-height: var(--mixin-Z-seKnQRX53o_line-height);
  font-family: var(--mixin-Z-seKnQRX53o_font-family);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-vyRZtXYbGmGi_color);
  font-size: var(--mixin-vyRZtXYbGmGi_font-size);
  font-weight: var(--mixin-vyRZtXYbGmGi_font-weight);
  line-height: var(--mixin-vyRZtXYbGmGi_line-height);
  font-family: var(--mixin-vyRZtXYbGmGi_font-family);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-sed6VuY9C4ln_font-family);
  border-radius: var(--mixin-sed6VuY9C4ln_border-top-left-radius)
    var(--mixin-sed6VuY9C4ln_border-top-right-radius)
    var(--mixin-sed6VuY9C4ln_border-bottom-right-radius)
    var(--mixin-sed6VuY9C4ln_border-bottom-left-radius);
  padding: var(--mixin-sed6VuY9C4ln_padding-top)
    var(--mixin-sed6VuY9C4ln_padding-right)
    var(--mixin-sed6VuY9C4ln_padding-bottom)
    var(--mixin-sed6VuY9C4ln_padding-left);
  border-top: var(--mixin-sed6VuY9C4ln_border-top-width)
    var(--mixin-sed6VuY9C4ln_border-top-style)
    var(--mixin-sed6VuY9C4ln_border-top-color);
  border-right: var(--mixin-sed6VuY9C4ln_border-right-width)
    var(--mixin-sed6VuY9C4ln_border-right-style)
    var(--mixin-sed6VuY9C4ln_border-right-color);
  border-bottom: var(--mixin-sed6VuY9C4ln_border-bottom-width)
    var(--mixin-sed6VuY9C4ln_border-bottom-style)
    var(--mixin-sed6VuY9C4ln_border-bottom-color);
  border-left: var(--mixin-sed6VuY9C4ln_border-left-width)
    var(--mixin-sed6VuY9C4ln_border-left-style)
    var(--mixin-sed6VuY9C4ln_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-37SwOrXL_yCf_color);
  padding-left: var(--mixin-37SwOrXL_yCf_padding-left);
  border-left: var(--mixin-37SwOrXL_yCf_border-left-width)
    var(--mixin-37SwOrXL_yCf_border-left-style)
    var(--mixin-37SwOrXL_yCf_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin--6ELTAP-wORP_font-family);
  border-radius: var(--mixin--6ELTAP-wORP_border-top-left-radius)
    var(--mixin--6ELTAP-wORP_border-top-right-radius)
    var(--mixin--6ELTAP-wORP_border-bottom-right-radius)
    var(--mixin--6ELTAP-wORP_border-bottom-left-radius);
  padding: var(--mixin--6ELTAP-wORP_padding-top)
    var(--mixin--6ELTAP-wORP_padding-right)
    var(--mixin--6ELTAP-wORP_padding-bottom)
    var(--mixin--6ELTAP-wORP_padding-left);
  border-top: var(--mixin--6ELTAP-wORP_border-top-width)
    var(--mixin--6ELTAP-wORP_border-top-style)
    var(--mixin--6ELTAP-wORP_border-top-color);
  border-right: var(--mixin--6ELTAP-wORP_border-right-width)
    var(--mixin--6ELTAP-wORP_border-right-style)
    var(--mixin--6ELTAP-wORP_border-right-color);
  border-bottom: var(--mixin--6ELTAP-wORP_border-bottom-width)
    var(--mixin--6ELTAP-wORP_border-bottom-style)
    var(--mixin--6ELTAP-wORP_border-bottom-color);
  border-left: var(--mixin--6ELTAP-wORP_border-left-width)
    var(--mixin--6ELTAP-wORP_border-left-style)
    var(--mixin--6ELTAP-wORP_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-18yImBnqN3GP_display);
  flex-direction: var(--mixin-18yImBnqN3GP_flex-direction);
  align-items: var(--mixin-18yImBnqN3GP_align-items);
  justify-content: var(--mixin-18yImBnqN3GP_justify-content);
  list-style-position: var(--mixin-18yImBnqN3GP_list-style-position);
  padding-left: var(--mixin-18yImBnqN3GP_padding-left);
  position: var(--mixin-18yImBnqN3GP_position);
  list-style-type: var(--mixin-18yImBnqN3GP_list-style-type);
  flex-column-gap: var(--mixin-18yImBnqN3GP_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-8ZIJeEzf99QZ_display);
  flex-direction: var(--mixin-8ZIJeEzf99QZ_flex-direction);
  align-items: var(--mixin-8ZIJeEzf99QZ_align-items);
  justify-content: var(--mixin-8ZIJeEzf99QZ_justify-content);
  list-style-position: var(--mixin-8ZIJeEzf99QZ_list-style-position);
  padding-left: var(--mixin-8ZIJeEzf99QZ_padding-left);
  position: var(--mixin-8ZIJeEzf99QZ_position);
  list-style-type: var(--mixin-8ZIJeEzf99QZ_list-style-type);
  flex-column-gap: var(--mixin-8ZIJeEzf99QZ_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-840KEmb7ceaw_color);
  font-size: var(--mixin-840KEmb7ceaw_font-size);
  font-weight: var(--mixin-840KEmb7ceaw_font-weight);
  line-height: var(--mixin-840KEmb7ceaw_line-height);
  font-family: var(--mixin-840KEmb7ceaw_font-family);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-IPKv5q6d8IZd_color);
  font-size: var(--mixin-IPKv5q6d8IZd_font-size);
  font-weight: var(--mixin-IPKv5q6d8IZd_font-weight);
  line-height: var(--mixin-IPKv5q6d8IZd_line-height);
  font-family: var(--mixin-IPKv5q6d8IZd_font-family);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-gyYcY9sj9MmF_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
