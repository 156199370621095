.pickr,
.pcr-swatches,
.pcr-interaction {
  display: none !important;
}

.pcr-app[data-theme] {
  width: 100% !important;
  max-width: 100% !important;
  box-shadow: none !important;
  padding-bottom: 10px;
  font-size: 16px;
  z-index: 1;
}

.pcr-app[data-theme],
.pcr-app[data-theme] * {
  animation: none !important;
  transition: all 0ms !important;
}

.pcr-selection {
  height: 200px !important;
  grid-template-columns: 32px 4fr !important;
}

.pcr-color-preview {
  margin-left: 0 !important;
  width: 32px !important;
  height: 32px !important;
  justify-content: flex-start !important;
}

.pcr-palette {
  border-radius: 2px !important;
}

.pcr-picker {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.pcr-current-color {
  width: 100% !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 50% !important;
  background: none !important;

  &::before {
    opacity: 0.2 !important;
    z-index: 0 !important;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: currentColor;
    z-index: 0;
  }
}

.pcr-palette:before {
  opacity: 0.2;
}
