.root {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  flex-direction: column;
  min-width: 0;
  border-radius: 4px;
}
.header {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: var(--token-aDa535tnF);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px 16px;
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.headerstate_empty > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.headerstate_empty > :global(.__wab_flex-container) > *,
.headerstate_empty > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.headerstate_empty > :global(.__wab_flex-container) > picture > img,
.headerstate_empty
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.iconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.iconContainershowIcon {
  opacity: 1;
}
.slotTargetIcon {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetIcon > *,
.slotTargetIcon > :global(.__wab_slot) > *,
.slotTargetIcon > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetIcon > picture > img,
.slotTargetIcon > :global(.__wab_slot) > picture > img,
.slotTargetIcon > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  opacity: 0;
}
.slotTargetIconshowIcon > *,
.slotTargetIconshowIcon > :global(.__wab_slot) > *,
.slotTargetIconshowIcon > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetIconshowIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetIconshowIcon > picture > img,
.slotTargetIconshowIcon > :global(.__wab_slot) > picture > img,
.slotTargetIconshowIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetIconshowIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  opacity: 0.5;
}
.svg__gJ4Xi {
  object-fit: cover;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.labelContainer {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  flex-grow: 1;
  min-width: 0;
}
.labelContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.labelContainer > :global(.__wab_flex-container) > *,
.labelContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.labelContainer > :global(.__wab_flex-container) > picture > img,
.labelContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.slotTargetTitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  white-space: pre;
  color: var(--token-ElMeFHrIrKT);
}
.slotTargetTitle > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_slot-string-wrapper),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetTitle > *,
.slotTargetTitle > :global(.__wab_slot) > *,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetTitle > picture > img,
.slotTargetTitle > :global(.__wab_slot) > picture > img,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.actionsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto !important;
  flex-shrink: 0;
}
.actionsContainerstate_empty {
  margin-left: auto !important;
}
.actionsContainerhasCodeExpression {
  margin-left: auto !important;
}
.actionsContainerstate_empty_isQuiet {
  margin-left: auto !important;
}
.menuButton:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.root:hover .menuButton:global(.__wab_instance):global(.__wab_instance) {
  flex-shrink: 0;
}
.settingsButton:global(.__wab_instance) {
  position: relative;
}
.svg__uGhUb {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg__w4Qxv {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  width: 1em;
  height: 1em;
}
.exprButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.exprButtonhasCodeExpression:global(.__wab_instance) {
  display: flex;
}
.svg__wGxvY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  display: none;
}
.svghasCodeExpression__wGxvYqVc3M {
  display: block;
}
.svg__vojYb {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.addButton:global(.__wab_instance) {
  position: relative;
}
.svg__rh6GJ {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1rem;
  height: 1rem;
}
.svg__vxfGq {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  width: 1em;
  height: 1em;
}
.emptyAddButton:global(.__wab_instance) {
  position: relative;
  margin-left: 10px;
  flex-shrink: 0;
}
.svg__ypiQa {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg__wQfgt {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.dragHandle {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  left: -4px;
  top: 0px;
  height: 100%;
  cursor: move;
  min-height: 0;
}
.dragHandlestate_empty {
  left: -4px;
}
.svg__srcvf {
  object-fit: cover;
  color: var(--token-fVn5vRhXJxQ);
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
}
.body {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.variantsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.variantRow___5OoLc:global(.__wab_instance) {
  position: relative;
}
.svg__dhNhp {
  object-fit: cover;
  opacity: 0;
  width: 1em;
  height: 1em;
}
