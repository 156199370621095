.root {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: var(--token-iR8SeEwQZ);
  width: 480px;
  border-radius: 4px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.freeBox__f5CjA {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.freeBox__f5CjA > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__f5CjA > :global(.__wab_flex-container) > *,
.freeBox__f5CjA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__f5CjA > :global(.__wab_flex-container) > picture > img,
.freeBox__f5CjA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__jBw6C {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__jBw6C > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__jBw6C > :global(.__wab_flex-container) > *,
.freeBox__jBw6C > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jBw6C > :global(.__wab_flex-container) > picture > img,
.freeBox__jBw6C
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__oAobL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.routeInput:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__sXrs1 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__t5Rk5 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.freeBox__ucQAw {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__ucQAw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__ucQAw > :global(.__wab_flex-container) > *,
.freeBox__ucQAw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ucQAw > :global(.__wab_flex-container) > picture > img,
.freeBox__ucQAw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__i4G3Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.titleInput:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__nwnCo {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__vn51D {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.freeBox__lSd8I {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__lSd8I > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__lSd8I > :global(.__wab_flex-container) > *,
.freeBox__lSd8I > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lSd8I > :global(.__wab_flex-container) > picture > img,
.freeBox__lSd8I
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__u5FdN {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.freeBox__u5FdN > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__u5FdN > :global(.__wab_flex-container) > *,
.freeBox__u5FdN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u5FdN > :global(.__wab_flex-container) > picture > img,
.freeBox__u5FdN
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBox__hwX9F {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.freeBox__hwX9F > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__hwX9F > :global(.__wab_flex-container) > *,
.freeBox__hwX9F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hwX9F > :global(.__wab_flex-container) > picture > img,
.freeBox__hwX9F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.descriptionInput {
  width: 100%;
  height: 81px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 6px;
  padding: 0.5rem;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.root .descriptionInput::placeholder {
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__uFy4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  top: auto;
  left: auto;
}
.freeBox__aYaBf {
  display: flex;
  position: relative;
}
.freeBox__aYaBf > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__aYaBf > :global(.__wab_flex-container) > *,
.freeBox__aYaBf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aYaBf > :global(.__wab_flex-container) > picture > img,
.freeBox__aYaBf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.characterCount {
  position: relative;
}
.text__v5Bs7 {
  font-size: 11px;
  line-height: 1.5;
}
.freeBox__hurYr {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__hurYr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__hurYr > :global(.__wab_flex-container) > *,
.freeBox__hurYr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hurYr > :global(.__wab_flex-container) > picture > img,
.freeBox__hurYr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__ftM2E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.canonicalInput:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__cXo1J {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__dXpya {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.freeBox__zwdd8 {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__zwdd8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__zwdd8 > :global(.__wab_flex-container) > *,
.freeBox__zwdd8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zwdd8 > :global(.__wab_flex-container) > picture > img,
.freeBox__zwdd8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__elLZ {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.freeBox__elLZ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__elLZ > :global(.__wab_flex-container) > *,
.freeBox__elLZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__elLZ > :global(.__wab_flex-container) > picture > img,
.freeBox__elLZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__eyrOx {
  font-size: 12px;
  line-height: 1.25;
}
.text__v3EQ {
  font-size: 11px;
  line-height: 1.5;
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__bmaVn {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.freeBox__bmaVn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__bmaVn > :global(.__wab_flex-container) > *,
.freeBox__bmaVn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bmaVn > :global(.__wab_flex-container) > picture > img,
.freeBox__bmaVn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.imageAssetPicker {
  position: relative;
}
.freeBox__pd0 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(8px + 16px) !important;
  min-width: 0;
  padding: 0px;
}
.freeBox__pd0 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__pd0 > :global(.__wab_flex-container) > *,
.freeBox__pd0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pd0 > :global(.__wab_flex-container) > picture > img,
.freeBox__pd0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.text__rAUsl {
  width: auto;
  height: auto;
  max-width: 800px;
  color: var(--token-qKhMu66CwSx);
  flex-shrink: 0;
}
.freeBox__zwogK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: var(--token-VBhAy2b-S);
  min-width: 0;
  padding: 0px;
}
.freeBox__r3Es1 {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__r3Es1 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__r3Es1 > :global(.__wab_flex-container) > *,
.freeBox__r3Es1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__r3Es1 > :global(.__wab_flex-container) > picture > img,
.freeBox__r3Es1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__gq12T {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100px;
  flex-shrink: 0;
}
.freeBox__gq12T > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__gq12T > :global(.__wab_flex-container) > *,
.freeBox__gq12T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gq12T > :global(.__wab_flex-container) > picture > img,
.freeBox__gq12T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__nXsp {
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__nlHjo {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.freeBox__nlHjo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__nlHjo > :global(.__wab_flex-container) > *,
.freeBox__nlHjo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nlHjo > :global(.__wab_flex-container) > picture > img,
.freeBox__nlHjo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.propsControl {
  position: relative;
}
.freeBox__cMjM {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(8px + 16px) !important;
  min-width: 0;
  display: none;
  padding: 0px;
}
.freeBox__cMjM > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__cMjM > :global(.__wab_flex-container) > *,
.freeBox__cMjM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cMjM > :global(.__wab_flex-container) > picture > img,
.freeBox__cMjM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.text__a8Wj {
  width: auto;
  height: auto;
  max-width: 800px;
  color: var(--token-qKhMu66CwSx);
  flex-shrink: 0;
}
.freeBox__ykmFj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: var(--token-VBhAy2b-S);
  min-width: 0;
  padding: 0px;
}
.freeBox__wm2MJ {
  flex-direction: row;
  width: auto;
  height: auto;
  display: none;
}
.freeBox__wm2MJ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__wm2MJ > :global(.__wab_flex-container) > *,
.freeBox__wm2MJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wm2MJ > :global(.__wab_flex-container) > picture > img,
.freeBox__wm2MJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.renderModeLabel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.renderModeLabel > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.renderModeLabel > :global(.__wab_flex-container) > *,
.renderModeLabel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.renderModeLabel > :global(.__wab_flex-container) > picture > img,
.renderModeLabel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.renderModeSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__arEr1 {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.option__eWsqQ:global(.__wab_instance) {
  position: relative;
}
.option__oLZu1:global(.__wab_instance) {
  position: relative;
}
.optionGroup__dpydb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__plynp:global(.__wab_instance) {
  position: relative;
}
.option__l0H9:global(.__wab_instance) {
  position: relative;
}
.optionGroup__ge5Wc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__rfhqm:global(.__wab_instance) {
  position: relative;
}
.option__pIwE5:global(.__wab_instance) {
  position: relative;
}
.option__aUSy:global(.__wab_instance) {
  position: relative;
}
.loginRequiredRow {
  flex-direction: row;
  width: auto;
  height: auto;
  display: none;
}
.loginRequiredRow > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.loginRequiredRow > :global(.__wab_flex-container) > *,
.loginRequiredRow > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.loginRequiredRow > :global(.__wab_flex-container) > picture > img,
.loginRequiredRow
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.loginRequiredLabel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.loginRequiredLabel > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.loginRequiredLabel > :global(.__wab_flex-container) > *,
.loginRequiredLabel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.loginRequiredLabel > :global(.__wab_flex-container) > picture > img,
.loginRequiredLabel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.loginRequiredSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.roleRequiredRow {
  flex-direction: row;
  width: auto;
  height: auto;
  display: none;
}
.roleRequiredRow > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.roleRequiredRow > :global(.__wab_flex-container) > *,
.roleRequiredRow > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.roleRequiredRow > :global(.__wab_flex-container) > picture > img,
.roleRequiredRow
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.roleRequiredLabel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.roleRequiredLabel > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.roleRequiredLabel > :global(.__wab_flex-container) > *,
.roleRequiredLabel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.roleRequiredLabel > :global(.__wab_flex-container) > picture > img,
.roleRequiredLabel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.roleRequiredSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rq5Fw {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.option__fWxW7:global(.__wab_instance) {
  position: relative;
}
.option__nqdX:global(.__wab_instance) {
  position: relative;
}
.optionGroup__pI405:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option___0Bjbj:global(.__wab_instance) {
  position: relative;
}
.option__ej8Y4:global(.__wab_instance) {
  position: relative;
}
.optionGroup__h4LgK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__msihw:global(.__wab_instance) {
  position: relative;
}
.option__qQxQp:global(.__wab_instance) {
  position: relative;
}
.option__uvWbg:global(.__wab_instance) {
  position: relative;
}
