.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.sectionCollapseButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.sectionCollapseButtonisExpanded:global(.__wab_instance) {
  order: 1;
  flex-shrink: 0;
}
.listSectionSeparator__xPYnA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.listSectionSeparatorisExpanded__xPYnAgCegW:global(.__wab_instance) {
  flex-shrink: 0;
}
.contentContainer {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.contentContainerisExpanded {
  display: flex;
}
.text___2OyO6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.listSectionSeparator__p31HI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  order: 2;
  flex-shrink: 0;
}
.listSectionSeparatorisExpanded__p31HIgCegW:global(.__wab_instance) {
  flex-shrink: 0;
}
