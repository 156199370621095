.root {
  display: flex;
  position: relative;
  border-radius: 2px;
  padding: 0px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.root:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.slotTargetVersion {
  font-size: 11px;
  line-height: 1.5;
  color: var(--token-UunsGa2Y3t3);
}
.root:hover .slotTargetVersion {
  color: var(--token-0IloF6TmFvF);
}
.root:focus .slotTargetVersion > *,
.root:focus .slotTargetVersion > :global(.__wab_slot) > *,
.root:focus
  .slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetVersion > picture > img,
.root:focus .slotTargetVersion > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.svg {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-UunsGa2Y3t3);
  flex-shrink: 0;
}
.root:hover .svg {
  color: rgb(74, 76, 79);
}
