.root {
  position: relative;
  display: flex;
  flex-direction: column;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.rootsimplified > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootsimplified > :global(.__wab_flex-container) > *,
.rootsimplified > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootsimplified > :global(.__wab_flex-container) > picture > img,
.rootsimplified
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.h5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  font-size: var(--token-eTQczG7SkS0J);
  line-height: 1.5;
  min-width: 0;
}
.slotTargetTier {
  line-height: 1.25rem;
  letter-spacing: -1%;
}
.text___4TEmb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  min-height: 72px;
  min-width: 0;
}
.slotTargetDescription {
  letter-spacing: -0.01em;
  color: var(--token-UunsGa2Y3t3);
  font-size: var(--token-YU1H6d7MUtVL);
  line-height: 1.5;
}
.text {
  font-size: 11px;
  line-height: 1.5;
  color: var(--token-UunsGa2Y3t3);
}
.textcurrent {
  display: block;
}
