.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40vw;
  height: auto;
  background: var(--token-9jh0BkCENS);
  justify-self: flex-start;
  border-radius: 6px;
  padding: 30px 70px;
  border: 2px solid var(--token-bV4cCeIniS6);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.svg__er1Mj {
  object-fit: cover;
  max-width: 100%;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}
.h3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: 24px;
  line-height: 40px;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-size: 12px;
  line-height: 1.5;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.confirmButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__u5Zd2 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__akfXw {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
