.root:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h6NCv {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-iR8SeEwQZ);
  height: 1em;
}
.svg__wHz3V {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.svgisActive__wHz3Vpuhxi {
  color: var(--token-hIvqQBCxyWwX);
}
.svg__ydByj {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
