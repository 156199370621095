@import 'src/wab/styles/_tokens.sass'

.EffectControllersContainer
  width: 180px

.slider
  min-width: 110px

.Dropdown
  background-color: $sand3
  max-width: 120px
  border-radius: 4px