@import "src/wab/styles/tokens";
@import "../../../../styles/_vars.sass";

.trashIcon {
  color: $neutral-secondary;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 15px;
  height: 15px;

  &:hover {
    color: $neutral-primary;
  }
}

.stringDictKeyInput {
  max-width: 34%;
}
.dataSourceExprValue {
  // !important to override `.flex > *`
  min-width: 100% !important;
  max-width: 4vw;
  text-overflow: ellipsis;
}
.container {
  user-select: text;
  background: #fafafa;
}

.blueIndicatorContainer {
  width: 12px;
  height: 12px;
  display: grid;
  justify-items: center;
  align-items: center;
  margin-right: 4px;
  flex-shrink: 0;
}

.blueIndicator {
  border-radius: 50%;
  height: 4px;
  width: 4px;
  z-index: 10;
  box-shadow: 0 0 0 2px white;
  background: $indicator-set;
}
