.root {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  border-radius: 6px;
  padding: 6px 12px 6px 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  align-content: unset;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6px;
}
.rootdisabled {
  opacity: 0.5;
  pointer-events: none;
}
.rootselected {
  background: var(--token-O4S7RMTqZ3);
}
.rootwithEndIcon {
  padding-right: 6px;
}
.rootblue {
  background: var(--token-yqAf_E0HIjU);
}
.rootviolet {
  background: var(--token-Cr1odk81N3Y);
}
.rootselected_blue {
  background: var(--token-dqEx_KxIoYV);
}
.root:hover {
  background: var(--token-bV4cCeIniS6);
}
.rootblue:hover {
  background: var(--token-dqEx_KxIoYV);
}
.rootviolet:hover {
  background: var(--token-oPrqrxbKHqk);
}
.root:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root___focusVisible {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root:focus-within {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root___focusVisibleWithin {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.rootblue:active {
  background: var(--token-RhvOnhv_xIi);
}
.rootviolet:active {
  background: var(--token-I2zAJ678hbp);
}
.startIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.root:focus .startIconContainer {
  outline: none;
}
.root .startIconContainer___focusVisible {
  outline: none;
}
.root:focus-within .startIconContainer {
  outline: none;
}
.root .startIconContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetStartIcon {
  font-size: 24px;
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetStartIconblue {
  color: var(--token-VUsIDivgUss);
}
.slotTargetStartIconviolet {
  color: var(--token-UCbZU41E8Mj);
}
.slotTargetStartIconsmallIcon {
  font-size: 16px;
}
.root:focus .slotTargetStartIcon > *,
.root:focus .slotTargetStartIcon > :global(.__wab_slot) > *,
.root:focus
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetStartIcon > picture > img,
.root:focus .slotTargetStartIcon > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root .slotTargetStartIcon___focusVisible > *,
.root .slotTargetStartIcon___focusVisible > :global(.__wab_slot) > *,
.root
  .slotTargetStartIcon___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetStartIcon___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetStartIcon___focusVisible > picture > img,
.root
  .slotTargetStartIcon___focusVisible
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetStartIcon___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetStartIcon___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root:focus-within .slotTargetStartIcon > *,
.root:focus-within .slotTargetStartIcon > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetStartIcon > picture > img,
.root:focus-within .slotTargetStartIcon > :global(.__wab_slot) > picture > img,
.root:focus-within
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root .slotTargetStartIcon___focusVisibleWithin > *,
.root .slotTargetStartIcon___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetStartIcon___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetStartIcon___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetStartIcon___focusVisibleWithin > picture > img,
.root
  .slotTargetStartIcon___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetStartIcon___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetStartIcon___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.svg__qik2M {
  display: flex;
  position: relative;
  height: 1em;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBoxwithEndIcon > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxwithEndIcon > :global(.__wab_flex-container) > *,
.freeBoxwithEndIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBoxwithEndIcon > :global(.__wab_flex-container) > picture > img,
.freeBoxwithEndIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.slotTargetChildren {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildrendisabled {
  color: #706f6c;
}
.slotTargetChildrenselected {
  color: #1b1b18;
  font-weight: 500;
}
.slotTargetChildrenblue {
  color: var(--token-VUsIDivgUss);
}
.slotTargetChildrenviolet {
  color: var(--token-ZaJGjVCo3Ue);
}
.slotTargetChildrenbold {
  font-weight: 500;
}
.root:focus .slotTargetChildren > *,
.root:focus .slotTargetChildren > :global(.__wab_slot) > *,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetChildren > picture > img,
.root:focus .slotTargetChildren > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root .slotTargetChildren___focusVisible > *,
.root .slotTargetChildren___focusVisible > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisible > picture > img,
.root .slotTargetChildren___focusVisible > :global(.__wab_slot) > picture > img,
.root
  .slotTargetChildren___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root:focus-within .slotTargetChildren > *,
.root:focus-within .slotTargetChildren > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetChildren > picture > img,
.root:focus-within .slotTargetChildren > :global(.__wab_slot) > picture > img,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.endIconContainer {
  display: flex;
  position: relative;
}
.endIconContainerwithEndIcon {
  display: flex;
}
.slotTargetEndIcon {
  font-size: 16px;
  color: var(--token-fVn5vRhXJxQ);
}
.slotTargetEndIconblue {
  color: var(--token-D666zt2IZPL);
}
.slotTargetEndIconviolet {
  color: var(--token-Izr3_jwKAp1);
}
.svg__siHw5 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
