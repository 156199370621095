.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-uzWT6AFCY));
  width: calc(100% + var(--token-uzWT6AFCY));
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-uzWT6AFCY);
  margin-top: 8px;
}
.rootlayout_vertical {
  display: flex;
  flex-direction: column;
}
.rootlayout_vertical > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.rootlayout_vertical > :global(.__wab_flex-container) > *,
.rootlayout_vertical
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootlayout_vertical > :global(.__wab_flex-container) > picture > img,
.rootlayout_vertical
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.rootlayout_horizontal {
  display: flex;
  flex-direction: row;
}
.rootlayout_horizontal > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uzWT6AFCY));
  width: calc(100% + var(--token-uzWT6AFCY));
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootlayout_horizontal > :global(.__wab_flex-container) > *,
.rootlayout_horizontal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootlayout_horizontal > :global(.__wab_flex-container) > picture > img,
.rootlayout_horizontal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-uzWT6AFCY);
  margin-top: 0px;
}
.freeBox__bXw82 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__bXw82 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__bXw82 > :global(.__wab_flex-container) > *,
.freeBox__bXw82 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bXw82 > :global(.__wab_flex-container) > picture > img,
.freeBox__bXw82
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxlayout_vertical__bXw821KxsP {
  position: relative;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: column;
}
.freeBoxlayout_vertical__bXw821KxsP > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBoxlayout_vertical__bXw821KxsP > :global(.__wab_flex-container) > *,
.freeBoxlayout_vertical__bXw821KxsP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxlayout_vertical__bXw821KxsP
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxlayout_vertical__bXw821KxsP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.freeBox___0Gerh {
  display: flex;
  flex-direction: row;
}
.freeBox___0Gerh > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uzWT6AFCY));
  width: calc(100% + var(--token-uzWT6AFCY));
}
.freeBox___0Gerh > :global(.__wab_flex-container) > *,
.freeBox___0Gerh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0Gerh > :global(.__wab_flex-container) > picture > img,
.freeBox___0Gerh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-uzWT6AFCY);
}
.freeBoxlayout_horizontal___0GerhEsXmk {
  width: 100px;
  flex-shrink: 0;
}
.freeBoxlabelSize_half___0GerhhH4Fv {
  width: 100%;
  min-width: 0;
}
.freeBoxlabelSize_half___0GerhhH4Fv > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBox__wrYhU {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.textbox__kv871:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textboxlayout_vertical__kv8711KxsP:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.svg__oazkb {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__hkE1G {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.freeBox__oh2X {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
  padding: 0px;
}
.freeBoxwithRightExtras__oh2XUUUj4 {
  display: flex;
}
