.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.defaultLayout:global(.__wab_instance) {
  position: relative;
}
.freeBox__cpaZg {
  display: flex;
  position: relative;
  flex-direction: column;
}
.header:global(.__wab_instance) {
  position: sticky;
  top: 48px;
  left: 0px;
  z-index: 1;
}
.freeBox__jk5PX {
  display: flex;
  position: relative;
  flex-direction: column;
}
.projectListItem__gcUcH:global(.__wab_instance) {
  position: relative;
}
.projectListItem__bkTRw:global(.__wab_instance) {
  position: relative;
}
.text__l2A9P {
  width: auto;
}
.projectListItem__zqqA:global(.__wab_instance) {
  position: relative;
}
.projectListItem___449Jj:global(.__wab_instance) {
  position: relative;
}
.img__nbaEu {
  object-fit: cover;
  width: 24px;
  height: 24px;
  max-width: 24px;
  border-radius: 50%;
}
.img__nbaEu > picture > img {
  object-fit: cover;
}
.navTeamSection:global(.__wab_instance) {
  position: relative;
}
.svg__hckNp {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.text {
  height: auto;
}
.svg__nwyri {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
