.root {
  display: flex;
  position: relative;
  flex-direction: row;
  background: var(--token-brSQU2ryS);
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.rootdisabled {
  opacity: 0.5;
}
.rootsize_small {
  width: 24px;
  height: 24px;
  padding: 0px;
}
.rootsize_large {
  width: 40px;
  height: 40px;
}
.rootsize_vertical {
  padding-right: 4px;
  padding-left: 4px;
  width: 24px;
  height: 32px;
}
.rootsize_medium {
  padding: 6px;
}
.roottype_seamless {
  width: auto;
  height: auto;
  display: inline-flex;
  padding: 0px;
  border-width: 0px;
}
.roottype_round {
  background: var(--token-O4S7RMTqZ3);
  border-radius: 50%;
  padding: 6px;
}
.roottype_roundClear {
  border-radius: 50%;
}
.roottype_dividedRight {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 4px;
  background: var(--token-O4S7RMTqZ3);
  padding-right: 4px;
  width: auto;
  height: auto;
  display: inline-flex;
  border-color: var(--token-eBt2ZgqRUCz);
}
.roottype_primary {
  background: var(--token-D666zt2IZPL);
}
.roottype_clear {
  background: transparent;
}
.roottype_noDivider {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 0px;
  border-left-style: solid;
  border-color: var(--token-eBt2ZgqRUCz);
}
.roottype_gray {
  background: linear-gradient(var(--token-O4S7RMTqZ3), var(--token-O4S7RMTqZ3)),
    var(--token-brSQU2ryS);
}
.roottype_switchOn {
  box-shadow: 0px 2px 4px 0px var(--token-JrjdlBU-a5Ju),
    0px 0px 0px 0px var(--token-zBV3PmIqbJ9F);
  width: auto;
  min-height: 1.75rem;
  background: var(--token-iR8SeEwQZ);
  display: inline-flex;
  border-radius: 4px;
  padding: 6px 8px;
  border-width: 0px;
}
.roottype_switchOn > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roottype_switchOn > :global(.__wab_flex-container) > *,
.roottype_switchOn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.roottype_switchOn > :global(.__wab_flex-container) > picture > img,
.roottype_switchOn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.roottype_red {
  background: linear-gradient(
      var(--token-qYuX4XAEbRt),
      var(--token-qYuX4XAEbRt)
    ),
    var(--token-brSQU2ryS);
}
.roottype_green {
  background: linear-gradient(
      var(--token-h-5XbcO2WhcA),
      var(--token-h-5XbcO2WhcA)
    ),
    var(--token-brSQU2ryS);
}
.roottype_blue {
  background: linear-gradient(
      var(--token-yqAf_E0HIjU),
      var(--token-yqAf_E0HIjU)
    ),
    var(--token-brSQU2ryS);
}
.roottype_purple {
  background: linear-gradient(
      var(--token-Cr1odk81N3Y),
      var(--token-Cr1odk81N3Y)
    ),
    var(--token-brSQU2ryS);
}
.rootisActive {
  background: var(--token-bV4cCeIniS6);
}
.rootwithDropdown > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootwithDropdown > :global(.__wab_flex-container) > *,
.rootwithDropdown > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootwithDropdown > :global(.__wab_flex-container) > picture > img,
.rootwithDropdown
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.roottype_primary_type_dividedRight {
  border-left-color: var(--token-JfSQu2FXX0v);
  background: var(--token-D666zt2IZPL);
}
.roottype_clear_type_dividedRight {
  background: none;
}
.roottype_noDivider_type_primary {
  border-left-color: var(--token-JfSQu2FXX0v);
}
.roottype_roundClear_isActive {
  background: none;
}
.rootisActive_type_dividedRight {
  background: var(--token-bV4cCeIniS6);
}
.rootisActive_type_primary_type_dividedRight {
  background: var(--token-mu3x63xzJRW);
}
.roottype_round:hover {
  background: var(--token-bV4cCeIniS6);
  border-color: #ffffff00;
}
.roottype_roundClear:hover {
  background: var(--token-bV4cCeIniS6);
}
.roottype_dividedRight:hover {
  background: var(--token-bV4cCeIniS6);
}
.roottype_primary:hover {
  background: var(--token-mu3x63xzJRW);
}
.roottype_noDivider:hover {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_stepUp:hover {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_red:hover {
  background: linear-gradient(
      var(--token-HKVCQ5ZKovK),
      var(--token-HKVCQ5ZKovK)
    ),
    var(--token-brSQU2ryS);
}
.roottype_green:hover {
  background: linear-gradient(
      var(--token-dv0BWWyaHl7H),
      var(--token-dv0BWWyaHl7H)
    ),
    var(--token-brSQU2ryS);
}
.roottype_blue:hover {
  background: linear-gradient(
      var(--token-dqEx_KxIoYV),
      var(--token-dqEx_KxIoYV)
    ),
    var(--token-brSQU2ryS);
}
.roottype_purple:hover {
  background: linear-gradient(
      var(--token-oPrqrxbKHqk),
      var(--token-oPrqrxbKHqk)
    ),
    var(--token-brSQU2ryS);
}
.rootisActive:hover {
  background: var(--token-Ik3bdE1e1Uy);
  border-color: rgb(201, 204, 209);
}
.rootwithBackgroundHover:hover {
  background: var(--token-bV4cCeIniS6);
}
.rootwithRedBackgroundHover:hover {
  background: var(--token-HKVCQ5ZKovK);
}
.rootwithGreenBackgroundHover:hover {
  background: var(--token-dv0BWWyaHl7H);
}
.roottype_primary_type_dividedRight:hover {
  background: var(--token-mu3x63xzJRW);
}
.roottype_round:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_roundClear:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_dividedRight:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_primary:active {
  background: var(--token-VUsIDivgUss);
}
.roottype_noDivider:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_stepUp:active {
  background: var(--token-hoA5qaM-91G);
}
.roottype_red:active {
  background: linear-gradient(
      var(--token-5kjtdCiiOPB),
      var(--token-5kjtdCiiOPB)
    ),
    var(--token-brSQU2ryS);
}
.roottype_green:active {
  background: linear-gradient(
      var(--token-3AptjBfMqvPS),
      var(--token-3AptjBfMqvPS)
    ),
    var(--token-brSQU2ryS);
}
.roottype_blue:active {
  background: linear-gradient(
      var(--token-RhvOnhv_xIi),
      var(--token-RhvOnhv_xIi)
    ),
    var(--token-brSQU2ryS);
}
.roottype_purple:active {
  background: linear-gradient(
      var(--token-I2zAJ678hbp),
      var(--token-I2zAJ678hbp)
    ),
    var(--token-brSQU2ryS);
}
.rootwithBackgroundHover:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.rootwithRedBackgroundHover:active {
  background: var(--token-5kjtdCiiOPB);
}
.rootwithGreenBackgroundHover:active {
  background: var(--token-3AptjBfMqvPS);
}
.roottype_primary_type_dividedRight:active {
  background: var(--token-VUsIDivgUss);
}
.root___focused__focusVisible:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.slotTargetChildren {
  font-size: 1rem;
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildrensize_large {
  font-size: 24px;
}
.slotTargetChildrensize_medium {
  font-size: 1.25rem;
}
.slotTargetChildrentype_primary {
  color: var(--token-iR8SeEwQZ);
}
.slotTargetChildrentype_switchOn {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetChildrentype_red {
  color: var(--token-Y2CWh0ci95a);
}
.slotTargetChildrentype_green {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetChildrentype_blue {
  color: var(--token-VUsIDivgUss);
}
.slotTargetChildrentype_purple {
  color: var(--token-UCbZU41E8Mj);
}
.slotTargetChildrenisActive {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetChildrenisActive_type_primary_type_dividedRight {
  color: var(--token-iR8SeEwQZ);
}
.root:hover .slotTargetChildren {
  color: var(--token-0IloF6TmFvF);
}
.roottype_primary:hover .slotTargetChildrentype_primary {
  color: var(--token-iR8SeEwQZ);
}
.roottype_red:hover .slotTargetChildrentype_red {
  color: var(--token-Ift7nNWjir7);
}
.roottype_green:hover .slotTargetChildrentype_green {
  color: var(--token-AKguBXlLTkKh);
}
.roottype_blue:hover .slotTargetChildrentype_blue {
  color: var(--token-krbUYvO2lx2);
}
.roottype_purple:hover .slotTargetChildrentype_purple {
  color: var(--token-ZaJGjVCo3Ue);
}
.rootwithRedBackgroundHover:hover .slotTargetChildrenwithRedBackgroundHover {
  color: var(--token-Y2CWh0ci95a);
}
.rootwithGreenBackgroundHover:hover
  .slotTargetChildrenwithGreenBackgroundHover {
  color: var(--token-hIvqQBCxyWwX);
}
.root:active .slotTargetChildren {
  color: var(--token-0IloF6TmFvF);
}
.roottype_primary:active .slotTargetChildrentype_primary {
  color: var(--token-iR8SeEwQZ);
}
.rootwithRedBackgroundHover:active .slotTargetChildrenwithRedBackgroundHover {
  color: var(--token-Y2CWh0ci95a);
}
.rootwithGreenBackgroundHover:active
  .slotTargetChildrenwithGreenBackgroundHover {
  color: var(--token-hIvqQBCxyWwX);
}
.root:focus .slotTargetChildren___focused__focusVisible > *,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetChildren___focused__focusVisible > picture > img,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetChildren___focused__focusVisible
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > *,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > picture
  > img,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_primary:focus
  .slotTargetChildren___focused__focusVisible_type_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.svg__vKQk {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.slotTargetChildren2 {
  font-size: 16px;
  color: var(--token-fVn5vRhXJxQ);
}
.slotTargetChildren2 > :global(.__wab_text),
.slotTargetChildren2 > :global(.__wab_expr_html_text),
.slotTargetChildren2 > :global(.__wab_slot-string-wrapper),
.slotTargetChildren2 > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren2 > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren2 > *,
.slotTargetChildren2 > :global(.__wab_slot) > *,
.slotTargetChildren2 > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren2 > picture > img,
.slotTargetChildren2 > :global(.__wab_slot) > picture > img,
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren2
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildren2size_large {
  font-size: 24px;
}
.slotTargetChildren2type_red {
  color: var(--token-iDpByWoW0eF);
}
.slotTargetChildren2type_green {
  color: var(--token-GEBK7U4Am62W);
}
.slotTargetChildren2type_blue {
  color: var(--token-D666zt2IZPL);
}
.slotTargetChildren2type_purple {
  color: var(--token-Izr3_jwKAp1);
}
.slotTargetChildren2isActive {
  color: var(--token-UunsGa2Y3t3);
}
.root:hover .slotTargetChildren2 {
  color: var(--token-UunsGa2Y3t3);
}
.svg__vDdFm {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg {
  position: absolute;
  object-fit: cover;
  left: auto;
  top: 0;
  right: 0;
  color: var(--token-fVn5vRhXJxQ);
  height: 1em;
}
