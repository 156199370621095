.root {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.outerBorder {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 2px rgba(62, 142, 246, 1);
  background: rgba(62, 142, 246, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
  border-radius: 3px;
  border: 1px solid #ffffff;
}
.outerBorder2 {
  box-shadow: 0px 4px 10px 0px #0000001a,
    0px 0px 0px 2px var(--token-clhN-3CruXgh);
  background: #2997641a;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 3px;
}
.outerBorder2isRecording {
  background: #e54d2e1a;
  box-shadow: 0px 4px 10px 0px #0000001a,
    0px 0px 0px 2px var(--token-iDpByWoW0eF);
  border-color: #e54d2e;
}
.innerBorder {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
  border-radius: 3px;
}
