@import "~antd/dist/antd.less";

@gray30: #4a4c4f;
@gray45: #6e7175;
@gray60: #96999e;
@gray75: #bcc0c4;
@gray80: #c9ccd1;
@gray95: #f0f2f5;
@gray100: #f5f7fa;
@cyan60: #04a4f4;
@cyan90: #c6efff;

@sand3: #f3f3f2;
@sand4: #eeeeec;
@sand5: #e9e9e6;
@sand9: #90908c;
@sand11: #706f6c;
@sand12: #1B1B18;

@font-family: "Inter", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base: 12px;
@line-height-base: 1.5;
@component-background: #fff;
@text-color: @sand12;
@text-color-secondary: @sand11;
@primary-color: @cyan60;
//@item-hover-bg: #444858;
//@item-active-bg: #444858;
//@heading-color: #bbc;
@popover-bg: #2d3439;
@popover-bg: #fff;
//@border-color-split: darken(#777, 20%);
//@btn-default-border: none;
@focus-ring-color: #0091FF80;
@property-input-border-radius: 6px;

.ant-popover-inner-content {
  width: auto
}

.ant-dropdown-menu {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 2px 2px 10px rgba(0, 0, 0, 0.15);
}

.ant-dropdown-menu-item-group-list {
  margin: 0;
}

.ant-dropdown-menu-item-group-list {
  & [role="menuitem"]:not(.ant-dropdown-menu-submenu),
  & .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
    padding-left: 24px;
  }
}

.ant-dropdown-menu-item {
  padding-left: 12px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding-top: 2px;
  padding-bottom: 2px;
  transition: all 0ms;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: @gray95;
}

.ant-dropdown-menu-submenu-expand-icon {
  margin-left: 20px;
}

.ant-dropdown-menu-submenu-expand-icon svg {
  width: 9px;
  height: 9px;
}

.ant-input:focus {
  box-shadow: none;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-btn-primary:disabled {
  background-color: @primary-color;
  border-color: @primary-color;
  opacity: 0.5;
  color: #fff;
  &:hover {
    background-color: @primary-color;
    border-color: @primary-color;
    color: #fff;
  }
}


.tag-select .ant-select.textboxlike .ant-select-selector,
.labeled-item .ant-select.textboxlike .ant-select-selector,
[data-plasmic-role="labeled-item"] .ant-select.textboxlike .ant-select-selector,
.labeled-item .ant-input-number,
[data-plasmic-role="labeled-item"] .ant-input-number,
.labeled-item .ant-input,
[data-plasmic-role="labeled-item"] .ant-input {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 6px 6px 6px 8px !important;
  background-color: @sand3;
  border-radius: @property-input-border-radius;
  min-height: 32px;
  height: 32px;
  &:hover {
    background-color: @sand4;
  }
}

.tag-select .ant-select.textboxlike,
.labeled-item .ant-select.textboxlike,
[data-plasmic-role="labeled-item"] .ant-select.textboxlike {
  border-radius: @property-input-border-radius;

  &:focus-within {
    box-shadow: 0px 0px 0px 1px @focus-ring-color !important;
  }
}

:where(.labeled-item .ant-input-number-input)::placeholder,
:where([data-plasmic-role="labeled-item"] .ant-input-number-input)::placeholder,
:where(.labeled-item .ant-input)::placeholder,
:where([data-plasmic-role="labeled-item"] .ant-input)::placeholder {
  color: @sand9;
  opacity: 1;
}

.tag-select .ant-select.textboxlike .ant-select-selector,
.labeled-item .ant-select.textboxlike .ant-select-selector,
[data-plasmic-role="labeled-item"] .ant-select.textboxlike .ant-select-selector {
  min-height: 32px;
  height: 32px;
}

.labeled-item input, [data-plasmic-role="labeled-item"] input,
.labeled-item textarea, [data-plasmic-role="labeled-item"] textarea {
  font-family: Inter, Helvetica, Arial, sans-serif !important;
  padding-right: 0 !important;
}

.labeled-item .ant-input-number-sm input,
[data-plasmic-role="labeled-item"] .ant-input-number-sm input {
  height: 18px;
  padding: 0px;
}

.ant-checkbox {
  top: 4px;
}
