@import './_tokens.sass'

// Backgrounds
$bg: #fff

$inverse-bg: $sand12

$canvas-bg: $sand2
$canvas-border: $sand3

// Fonts
$font-family: "Inter", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif
$code-font-family: "IBM Plex Mono", monospace

$font-size-xsm: 10px
$font-size-sm: 11px
$font-size: 12px
$font-size-xlg: 14px
$font-size-xxlg: 18px

$line-height: 1.5
$fg: $sand12
$dimfg: $sand9
$dimdimfg: $sand7
$dimdimdimfg: $sand3
$brightfg: $sand12
$dimbrightfg: $sand9
$dimbg: $sand3
$strongbg: $sand4

$inverse-fg: $sand7
$inverse-brightfg: #fff
$inverse-dimfg: $sand10
$inverse-dimdimfg: $sand12
$inverse-brightfg: $sand4
$inverse-dimbg: $sand12
$inverse-dimdimbg: $sand12

$lightener: rgba(0, 0, 0, 0.1)
$lightener2: rgba(0, 0, 0, 0.2)

$fg-active: $brightfg
$dimfg-active: lighten($fg, 20%)
$disabled-fg: rgba($fg, 0.5)

$active-color: $blue9
$focus-color: $focus-ring
$error-bg: #873737
$error-fg: #ccc
$light-error-fg: $red9

// Component colors
$component-dimdimfg: $purple3
$component-dimfg: $purple7
$component-fg: $purple9

// Variant colors
$variant-dimdimfg: $tomato3
$variant-dimfg: $tomato7
$variant-fg: $tomato9
$variant-dimbg: $tomato3
$variant-bg: $tomato4

// Frame colors
$frame-dimdimfg: $blue3
$frame-dimfg: $blue7
$frame-fg: $blue9
$frame-bg: $blue3

// TplTag colors
$tag-dimdimfg: $blue3
$tag-dimfg: $blue7
$tag-fg: $blue9

// Mixin colors
$mixin-dimdimfg: $teal3
$mixin-fg: $teal9
$mixin-bg: $teal3

// Token colors
$token-fg: $yellow9

// Indicator style colors
$indicator-set: $blue9
$indicator-overriding: $pink9
$indicator-inherited: $sand6
$indicator-theme: $sand6
$indicator-mixin: $mixin-fg
$indicator-overwritten: $sand6

// Border colors
$border-strong: $dimdimfg
$border-dim: $dimdimdimfg


// Styled control grid
$label-col-width: 84px
$label-col-width-sm: 46px
$label-col-gap: 8px

// Sidebar
$sidebar-section-hpadding: 16px
$sidebar-section-hpadding-with-right-gutter: 20px
$sidebar-section-vpadding: 12px
$section-list-item-height: 32px
$sidebar-tab-hpadding: 8px
