.DateStringEditor {
  width: 100%;
  display: flex;
  background-color: var(--token-O4S7RMTqZ3) !important;
  border-radius: 6px !important;
  border: none !important;
}

.DateStringEditor input {
  font-size: 12px !important;
}
