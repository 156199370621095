.root:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___3Qolh {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___96QKk {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
