.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.rootspecialSectionHeading {
  padding-top: 16px;
}
.svg {
  position: relative;
  object-fit: cover;
  color: var(--token-mlyauzeAvywY);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.svgglobal_environment_website {
  margin-top: 4px;
}
.svgtier_enterprise {
  color: var(--token-N1gO6v-RIEn);
}
.svgspecialSectionHeading {
  opacity: 0;
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.slotTargetChildren {
  color: var(--token-mlyauzeAvywY);
  font-size: var(--token-YU1H6d7MUtVL);
  line-height: 1.5;
}
.slotTargetChildrentier_free {
  color: var(--token-ZaJGjVCo3Ue);
}
.slotTargetChildrentier_starter {
  color: var(--token-Ift7nNWjir7);
}
.slotTargetChildrentier_pro {
  color: var(--token-AKguBXlLTkKh);
}
.slotTargetChildrentier_team {
  color: var(--token-krbUYvO2lx2);
}
.slotTargetChildrentier_enterprise {
  color: var(--token-N1gO6v-RIEn);
}
.slotTargetChildrenspecialSectionHeading {
  font-size: 18px;
  font-weight: 500;
}
