.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  min-width: 0;
}
.root:focus-within {
  outline: none;
}
.trigger {
  background: var(--token-O4S7RMTqZ3);
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--token-aDa535tnF);
  min-width: 0;
  flex-shrink: 0;
  border-radius: 6px;
  padding: 6px 6px 6px 8px;
  border: 0px solid #cacaca;
}
.trigger > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.trigger > :global(.__wab_flex-container) > *,
.trigger > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trigger > :global(.__wab_flex-container) > picture > img,
.trigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.triggerisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root:hover .trigger {
  background: var(--token-bV4cCeIniS6);
}
.root:focus-within .trigger {
  box-shadow: inset 0px 0px 0px 1px var(--token-O9Cf1BVdg);
  outline: none;
}
.root .trigger:focus {
  outline: none;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.contentContainertextRight {
  justify-content: flex-end;
}
.slotTargetSelectedContent {
  white-space: pre;
  color: var(--token-fVn5vRhXJxQ);
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSelectedContentvalueSetState_isSet {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetSelectedContentvalueSetState_isUnset {
  color: var(--token-4f35RzFNq);
}
.slotTargetSelectedContentvalueSetState_isInherited {
  color: var(--token-s0HBak9It);
}
.slotTargetPlaceholder {
  white-space: pre;
  color: var(--token-4f35RzFNq);
  font-style: normal;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetPlaceholdervalueSetState_isSet {
  color: var(--token-0IloF6TmFvF);
}
.slotTargetPlaceholdervalueSetState_isInherited {
  color: var(--token-qKhMu66CwSx);
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-UunsGa2Y3t3);
  flex-shrink: 0;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  overflow: auto;
}
.optionsContainerisOpen {
  padding: 1px;
}
