.root {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  min-height: var(--token-aDa535tnF);
  justify-content: flex-start;
  flex-shrink: 0;
  min-width: 0;
  padding: 0px 16px;
}
.rootclickable {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}
.rootalignment_center {
  align-items: center;
  justify-content: flex-start;
}
.rootalignment_top {
  align-items: flex-start;
}
.rootpadding_noHorizontal {
  padding: 0px;
}
.rootlayout_vertical {
  display: flex;
  flex-direction: column;
}
.rootautoWidth {
  width: auto;
  display: inline-flex;
}
.rootclickable:hover {
  background: var(--token-p-rw5DRJTx);
}
.dragHandle {
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  min-height: 0;
  display: none;
  padding: 0px;
}
.dragHandledraggable {
  display: flex;
}
.grip {
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  width: 16px;
  height: var(--token-dA6NVsnIp);
  cursor: grabbing;
  transition-property: opacity;
  transition-duration: 250ms;
  align-self: center;
  left: auto;
  top: auto;
  flex-shrink: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 250ms;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 84px;
  flex-shrink: 0;
  margin-right: 8px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  align-self: stretch;
}
.labelContainernesting_simple {
  margin-left: 16px;
}
.labelContainernesting_double {
  margin-left: 32px;
}
.labelContainerpadding_noLabel {
  padding: 0px;
}
.labelContainerlayout_vertical {
  width: auto;
}
.labelContainerlabelSize_half {
  width: 50%;
}
.labelContainerlabelSize_auto {
  width: auto;
}
.labelContainerlabelSize_small {
  width: 46px;
  flex-shrink: 0;
}
.spacer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 7px;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBoxnesting_labelOnly {
  padding-left: 16px;
}
.iconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-right: 4px;
}
.iconContainerwithIcon {
  display: flex;
}
.svg__k5Ltc {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.labelTextContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
}
.slotTargetLabel {
  color: var(--token-0IloF6TmFvF);
  white-space: pre-wrap;
}
.slotTargetLabeltruncateLabel {
  white-space: pre;
}
.slotTargetLabeltruncateLabel > :global(.__wab_text),
.slotTargetLabeltruncateLabel > :global(.__wab_expr_html_text),
.slotTargetLabeltruncateLabel > :global(.__wab_slot-string-wrapper),
.slotTargetLabeltruncateLabel > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetLabeltruncateLabel > *,
.slotTargetLabeltruncateLabel > :global(.__wab_slot) > *,
.slotTargetLabeltruncateLabel > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetLabeltruncateLabel > picture > img,
.slotTargetLabeltruncateLabel > :global(.__wab_slot) > picture > img,
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetLabeltruncateLabel
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSubtitle {
  font-size: 11px;
  color: var(--token-fVn5vRhXJxQ);
}
.text__u1Jb1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.spacer2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 7px;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  min-height: var(--token-aDa535tnF);
  min-width: 0;
  padding: 7px 0px;
}
.contentContainerpadding_noContent {
  padding: 0px;
}
.contentContainercontentAlignment_right {
  justify-content: flex-end;
  align-items: center;
}
.text__xVFjy {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.actionsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: var(--token-aDa535tnF);
  max-width: 100%;
  left: auto;
  top: auto;
  right: auto;
  align-self: flex-start;
  margin-right: -16px;
  flex-shrink: 0;
  transition-property: opacity;
  transition-duration: 0.25s;
  opacity: 0;
  min-width: 16px;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 0.25s;
}
.actionsContainerlayout_vertical {
  top: 0px;
  position: absolute;
  width: auto;
  right: 0px;
  margin: 0px;
}
.root:hover .actionsContainer {
  opacity: 1;
}
.root:focus-within .actionsContainer {
  opacity: 1;
  outline: none;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: var(--token-aDa535tnF);
}
.deleteButtondeletable:global(.__wab_instance) {
  display: flex;
}
.svg__dp5Pt {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg___9TaH7 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.menuButton:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: var(--token-aDa535tnF);
  display: none;
}
.menuButtonwithMenu:global(.__wab_instance):global(.__wab_instance) {
  display: block;
}
.indicatorContainer {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  top: 10px;
  left: 2px;
}
.indicatorContainernoLabel {
  left: -8px;
}
.indicatorContainerwithIndicator {
  display: flex;
}
.indicatorContainerwithIndicator_padding_noHorizontal {
  left: -14px;
}
