@import '../../../styles/_vars.sass'
@import '../../../styles/_mixins.sass'

.LoginForm__Container
  @include hcenter

.LoginForm__Content
  @include vstack-gap(16px)
  align-items: center
  margin-top: 128px
  width: 100%

.LoginForm__Controls
  @include vstack
  align-items: stretch
  margin-top: 48px
  background: white
  padding: 32px
  width: 100%
  max-width: 400px

.LoginForm__Logo
  width: 128px
  height: 64px
  margin-bottom: 32px
  > .PlasmicLogo
    width: 100%
    height: 100%

.LoginForm__Footer
  @include vstack-gap(16px)
  align-items: center
  font-size: 11px
  margin-bottom: 32px

.LoginForm__FooterLinks
  @include hstack-gap(16px)

.LoginForm__FooterCopy
  color: $dimfg

.LoginForm__Oauth
  @include vstack-gap(16px)

.LoginForm__Fields
  @include vstack-gap(16px)

.LoginForm__Error
  color: $tomato9

.LoginForm__Feedback
  color: $grass9

.LoginForm__SignUpOrInToggle
  line-height: 20px
  margin-top: 32px
  text-align: center

.LoginForm__SmallMargin
  margin-top: 4px !important

.SurveyForm__Fields
  @include vstack-gap(16px)
  // reset ant form's gap
  > *:not(:empty)
    margin-bottom: 0
