.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.openCopilotBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__aqhUm {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__ubknx {
  position: relative;
  object-fit: cover;
  width: 24px;
  height: 18px;
  color: var(--token-N1gO6v-RIEn);
}
.svg__phj6M {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.popoverPlaceholder {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 40px;
  bottom: auto;
  right: 0px;
  padding: 24px;
}
.popoverPlaceholderstate_ready {
  display: flex;
}
.popoverPlaceholderstate_loading {
  display: flex;
}
.popoverPlaceholderstate_error {
  display: flex;
}
.popoverPlaceholderstate_quotaExceeded {
  display: flex;
}
.popoverPlaceholderstate_history {
  display: flex;
}
.popoverPlaceholderstate_historyEmpty {
  display: flex;
}
.sizerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 300px;
  max-width: 100%;
  flex-shrink: 0;
}
.sizerContainerstate_ready {
  width: auto;
  height: 300px;
  flex-shrink: 0;
}
.promptDialog {
  display: flex;
  flex-direction: column;
  width: 512px;
  height: auto;
  max-width: 100%;
  overflow: auto;
  background: #ffffff;
  max-height: 100%;
  padding: 16px;
}
.promptDialog > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.promptDialog > :global(.__wab_flex-container) > *,
.promptDialog > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.promptDialog > :global(.__wab_flex-container) > picture > img,
.promptDialog
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.headerContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.promptLabel {
  width: auto;
  height: auto;
  max-width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 50px;
}
.promptLabelstate_history {
  display: none;
}
.promptLabelstate_historyEmpty {
  display: none;
}
.history {
  width: auto;
  height: auto;
  max-width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 50px;
  display: none;
}
.historystate_history {
  display: block;
}
.historystate_historyEmpty {
  display: block;
}
.rightButtons {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.historyBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.historyBtnstate_history:global(.__wab_instance) {
  flex-shrink: 0;
}
.historyBtnstate_historyEmpty:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__nhiay {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.svgstate_history__nhiay6YQr {
  color: #1b1b18;
}
.svgstate_historyEmpty__nhiay176IJ {
  color: #1b1b18;
}
.svg__shUhp {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.cancelBtn:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.cancelBtnstate_ready:global(.__wab_instance) {
  flex-shrink: 0;
}
.cancelBtnstate_quotaExceeded:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__ySl6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.svg___1FlQ0 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.contents {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.contents > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.contents > :global(.__wab_flex-container) > *,
.contents > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contents > :global(.__wab_flex-container) > picture > img,
.contents
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.contentsstate_history {
  max-height: 100%;
  overflow: auto;
}
.contentsstate_historyEmpty {
  max-height: 100%;
  overflow: auto;
}
.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.historyContainerstate_history {
  display: flex;
}
.historyContainerstate_historyEmpty {
  display: flex;
}
.historyEmptyMsg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.historyEmptyMsgstate_history {
  display: none;
}
.historyEmptyMsgstate_historyEmpty {
  text-align: center;
}
.historyContents {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 0;
}
.historyContents > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.historyContents > :global(.__wab_flex-container) > *,
.historyContents > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.historyContents > :global(.__wab_flex-container) > picture > img,
.historyContents
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.historyContentsstate_history {
  display: flex;
}
.historyContentsstate_historyEmpty {
  display: none;
}
.copilotMsg__yTnCa:global(.__wab_instance) {
  max-width: 100%;
}
.copilotMsg__li4VX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.copilotMsg___5GBpq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.copilotMsg___3Ib5I:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.historyBottomDiv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.onLoadTrigger {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.onLoadTrigger > picture > img {
  object-fit: cover;
}
.onLoadTrigger > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.onLoadTriggerstate_history {
  width: 1px;
  opacity: 0;
  height: 1px;
  flex-shrink: 0;
}
.onLoadTriggerstate_historyEmpty {
  width: 1px;
  opacity: 0;
  height: 1px;
  flex-shrink: 0;
}
.promptContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.promptContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.promptContainer > :global(.__wab_flex-container) > *,
.promptContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.promptContainer > :global(.__wab_flex-container) > picture > img,
.promptContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.promptContainerstate_ready {
  display: flex;
}
.promptContainerstate_history {
  display: none;
}
.promptContainerstate_historyEmpty {
  display: none;
}
.promptInput:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__cl5YY {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__htBvt {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.runPromptBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.runPromptBtnstate_ready:global(.__wab_instance) {
  flex-shrink: 0;
}
.runPromptBtnstate_error:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__hRxiI {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
  color: #0091ff;
}
.svg__ynXy {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__x55Ap {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 64px;
  color: #8888881a;
  min-width: 0;
  flex-shrink: 0;
}
.svgstate_loading__x55Apa4Pzr {
  display: block;
}
.errorMessage {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
  display: none;
}
.errorMessagestate_error {
  display: block;
}
.quotaExceededMsg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.quotaExceededMsgstate_quotaExceeded {
  text-align: center;
  width: 100%;
  background: #fef2a4;
  margin-bottom: 12px;
  min-width: 0;
  display: block;
  border-radius: 12px;
  padding: 8px;
}
.reply:global(.__wab_instance) {
  position: relative;
  margin-bottom: 12px;
  display: none;
}
.replystate_ready:global(.__wab_instance) {
  display: flex;
}
.replystate_quotaExceeded:global(.__wab_instance) {
  margin-bottom: 12px;
}
