.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.freeBox___67Tlr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___48TmO {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox___48TmO > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox___48TmO > :global(.__wab_flex-container) > *,
.freeBox___48TmO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___48TmO > :global(.__wab_flex-container) > picture > img,
.freeBox___48TmO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.operationTitle {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 16px;
}
.freeBox__ot68 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTeamName {
  font-size: 16px;
  font-weight: 600;
}
.freeBox__veMFb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 16px;
}
.text__pa5Ic {
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 11px;
  color: var(--token-UunsGa2Y3t3);
}
.operationDescription {
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 11px;
  color: var(--token-UunsGa2Y3t3);
}
.text__nm0Di {
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 11px;
  color: var(--token-UunsGa2Y3t3);
  padding-left: 0px;
}
.freeBox__eBvEe {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.billingFrequencyToggle:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__c7DnN {
  font-size: 14px;
  font-weight: 600;
}
.freeBox__cZbE {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 200px;
  padding: 16px;
}
.freeBox__cZbE > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__cZbE > :global(.__wab_flex-container) > *,
.freeBox__cZbE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cZbE > :global(.__wab_flex-container) > picture > img,
.freeBox__cZbE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
.priceTierPicker:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__lrnq {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.freeBox__lrnq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__lrnq > :global(.__wab_flex-container) > *,
.freeBox__lrnq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lrnq > :global(.__wab_flex-container) > picture > img,
.freeBox__lrnq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBoxtempPickOneModal__lrnQzGtUe {
  display: none;
}
.freeBox__tMKh6 {
  display: flex;
  position: relative;
}
.bill:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__pogjR {
  text-align: right;
}
.freeBox___7P51B {
  position: relative;
  display: none;
}
.freeBox___7P51B > :global(.__wab_flex-container) {
  justify-content: flex-end;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox___7P51B > :global(.__wab_flex-container) > *,
.freeBox___7P51B > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7P51B > :global(.__wab_flex-container) > picture > img,
.freeBox___7P51B
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxskipCreditCard___7P51B73226 {
  display: flex;
}
.spinnerContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
}
.cancelButton:global(.__wab_instance) {
  position: relative;
}
.svg__hfzby {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__cbbt {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.confirmButton:global(.__wab_instance) {
  position: relative;
}
.svg__r5EBh {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__p2T2J {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.freeBox___6BfLa {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.freeBox___6BfLa > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___6BfLa > :global(.__wab_flex-container) > *,
.freeBox___6BfLa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6BfLa > :global(.__wab_flex-container) > picture > img,
.freeBox___6BfLa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBoxskipCreditCard___6BfLa73226 {
  display: none;
}
.freeBoxtempPickOneModal___6BfLAzGtUe {
  display: none;
}
.freeBox__eVxan {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__eVxan > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__eVxan > :global(.__wab_flex-container) > *,
.freeBox__eVxan > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eVxan > :global(.__wab_flex-container) > picture > img,
.freeBox__eVxan
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.text__ik21K {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 14px;
  font-weight: 500;
}
.freeBox__i0LzW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__i0LzW > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__i0LzW > :global(.__wab_flex-container) > *,
.freeBox__i0LzW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__i0LzW > :global(.__wab_flex-container) > picture > img,
.freeBox__i0LzW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.stripeCardElement {
  display: flex;
  height: 32px;
  width: 100%;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  flex-shrink: 0;
}
.text__b6Vot {
  position: relative;
  left: auto;
  top: auto;
}
.freeBox___6RZop {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__aX7C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-UunsGa2Y3t3);
  min-width: 0;
}
.freeBox__m66BV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.stripeExpiryElement {
  display: flex;
  height: 32px;
  width: 100%;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  flex-shrink: 0;
}
.text__a3V3H {
  position: relative;
  left: auto;
  top: auto;
}
.freeBox__s2GDv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__naWaw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-UunsGa2Y3t3);
  min-width: 0;
}
.freeBox__wiZSw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.stripeCvcElement {
  display: flex;
  height: 32px;
  width: 100%;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  flex-shrink: 0;
}
.text__biGjm {
  position: relative;
  left: auto;
  top: auto;
}
.freeBox___5U5GM {
  display: flex;
  position: relative;
}
.freeBox___5U5GM > :global(.__wab_flex-container) {
  justify-content: flex-end;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox___5U5GM > :global(.__wab_flex-container) > *,
.freeBox___5U5GM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5U5GM > :global(.__wab_flex-container) > picture > img,
.freeBox___5U5GM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.spinnerContainer2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
}
.cancelButton2:global(.__wab_instance) {
  position: relative;
}
.svg___6COrm {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__muOl {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.confirmButton2:global(.__wab_instance) {
  position: relative;
}
.svg___8Fw5J {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__m1Mj {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
