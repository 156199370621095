.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 16px;
}
.freeBox__zZaCn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text___71ZHi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 12px;
  font-weight: 700;
  min-width: 0;
}
.form {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 16px 8px;
  border-bottom: 1px solid var(--token-hoA5qaM-91G);
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.freeBox__nW6I {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__kDdP0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.notifyCheckbox:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox___5SwlD {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox___5SwlD > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox___5SwlD > :global(.__wab_flex-container) > *,
.freeBox___5SwlD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5SwlD > :global(.__wab_flex-container) > picture > img,
.freeBox___5SwlD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.input:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yfl01 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__okLgH {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.roleSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 108px;
  flex-shrink: 0;
}
.addBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.text__qQqcQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  min-width: 0;
}
.svg__pbIrE {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__h9QS {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__h9QS > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__h9QS > :global(.__wab_flex-container) > *,
.freeBox__h9QS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__h9QS > :global(.__wab_flex-container) > picture > img,
.freeBox__h9QS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns {
  display: flex;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.column__hc3GY {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__saiUl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 12px;
  min-width: 0;
}
.column__o7NGs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__yiZe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 12px;
  min-width: 0;
}
.mainRules:global(.__wab_instance) {
  position: relative;
}
.permissionRule___83YDq:global(.__wab_instance) {
  position: relative;
}
.permissionRule___1HzCo:global(.__wab_instance) {
  position: relative;
}
.permissionRule___1YHz:global(.__wab_instance) {
  position: relative;
}
.permissionRule__zRPqd:global(.__wab_instance) {
  position: relative;
}
