.root {
  display: inline-flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6px;
}
.likeBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
}
.likeBtnstate_like:global(.__wab_instance) {
  flex-shrink: 0;
}
.likeBtnstate_dislike:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__tYoHl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.svgstate_like__tYoHlYkBI {
  color: #1b1b18;
}
.svg___2MjA0 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.dislikeBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
}
.dislikeBtnstate_like:global(.__wab_instance) {
  flex-shrink: 0;
}
.dislikeBtnstate_dislike:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__uQuhx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.svgstate_dislike__uQuhx5IumH {
  color: #1b1b18;
}
.svg___962M9 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
