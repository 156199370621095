.menuContainer {
  box-shadow: 0px 6px 12px 0px #1a1b251f, 0px 1px 3px 0px #1a1b251f,
    0px 0px 0px 1px #1a1b250f;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: #ffffff;
  opacity: 1;
  min-width: 300px;
  position: relative;
  border-radius: 8px;
  padding: 8px 12px;
}
.menuContainerbodyless {
  background: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}
.slotTargetMenuContainer2 {
  color: var(--token-0IloF6TmFvF);
  font-size: var(--token-YU1H6d7MUtVL);
  line-height: 20px;
}
.text__clTcj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 20px;
  min-width: 0;
}
.text__r3A7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
