.root {
  display: flex;
  position: relative;
  flex-direction: column;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.freeBox__pv2In {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.freeBox__vZxO3 {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__vZxO3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__vZxO3 > :global(.__wab_flex-container) > *,
.freeBox__vZxO3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vZxO3 > :global(.__wab_flex-container) > picture > img,
.freeBox__vZxO3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.slotTargetHeading {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
}
.infoIcon {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-fVn5vRhXJxQ);
  flex-shrink: 0;
}
.root .infoIcon:hover {
  color: var(--token-0IloF6TmFvF);
}
.freeBox__irp33 {
  display: flex;
  position: relative;
}
.freeBox__irp33 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__irp33 > :global(.__wab_flex-container) > *,
.freeBox__irp33 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__irp33 > :global(.__wab_flex-container) > picture > img,
.freeBox__irp33
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.viewDocs:global(.__wab_instance) {
  position: relative;
}
.svg__cWp3N {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.more:global(.__wab_instance) {
  position: relative;
}
.svg__opADb {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.freeBox__dDcq {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row-gap: 32px;
  grid-column-gap: 16px;
}
.freeBoxtwoColumnGrid__dDcqL1Zy {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.freeBoxgridColumns__2__dDcqwJdyT {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.freeBoxgridColumns__3__dDcq6FrKj {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.img__bP7Ph {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.img__bP7Ph > picture > img {
  object-fit: cover;
}
.svg__gFtv {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__zkh64 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__zkh64 > picture > img {
  object-fit: cover;
}
.starterProject__s5Vns:global(.__wab_instance) {
  position: relative;
}
.img___9FOdj {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.img___9FOdj > picture > img {
  object-fit: cover;
}
.svg__go0Gq {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__q6GP {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__q6GP > picture > img {
  object-fit: cover;
}
