.rightTrashIcon--vertical {
  position: absolute;
  right: 5px;
  top: 75%;
  transform: translateY(-50%);
}
.rightTrashIcon--horizontal {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
