.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 0px 1px;
}
.freeBox {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 64px;
  min-width: 0;
  flex-shrink: 0;
  display: none;
}
.freeBoxshowPreviewImage {
  display: flex;
}
.img__iEEcN {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__iEEcN > picture > img {
  object-fit: cover;
}
.svg__xaCli {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__zc4N {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg___6O2L {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  height: 1em;
}
.iconButton__ol5Pp:global(.__wab_instance) {
  position: relative;
}
.svg__acGZq {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg___286BO {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  height: 1em;
}
