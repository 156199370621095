.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.rootisSelected {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  border-radius: 4px;
}
.freeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.checker {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: none;
  flex-direction: row;
  overflow: hidden;
  background: url("./images/transparentBackgroundsvg.svg") left 0% top 0% / 12px
    12px repeat padding-box border-box scroll;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
}
.bgOpacity {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #ffffff80;
  min-width: 0;
  min-height: 0;
}
.bgOpacityisSelected {
  left: auto;
  top: auto;
}
.color {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  min-width: 0;
  min-height: 0;
}
.border {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid #00000026;
}
