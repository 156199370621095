.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.header {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: unset;
  left: 0;
  top: 0;
  z-index: 100;
  right: 0;
  background: var(--token-iR8SeEwQZ);
  width: 100%;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid var(--token-hoA5qaM-91G);
}
.headerWrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: unset;
  padding-left: 1.25rem;
  padding-right: 1rem;
  min-width: 0;
}
.headerLogoLink {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 2px;
  padding-right: 2px;
}
.headerLogo {
  object-fit: cover;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.headerActions {
  display: flex;
  position: relative;
  flex-direction: row;
}
.headerActions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.headerActions > :global(.__wab_flex-container) > *,
.headerActions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.headerActions > :global(.__wab_flex-container) > picture > img,
.headerActions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox___8H74X {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  cursor: pointer;
  height: 2rem;
}
.newProjectButtonhideNewProjectButton:global(.__wab_instance) {
  display: none;
}
.svg__pMrgf {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-mu3x63xzJRW);
  height: 1em;
}
.text {
  font-weight: 500;
  color: var(--token-VUsIDivgUss);
}
.svg__iw3P2 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svgnewProjectButtonAsDropdown__iw3P2LewDp {
  margin-left: 5px;
}
.upgradeButton:global(.__wab_instance) {
  position: relative;
  height: 2rem;
}
.svg__vF1 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__uSFd {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.freeTrial:global(.__wab_instance) {
  position: relative;
}
.freeBox__g4Hbj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: unset;
  flex-grow: 1;
  margin-top: 49px;
  min-height: 100vh;
}
.wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 200px;
  align-content: unset;
  top: 49px;
  left: auto;
  z-index: 99;
  bottom: 0;
  overflow: auto;
  background: var(--token-iR8SeEwQZ);
  flex-shrink: 0;
  padding: 8px;
  border-right: 1px solid var(--token-hoA5qaM-91G);
}
@media (min-width: 0px) and (max-width: 768px) {
  .sidebar {
    width: 100%;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    min-width: 0;
    border-bottom: 1px solid rgb(228, 230, 235);
  }
}
.nav {
  display: flex;
  position: relative;
  flex-direction: column;
}
.nav > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.nav > :global(.__wab_flex-container) > *,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nav > :global(.__wab_flex-container) > picture > img,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 4px;
}
.allProjectsButton:global(.__wab_instance) {
  position: relative;
}
.svg__suQ4M {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__ti7An {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.myProjectsButton:global(.__wab_instance) {
  position: relative;
}
.svg__gluGf {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__ebJx {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.navSeparator__e9MNn:global(.__wab_instance) {
  position: relative;
  margin-top: calc(8px + 4px) !important;
  margin-bottom: 8px;
  margin-left: 30px;
}
.navSeparatorhideStarters__e9MNnwfmdR:global(.__wab_instance) {
  margin-top: calc(8px + 4px) !important;
}
.navSeparatorhideTeams__e9MNn5Ktlm:global(.__wab_instance) {
  margin-top: calc(8px + 4px) !important;
  display: none;
}
.freeBox___1I5Dl {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox___1I5Dl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox___1I5Dl > :global(.__wab_flex-container) > *,
.freeBox___1I5Dl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1I5Dl > :global(.__wab_flex-container) > picture > img,
.freeBox___1I5Dl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBoxhideTeams___1I5Dl5Ktlm {
  display: none;
}
.navTeamSection__fNQog:global(.__wab_instance) {
  position: relative;
}
.svg__u8Mf {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.svg__yz3Vs {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.navSeparator__xnjTf:global(.__wab_instance) {
  position: relative;
  margin-top: calc(8px + 4px) !important;
  margin-bottom: 8px;
  margin-left: 30px;
}
.navSeparatorhideStarters__xnjTfwfmdR:global(.__wab_instance) {
  margin-top: calc(8px + 4px) !important;
}
.navSeparatorhideTeams__xnjTf5Ktlm:global(.__wab_instance) {
  margin-top: calc(8px + 4px) !important;
  display: none;
}
.startersButton:global(.__wab_instance) {
  position: relative;
}
.startersButtonhideStarters:global(.__wab_instance) {
  display: none;
}
.svg__cyyvY {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__tkcPl {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.navFooter {
  display: flex;
  position: relative;
  flex-direction: column;
}
.navFooter > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.navFooter > :global(.__wab_flex-container) > *,
.navFooter > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.navFooter > :global(.__wab_flex-container) > picture > img,
.navFooter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.newTeamButton:global(.__wab_instance) {
  position: relative;
}
.svg___9DbPc {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__fmNan {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.documentationButton:global(.__wab_instance) {
  position: relative;
}
.svg___2Eo9K {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg__qc8Uw {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.helpButton:global(.__wab_instance) {
  position: relative;
}
.svg__lkX6 {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg___9PUi {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.userButton:global(.__wab_instance) {
  position: relative;
}
.freeBox__hmXkw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.img__xzqEi {
  object-fit: cover;
  width: 24px;
  height: 24px;
  max-width: 24px;
  flex-shrink: 0;
  border-radius: 50%;
}
.img__xzqEi > picture > img {
  object-fit: cover;
}
.svg__dvFlL {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  margin-left: 200px;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .main {
    margin: 0px;
  }
}
