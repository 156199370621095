.root {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  border-radius: 6px 6px 0px 0px;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.rootselected {
  background: var(--token-bV4cCeIniS6);
}
.root:hover {
  background: var(--token-bV4cCeIniS6);
}
.root:focus-within {
  box-shadow: 0px 0px 0px 2px var(--token-D666zt2IZPL);
  outline: none;
}
.freeBox__vFql {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  display: none;
}
.svg {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  color: var(--token-fVn5vRhXJxQ);
  flex-shrink: 0;
}
.freeBox__oClzd {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  min-width: 0;
}
.slotTargetName {
  color: var(--token-0IloF6TmFvF);
  font-weight: 500;
}
.slotTargetName > *,
.slotTargetName > :global(.__wab_slot) > *,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetName > picture > img,
.slotTargetName > :global(.__wab_slot) > picture > img,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetNameselected {
  color: #1b1b18;
}
.freeBox__hk3YJ {
  position: relative;
  display: flex;
  flex-direction: row;
}
.freeBox__hk3YJ > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__hk3YJ > :global(.__wab_flex-container) > *,
.freeBox__hk3YJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hk3YJ > :global(.__wab_flex-container) > picture > img,
.freeBox__hk3YJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__awqZb {
  font-size: 11px;
  line-height: 1.5;
  color: var(--token-UunsGa2Y3t3);
}
.text__gjCwb {
  position: relative;
}
.textfreeTrial__gjCwbCMcVy {
  display: block;
}
.link {
  font-size: 11px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--token-VUsIDivgUss);
  display: none;
}
.linkfreeTrial {
  display: block;
}
