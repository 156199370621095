.root {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.freeBox {
  flex-direction: row;
  display: flex;
  position: relative;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.slotTargetUrl {
  font-family: "IBM Plex Mono";
}
.deleteBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.5rem;
  height: 1.5rem;
}
.svg__jh5X3 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
