.root {
  display: flex;
  border-right-style: solid;
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 1rem;
  border-top: 1px solid var(--token-hoA5qaM-91G);
}
.root > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.root:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.freeBox__lV3Y {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 30%;
}
.slotTargetName {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
}
.slotTargetEmail {
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__bvhPk {
  display: flex;
  position: relative;
  width: 15%;
}
.slotTargetLastActive {
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__yKwtR {
  display: flex;
  position: relative;
  width: 15%;
}
.slotTargetNumProjects {
  color: var(--token-UunsGa2Y3t3);
}
.freeBox__alQif {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 40%;
}
.freeBox__yVnYe {
  display: flex;
}
.freeBox__yVnYe > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__yVnYe > :global(.__wab_flex-container) > *,
.freeBox__yVnYe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yVnYe > :global(.__wab_flex-container) > picture > img,
.freeBox__yVnYe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.role:global(.__wab_instance) {
  width: 128px;
  flex-shrink: 0;
}
.text {
  width: auto;
}
.option__pMoLu:global(.__wab_instance) {
  position: relative;
  display: none;
}
.option__rEr0:global(.__wab_instance) {
  position: relative;
}
.option__vXrYx:global(.__wab_instance) {
  position: relative;
}
.option__itAig:global(.__wab_instance) {
  position: relative;
}
.option__r0GbZ:global(.__wab_instance) {
  position: relative;
}
.option___0ByQx:global(.__wab_instance) {
  position: relative;
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.roleHelp {
  position: relative;
  object-fit: cover;
  color: var(--token-fVn5vRhXJxQ);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.menuButton:global(.__wab_instance):global(.__wab_instance) {
  flex-shrink: 0;
}
