.root {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 960px;
  width: 100%;
  min-width: 0;
  padding: 1.5rem;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.frame321 {
  opacity: 1;
  transform: scale(1, 1) rotate(0deg) translate(0px, 0px);
  position: relative;
  transform-origin: top left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.frame321 > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame321 > :global(.__wab_flex-container) > *,
.frame321 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame321 > :global(.__wab_flex-container) > picture > img,
.frame321
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__b3Bi4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
}
.h1 {
  font-weight: 600;
  font-size: 20px;
}
.newProjectButton:global(.__wab_instance) {
  position: relative;
}
.svg__fDUq {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__opS5Z {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.tutorials:global(.__wab_instance) {
  position: relative;
}
.tutorialshideStarters:global(.__wab_instance) {
  display: none;
}
.svg__vsz6K {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.starterGroup__mBsAc:global(.__wab_instance) {
  position: relative;
}
.preview22 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview22 > picture > img {
  object-fit: cover;
}
.svg__by7Ek {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__gHpLe {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__gHpLe > picture > img {
  object-fit: cover;
}
.preview223 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview223 > picture > img {
  object-fit: cover;
}
.svg__mwqWs {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__nWagV {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__nWagV > picture > img {
  object-fit: cover;
}
.preview222 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview222 > picture > img {
  object-fit: cover;
}
.svg___8WEqf {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__owx0H {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__owx0H > picture > img {
  object-fit: cover;
}
.starterProject__kroAp:global(.__wab_instance) {
  position: relative;
}
.preview34 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview34 > picture > img {
  object-fit: cover;
}
.text__c5V {
  font-family: "Paytone One", sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.svg__hf1MI {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.img__qMBcL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__qMBcL > picture > img {
  object-fit: cover;
}
.starterProject__wvtKw:global(.__wab_instance) {
  position: relative;
}
.preview32 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview32 > picture > img {
  object-fit: cover;
}
.svg__laDb {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  height: 1em;
}
.img__v8GHj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__v8GHj > picture > img {
  object-fit: cover;
}
.starterProject__mnUxm:global(.__wab_instance) {
  position: relative;
}
.preview33 {
  position: relative;
}
.svg__ekBhK {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  height: 1em;
}
.img___4Azhf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___4Azhf > picture > img {
  object-fit: cover;
}
.starterGroup__xY8Xb:global(.__wab_instance) {
  position: relative;
}
.starterProject__tRkXo:global(.__wab_instance) {
  position: relative;
}
.preview352 {
  height: 100%;
  object-fit: cover;
  position: relative;
  min-height: 0;
}
.preview352 > picture > img {
  object-fit: cover;
}
.svg__kTsVu {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  height: 1em;
}
.img__j6ORt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__j6ORt > picture > img {
  object-fit: cover;
}
.starterProject___1FA8:global(.__wab_instance) {
  position: relative;
}
.preview3524 {
  height: 100%;
  object-fit: cover;
  position: relative;
  min-height: 0;
}
.preview3524 > picture > img {
  object-fit: cover;
}
.svg__bbSn5 {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  height: 1em;
}
.img__mog7Q {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__mog7Q > picture > img {
  object-fit: cover;
}
.starterProject__hb6Rg:global(.__wab_instance) {
  position: relative;
}
.preview3525 {
  height: 100%;
  object-fit: cover;
  position: relative;
  min-height: 0;
}
.preview3525 > picture > img {
  object-fit: cover;
}
.svg__evM1Z {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  height: 1em;
}
.img__rchQz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__rchQz > picture > img {
  object-fit: cover;
}
.freeBox__miuC3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: unset;
}
.filter:global(.__wab_instance) {
  position: relative;
}
.uploadButton:global(.__wab_instance) {
  position: relative;
}
.svg__bsji2 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__jK9HY {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.mainList {
  opacity: 1;
  transform: scale(1, 1) rotate(0deg);
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  border-radius: 0px;
}
.mainList > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.mainList > :global(.__wab_flex-container) > *,
.mainList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mainList > :global(.__wab_flex-container) > picture > img,
.mainList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.mainListnoProjects {
  display: none;
}
.projectListItem___8WVx:global(.__wab_instance) {
  position: relative;
}
.projectListItem__lo2Qn:global(.__wab_instance) {
  position: relative;
}
.projectListItem___4Pih3:global(.__wab_instance) {
  position: relative;
}
.deleted:global(.__wab_instance) {
  position: relative;
}
.svg__xdg9O {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.starterGroup__sNjEj:global(.__wab_instance) {
  position: relative;
}
.preview2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview2 > picture > img {
  object-fit: cover;
}
.svg__epZ7H {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__pP5S5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__pP5S5 > picture > img {
  object-fit: cover;
}
.starterProject__rz9Y6:global(.__wab_instance) {
  position: relative;
}
.preview3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.preview3 > picture > img {
  object-fit: cover;
}
.svg__pEw7 {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img___8UU2Y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___8UU2Y > picture > img {
  object-fit: cover;
}
.starterGroup__bKla6:global(.__wab_instance) {
  position: relative;
}
.img__qgkiq {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.img__qgkiq > picture > img {
  object-fit: cover;
}
.svg__jHE {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img__olWvG {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__olWvG > picture > img {
  object-fit: cover;
}
.starterProject__xgKzV:global(.__wab_instance) {
  position: relative;
}
.img__acfiy {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.img__acfiy > picture > img {
  object-fit: cover;
}
.svg__zwJRf {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  height: 1em;
}
.img___60YkE {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___60YkE > picture > img {
  object-fit: cover;
}
