.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  align-items: center;
  cursor: pointer;
}
.rootisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root___focusVisibleWithin {
  outline: none;
}
.toggle {
  box-shadow: inset 0px 0px 0px 1px var(--token-hoA5qaM-91G);
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 24px;
  background: var(--token-O4S7RMTqZ3);
  flex-shrink: 0;
  border-radius: 16px;
}
.toggleisChecked {
  box-shadow: inset 0px 0px 0px 1px var(--token-IknVZEkiPDz);
  background: var(--token-dqEx_KxIoYV);
}
.root:hover .toggle {
  background: var(--token-bV4cCeIniS6);
}
.rootisChecked:hover .toggleisChecked {
  background: var(--token-RhvOnhv_xIi);
}
.root:focus-within .toggle {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
  border-color: #1976d2;
}
.root .toggle___focusVisibleWithin {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
  border-style: dashed;
  border-color: #1976d2;
}
.thumb {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
  box-shadow: none;
  transition-property: all;
  transition-duration: 0.2s;
  background: var(--token-fVn5vRhXJxQ);
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
  border: 1px solid transparent;
}
.thumbisChecked {
  left: 20px;
  right: auto;
  background: var(--token-D666zt2IZPL);
}
.root:focus-within .thumb {
  outline: none;
}
.root .thumb___focusVisibleWithin {
  outline: none;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.root .labelContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  font-size: 12px;
  line-height: 1.25;
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
