.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: stretch;
  cursor: pointer;
  min-width: 0;
}
.tplRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2Wwcl {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.text__g6AcD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - var(--token-uzWT6AFCY));
  height: calc(100% + var(--token-uzWT6AFCY));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-uzWT6AFCY);
}
.variableRow__dGtU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.variableRow__pyXrl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.variableRow___5G4Dt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: var(--token-aDa535tnF);
  flex-shrink: 0;
}
