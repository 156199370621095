.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  align-items: center;
}
.root:focus-within {
  outline: none;
}
.root___focusVisibleWithin {
  outline: none;
}
.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  flex-shrink: 0;
}
.root:focus-within .toggle {
  outline: none;
}
.root .toggle___focusVisibleWithin {
  outline: none;
}
.track {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  transition-property: background;
  transition-duration: 0.2s;
  background: #f3f3f2;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.2s;
  border-radius: 12px;
  border: 1px solid #ffffff00;
}
.trackisChecked {
  background: #0091ff;
}
.root:hover .track {
  background: #eeeeec;
}
.rootisChecked:hover .trackisChecked {
  background: #0081f1;
}
.root:focus-within .track {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root .track___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root:active .track {
  background: #e9e9e6;
}
.thumb {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 4px;
  top: 8px;
  width: 16px;
  height: 16px;
  transition-property: all;
  transition-duration: 0.2s;
  background: #ffffff;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
  border: 1px solid #dbdbd7;
}
.thumbisChecked {
  left: 20px;
  right: auto;
  border-style: none;
}
.root:hover .thumb {
  background: #f9f9f9;
  border-color: #c8c7c1;
}
.root:focus-within .thumb {
  outline: none;
}
.root .thumb___focusVisibleWithin {
  outline: none;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.root .labelContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
