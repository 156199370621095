.root {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  min-width: 0;
  min-height: 0;
}
.leftSearchPanel:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.leftSearchPanelcompact:global(.__wab_instance) {
  flex-shrink: 0;
}
.iconsHeader:global(.__wab_instance) {
  position: relative;
}
.svg__rksSt {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg__tPc16 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.iconInfo:global(.__wab_instance) {
  max-width: 100%;
}
.text__k0Tq1 {
  font-weight: 600;
  font-size: 14px;
}
.iconsContent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: hidden;
  min-width: 0;
  border-bottom: 1px solid var(--token-hoA5qaM-91G);
}
.imagesHeader:global(.__wab_instance) {
  position: relative;
}
.svg___5CEB {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg___0VoGg {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.imageInfo:global(.__wab_instance) {
  max-width: 100%;
}
.text__hnR4J {
  font-weight: 600;
  font-size: 14px;
}
.imagesContent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: hidden;
  min-width: 0;
}
