.root:global(.__wab_instance) {
  position: relative;
}
.svg__unKzu {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1rem;
  height: 1rem;
}
.svghasValue__unKzuuzQ8L {
  left: auto;
  top: auto;
}
.svgmedium__unKzuKYp1 {
  width: 1.25rem;
  height: 1.25rem;
}
.svg___2K8R {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1rem;
  height: 1rem;
}
.svghasValue___2K8RuzQ8L {
  left: auto;
  top: auto;
}
