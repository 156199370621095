.root {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  background: var(--token-iR8SeEwQZ);
  min-width: 0;
}
.titleContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px 16px;
}
.slotTargetTitle {
  font-weight: 500;
  white-space: pre;
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetTitle > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot-string-wrapper),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetTitle > *,
.slotTargetTitle > :global(.__wab_slot) > *,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetTitle > picture > img,
.slotTargetTitle > :global(.__wab_slot) > picture > img,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.option__q5Xro:global(.__wab_instance) {
  position: relative;
}
.option__kFyB3:global(.__wab_instance) {
  position: relative;
}
.separator2 {
  display: flex;
  position: relative;
  height: 1px;
  background: var(--token-hoA5qaM-91G);
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  min-width: 0;
  flex-shrink: 0;
}
.separator2isFirst {
  display: flex;
}
.separator2isLast {
  display: none;
}
