.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: inset 0px 0px 0px 1px var(--token-hoA5qaM-91G);
  opacity: 1;
  background: rgb(255, 255, 255);
  min-width: 0;
  border-radius: 8px;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.text__g2NsR {
  position: relative;
  height: auto;
  width: auto;
  white-space: pre;
}
.hostProtocolSelect:global(.__wab_instance) {
  position: relative;
}
.option__jSgyS:global(.__wab_instance) {
  position: relative;
}
.option__nopaD:global(.__wab_instance) {
  position: relative;
}
.urlInput {
  position: relative;
  width: 100%;
  margin-left: calc(-9px + 8px) !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  min-width: 0;
  padding: 7px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.clearButton:global(.__wab_instance) {
  position: relative;
}
.svg__wNkJa {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text__sYxzC {
  height: auto;
}
.svg__vgMoN {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.confirmButton:global(.__wab_instance) {
  position: relative;
}
.svg__x95OL {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text__ijKr {
  height: auto;
}
.svg__idqB {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
