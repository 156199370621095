.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.defaultLayout:global(.__wab_instance) {
  position: relative;
}
.workspaceSection:global(.__wab_instance) {
  position: relative;
}
.projectListItem__seC2O:global(.__wab_instance) {
  position: relative;
}
.projectListItem__qnZxn:global(.__wab_instance) {
  position: relative;
}
.projectListItem__dNcjL:global(.__wab_instance) {
  position: relative;
}
.projectListItem__fs4Vv:global(.__wab_instance) {
  position: relative;
}
.projectListItem___4BZuv:global(.__wab_instance) {
  position: relative;
}
.projectListItem__sLb7K:global(.__wab_instance) {
  position: relative;
}
.img__gK7GD {
  object-fit: cover;
  width: 24px;
  height: 24px;
  max-width: 24px;
  border-radius: 50%;
}
.img__gK7GD > picture > img {
  object-fit: cover;
}
.navTeamSection:global(.__wab_instance) {
  position: relative;
}
.svg__ns9Ei {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.svg__akQpn {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
