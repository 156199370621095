.root {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.tooltip:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox___0Wi8O {
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  border-bottom: 2px dotted #000000;
}
.slotTargetChildren {
  font-family: "Inter", sans-serif;
  font-size: var(--token-YU1H6d7MUtVL);
  font-weight: 400;
  font-style: normal;
  color: var(--token-mlyauzeAvywY);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.popout:global(.__wab_instance) {
  position: relative;
  margin-top: 0px;
}
.freeBox__rS50D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 300px;
  min-width: 0;
}
.slotTargetPopover {
  font-size: var(--token-YU1H6d7MUtVL);
  line-height: 18px;
  font-weight: 400;
}
