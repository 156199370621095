.root:global(.__wab_instance) {
  position: relative;
}
.svg__fSc0K {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1rem;
  height: 1rem;
}
.svgpinState_pinnedTrue__fSc0KdSe1E {
  color: var(--token-Y2CWh0ci95a);
}
.svgpinState_pinnedFalse__fSc0KPSWf {
  color: var(--token-Y2CWh0ci95a);
}
.svgpinState_selected__fSc0KEqRw {
  color: var(--token-Y2CWh0ci95a);
}
.svgpinState_evaluatedTrue__fSc0KznhMp {
  color: var(--token-Y2CWh0ci95a);
}
.svg__dl131 {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  width: 1em;
  height: 1em;
}
