.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.defaultLayout:global(.__wab_instance) {
  position: relative;
}
.workspaceSection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.projectListItem__arWpc:global(.__wab_instance) {
  position: relative;
}
.projectListItem__ziPzg:global(.__wab_instance) {
  position: relative;
}
.img__fvceM {
  object-fit: cover;
  width: 24px;
  height: 24px;
  max-width: 24px;
  border-radius: 50%;
}
.img__fvceM > picture > img {
  object-fit: cover;
}
.navTeamSection:global(.__wab_instance) {
  position: relative;
}
.svg__mWBm6 {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.svg__n8Hx {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
