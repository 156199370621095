.root {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
.freeBox__nOs6U {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  background: rgb(224, 62, 40);
  display: none;
  padding: 9px;
}
.freeBoxshowBillingError__nOs6UXmPkf {
  background: var(--token-M6q4nJev3CK);
  display: flex;
  border-radius: 8px;
}
.slotTargetBillingError {
  color: var(--token-MuSUbZOS_NL);
  font-weight: 600;
}
.freeBox__ftaU {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-right: 32px;
}
.freeBox__xXxvr {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__xXxvr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.freeBox__xXxvr > :global(.__wab_flex-container) > *,
.freeBox__xXxvr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xXxvr > :global(.__wab_flex-container) > picture > img,
.freeBox__xXxvr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.freeBox___7YcIa {
  display: flex;
  flex-direction: column;
}
.text___70ASv {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
}
.freeBox___2PErC {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox___2PErC > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___2PErC > :global(.__wab_flex-container) > *,
.freeBox___2PErC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2PErC > :global(.__wab_flex-container) > picture > img,
.freeBox___2PErC
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text__qCvxa {
  color: var(--token-UunsGa2Y3t3);
  height: auto;
}
.learnMore {
  position: relative;
}
.freeTrial:global(.__wab_instance) {
  position: relative;
  margin-top: 0px;
  align-self: flex-end;
}
.freeTrialtier_free:global(.__wab_instance) {
  display: flex;
}
.freeBox__fQ70J {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 8px;
  padding-top: 8px;
}
.freeBox__fQ70J > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-end;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__fQ70J > :global(.__wab_flex-container) > *,
.freeBox__fQ70J > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fQ70J > :global(.__wab_flex-container) > picture > img,
.freeBox__fQ70J
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBoxtier_free__fQ70JXEnAp {
  display: none;
}
.freeBoxtier_enterprise__fQ70J5Ph4X {
  display: none;
}
.slotTargetCurrentBill {
  font-size: 1rem;
}
.text___4Yo4 {
  position: relative;
  height: auto;
  color: var(--token-UunsGa2Y3t3);
  margin-bottom: 0px;
}
.freeBox__mOmdD {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
}
.freeBox__mOmdD > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-end;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__mOmdD > :global(.__wab_flex-container) > *,
.freeBox__mOmdD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mOmdD > :global(.__wab_flex-container) > picture > img,
.freeBox__mOmdD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox___2NKs {
  display: flex;
  position: relative;
}
.freeBox___2NKs > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___2NKs > :global(.__wab_flex-container) > *,
.freeBox___2NKs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2NKs > :global(.__wab_flex-container) > picture > img,
.freeBox___2NKs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.slotTargetSeatsUsed {
  font-size: 1rem;
  font-weight: 500;
}
.text__gJjIm {
  position: relative;
  font-size: 1rem;
}
.slotTargetSeatsPurchased {
  font-size: 1rem;
}
.text__iz26I {
  font-size: 1rem;
  height: auto;
}
.text__ofLuw {
  position: relative;
  height: auto;
  color: var(--token-UunsGa2Y3t3);
}
.billingFrequencyToggle:global(.__wab_instance) {
  position: relative;
}
.text___0IM {
  font-size: 16px;
}
.priceTierPicker:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.premiumSections {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 32px;
}
.premiumSections > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.premiumSections > :global(.__wab_flex-container) > *,
.premiumSections > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.premiumSections > :global(.__wab_flex-container) > picture > img,
.premiumSections
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.premiumSectionstier_free {
  display: none;
}
.freeBox__egD9R {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.freeBox__egD9R > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__egD9R > :global(.__wab_flex-container) > *,
.freeBox__egD9R > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__egD9R > :global(.__wab_flex-container) > picture > img,
.freeBox__egD9R
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__sDa4Q {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
}
.freeBox__oz3Ad {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__oz3Ad > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__oz3Ad > :global(.__wab_flex-container) > *,
.freeBox__oz3Ad > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oz3Ad > :global(.__wab_flex-container) > picture > img,
.freeBox__oz3Ad
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__dsQj7 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
}
.freeBox___4WK2C {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox___4WK2C > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox___4WK2C > :global(.__wab_flex-container) > *,
.freeBox___4WK2C > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4WK2C > :global(.__wab_flex-container) > picture > img,
.freeBox___4WK2C
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.billingEmail {
  width: 320px;
  height: 2rem;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.updateBillingEmailButton:global(.__wab_instance) {
  position: relative;
  height: 2rem;
}
.svg__dEpin {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text__vqNbp {
  font-size: 14px;
}
.svg__lL73 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.freeBox___2CSw {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 2rem;
}
.freeBox___2CSw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox___2CSw > :global(.__wab_flex-container) > *,
.freeBox___2CSw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2CSw > :global(.__wab_flex-container) > picture > img,
.freeBox___2CSw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBoxtier_enterprise___2CSw5Ph4X {
  display: none;
}
.changeSeatsButton:global(.__wab_instance) {
  position: relative;
}
.svg__zyBz8 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text__uzg1X {
  height: auto;
  font-size: 14px;
}
.svg__a4XtS {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.changeCreditCardButton:global(.__wab_instance) {
  position: relative;
}
.svg__g7GmU {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text__l6WqO {
  font-size: 14px;
}
.svg__bwN41 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.cancelSubscriptionButton:global(.__wab_instance) {
  position: relative;
}
.svg__htmVy {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--token-Y2CWh0ci95a);
}
.text__iI7N {
  font-size: 14px;
}
.svg__yK6Zg {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
