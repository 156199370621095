.root {
  flex-direction: row;
  display: flex;
  height: var(--token-aDa535tnF);
  padding-left: 36px;
  position: relative;
  width: 100%;
  padding-right: var(--token-NCc1lDy9R);
  min-width: 0;
  border: 1px solid rgba(255, 255, 255, 0);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-uzWT6AFCY));
  width: calc(100% + var(--token-uzWT6AFCY));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-uzWT6AFCY);
}
.root:hover {
  box-shadow: none;
  border-color: var(--token-iDpByWoW0eF);
}
.iconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.svg__xqw7 {
  object-fit: cover;
  width: 24px;
  height: 24px;
  color: var(--token-eBt2ZgqRUCz);
  flex-shrink: 0;
}
.labelContainer {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0px;
}
.labelContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.labelContainer > :global(.__wab_flex-container) > *,
.labelContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.labelContainer > :global(.__wab_flex-container) > picture > img,
.labelContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.actionsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-shrink: 0;
}
.visibleButton:global(.__wab_instance) {
  position: relative;
}
.svg__dJrf {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.svg__d7Wtc {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  width: 1em;
  height: 1em;
}
.indicator:global(.__wab_instance) {
  position: absolute;
  left: -7px;
  top: 9px;
}
