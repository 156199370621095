.root {
  flex-direction: row;
  display: flex;
  position: relative;
  padding: 0.5rem;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox {
  display: flex;
  position: relative;
}
.freeBox > :global(.__wab_flex-container) {
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxcopyState_copied {
  width: auto;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  height: auto;
  align-self: auto;
}
.svg___2Kau {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--token-UunsGa2Y3t3);
  flex-shrink: 0;
}
.slotTargetTokenValue {
  font-family: "IBM Plex Mono";
}
.copyLink {
  position: relative;
  width: auto;
  height: auto;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  color: var(--token-HECc6yZMKR);
  cursor: pointer;
}
.copyLinkcopyState_copied {
  cursor: default;
}
.deleteBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gp5XI {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.5rem;
  height: 1.5rem;
}
.svg__hjkke {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
