
$focus-ring: rgba(0, 145, 255, 0.5)
$icon-size: 16px
$list-item-gap: 8px
$list-item-h-padding: 16px
$list-item-height: 32px
$list-section-header-height: 44px
$multiplayer-color01: rgb(48, 164, 108)
$multiplayer-color02: rgb(104, 221, 253)
$multiplayer-color03: rgb(142, 78, 198)
$multiplayer-color04: rgb(5, 162, 194)
$multiplayer-color05: rgb(214, 64, 159)
$multiplayer-color06: rgb(245, 217, 10)
$multiplayer-color07: rgb(247, 104, 8)
$spectrum-blue-15: rgb(5, 33, 77)
$spectrum-blue-60: rgb(69, 153, 255)
$spectrum-blue-95: rgb(231, 243, 255)
$spectrum-coral-40: rgb(224,62,40)rgb(224,62,40)rgb(224,62,40)rgb(224,62,40)
$spectrum-coral-50: rgb(245,83,61)rgb(245,83,61)rgb(245,83,61)
$spectrum-coral-80: rgb(255, 186, 176)
$spectrum-coral-95: rgb(255,230,227)rgb(255,230,227)rgb(255,230,227)
$spectrum-cyan-40: rgb(0, 110, 173)
$spectrum-cyan-50: rgb(0,139,208)rgb(0,139,208)
$spectrum-cyan-55: rgb(0, 153, 230)
$spectrum-cyan-60: rgb(4,164,244)#04A4F4rgb(4,164,244)rgb(4,164,244)
$spectrum-cyan-70: rgb(79, 196, 251)
$spectrum-cyan-95: rgb(224,247,255)rgb(224,247,255)
$spectrum-gray-00: rgb(5,5,5)rgb(5,5,5)
$spectrum-gray-05: rgb(14,15,15)rgb(14,15,15)
$spectrum-gray-100: rgb(245,247,250)rgb(245,247,250)rgb(245,247,250)rgb(245,247,250)
$spectrum-gray-20: rgb(50,52,54)#323436rgb(50,52,54)rgb(50,52,54)rgb(50,52,54)
$spectrum-gray-30: rgb(74,76,79)rgb(74,76,79)
$spectrum-gray-50: rgb(122,125,130)rgb(122,125,130)rgb(122,125,130)
$spectrum-gray-55: rgb(138, 141, 145)
$spectrum-gray-60: rgb(150,153,158)rgb(150,153,158)rgb(150,153,158)rgb(150,153,158)
$spectrum-gray-65: rgb(164,167,171)rgb(164,167,171)rgb(164,167,171)rgb(164,167,171)
$spectrum-gray-80: rgb(201,204,209)rgb(201,204,209)
$spectrum-gray-85: rgb(215, 218, 222)
$spectrum-gray-90: rgb(228,230,235)rgb(228,230,235)rgb(228,230,235)
$spectrum-gray-95: rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)
$spectrum-green-50: rgb(49, 162, 76)
$spectrum-green-60: rgb(69, 189, 98)
$spectrum-red-35: rgb(228,30,63)rgb(228,30,63)
$spectrum-red-40: rgb(240, 40, 73)
$spectrum-yellow-30: rgb(181, 84, 0)
$spectrum-yellow-55: rgb(241, 168, 23)
$spectrum-yellow-60: rgb(247, 185, 40)
$spectrum-yellow-95: rgb(255,249,209)rgb(255,249,209)rgb(255,249,209)
$tab-item-selected-bg: rgb(235, 246, 255)
$tab-item-selected-foreground: rgb(0, 145, 255)
$active-bg: rgb(237, 246, 255)
$active-fg: rgb(0, 145, 255)
$black: rgb(0, 0, 0)
$black-a1: rgba(0, 0, 0, 0.01)
$black-a10: rgba(0, 0, 0, 0.48)
$black-a11: rgba(0, 0, 0, 0.57)
$black-a12: rgba(0, 0, 0, 0.91)
$black-a2: rgba(0, 0, 0, 0.03)
$black-a3: rgba(0, 0, 0, 0.05)
$black-a4: rgba(0, 0, 0, 0.07)
$black-a5: rgba(0, 0, 0, 0.09)
$black-a6: rgba(0, 0, 0, 0.11)
$black-a7: rgba(0, 0, 0, 0.14)
$black-a8: rgba(0, 0, 0, 0.22)
$black-a9: rgba(0, 0, 0, 0.44)
$blue1: rgb(251, 253, 255)
$blue10: rgb(0, 129, 241)
$blue11: rgb(0, 106, 220)
$blue12: rgb(0, 37, 77)
$blue2: rgb(245, 250, 255)
$blue3: rgb(237, 246, 255)
$blue4: rgb(225, 240, 255)
$blue5: rgb(206, 231, 254)
$blue6: rgb(183, 217, 248)
$blue7: rgb(150, 199, 242)
$blue8: rgb(94, 176, 239)
$blue9: rgb(0, 145, 255)
$blue-dark1: rgb(15, 23, 32)
$blue-dark10: rgb(54, 158, 255)
$blue-dark11: rgb(82, 169, 255)
$blue-dark12: rgb(234, 246, 255)
$blue-dark2: rgb(15, 27, 45)
$blue-dark3: rgb(16, 36, 62)
$blue-dark4: rgb(16, 42, 76)
$blue-dark5: rgb(15, 48, 88)
$blue-dark6: rgb(13, 56, 104)
$blue-dark7: rgb(10, 68, 129)
$blue-dark8: rgb(9, 84, 165)
$blue-dark9: rgb(0, 145, 255)
$border-color: rgb(224, 224, 224)
$brown1: rgb(254, 253, 252)
$brown10: rgb(160, 118, 83)
$brown11: rgb(136, 99, 73)
$brown12: rgb(63, 44, 34)
$brown2: rgb(252, 249, 246)
$brown3: rgb(248, 241, 234)
$brown4: rgb(244, 233, 221)
$brown5: rgb(239, 221, 204)
$brown6: rgb(232, 205, 181)
$brown7: rgb(221, 184, 150)
$brown8: rgb(208, 158, 114)
$brown9: rgb(173, 127, 88)
$brown-dark1: rgb(25, 21, 19)
$brown-dark10: rgb(189, 139, 96)
$brown-dark11: rgb(219, 161, 110)
$brown-dark12: rgb(250, 240, 229)
$brown-dark2: rgb(34, 24, 19)
$brown-dark3: rgb(46, 32, 26)
$brown-dark4: rgb(54, 38, 30)
$brown-dark5: rgb(62, 44, 34)
$brown-dark6: rgb(62, 44, 34)
$brown-dark7: rgb(92, 67, 50)
$brown-dark8: rgb(119, 89, 64)
$brown-dark9: rgb(173, 127, 88)
$crimson1: rgb(255, 252, 253)
$crimson10: rgb(224, 49, 119)
$crimson11: rgb(211, 30, 102)
$crimson12: rgb(61, 13, 29)
$crimson2: rgb(255, 247, 251)
$crimson3: rgb(254, 239, 246)
$crimson4: rgb(252, 229, 240)
$crimson5: rgb(249, 216, 231)
$crimson6: rgb(244, 198, 219)
$crimson7: rgb(237, 173, 200)
$crimson8: rgb(229, 143, 177)
$crimson9: rgb(233, 61, 130)
$crimson-dark1: rgb(29, 20, 24)
$crimson-dark10: rgb(240, 79, 136)
$crimson-dark11: rgb(247, 97, 144)
$crimson-dark12: rgb(254, 236, 244)
$crimson-dark2: rgb(39, 20, 28)
$crimson-dark3: rgb(60, 24, 39)
$crimson-dark4: rgb(72, 26, 45)
$crimson-dark5: rgb(84, 27, 51)
$crimson-dark6: rgb(100, 29, 59)
$crimson-dark7: rgb(128, 29, 69)
$crimson-dark8: rgb(174, 25, 85)
$crimson-dark9: rgb(233, 61, 130)
$cyan1: rgb(250, 253, 254)
$cyan10: rgb(8, 148, 179)
$cyan11: rgb(12, 119, 146)
$cyan12: rgb(4, 49, 60)
$cyan2: rgb(242, 252, 253)
$cyan3: rgb(231, 249, 251)
$cyan4: rgb(216, 243, 246)
$cyan5: rgb(196, 234, 239)
$cyan6: rgb(170, 222, 230)
$cyan7: rgb(132, 205, 218)
$cyan8: rgb(61, 185, 207)
$cyan9: rgb(5, 162, 194)
$cyan-dark1: rgb(7, 25, 29)
$cyan-dark10: rgb(0, 177, 204)
$cyan-dark11: rgb(0, 194, 215)
$cyan-dark12: rgb(225, 248, 250)
$cyan-dark2: rgb(6, 30, 36)
$cyan-dark3: rgb(7, 40, 48)
$cyan-dark4: rgb(7, 48, 59)
$cyan-dark5: rgb(7, 56, 68)
$cyan-dark6: rgb(6, 65, 80)
$cyan-dark7: rgb(4, 80, 99)
$cyan-dark8: rgb(0, 100, 125)
$cyan-dark9: rgb(5, 162, 194)
$enterprise-background: rgb(22, 22, 21)
$gold1: rgb(253, 253, 252)
$gold10: rgb(140, 121, 93)
$gold11: rgb(119, 103, 80)
$gold12: rgb(59, 53, 43)
$gold2: rgb(251, 249, 242)
$gold3: rgb(245, 242, 233)
$gold4: rgb(238, 234, 221)
$gold5: rgb(229, 223, 208)
$gold6: rgb(218, 209, 189)
$gold7: rgb(203, 189, 164)
$gold8: rgb(184, 163, 131)
$gold9: rgb(151, 131, 101)
$gold-dark1: rgb(23, 22, 19)
$gold-dark10: rgb(165, 144, 113)
$gold-dark11: rgb(191, 168, 136)
$gold-dark12: rgb(247, 244, 231)
$gold-dark2: rgb(28, 26, 21)
$gold-dark3: rgb(38, 35, 28)
$gold-dark4: rgb(46, 42, 33)
$gold-dark5: rgb(53, 48, 38)
$gold-dark6: rgb(62, 56, 44)
$gold-dark7: rgb(80, 71, 55)
$gold-dark8: rgb(107, 93, 72)
$gold-dark9: rgb(151, 131, 101)
$grass1: rgb(251, 254, 251)
$grass10: rgb(61, 154, 80)
$grass11: rgb(41, 124, 59)
$grass12: rgb(27, 49, 30)
$grass2: rgb(243, 252, 243)
$grass3: rgb(235, 249, 235)
$grass4: rgb(223, 243, 223)
$grass5: rgb(206, 235, 207)
$grass6: rgb(183, 223, 186)
$grass7: rgb(151, 207, 156)
$grass8: rgb(101, 186, 117)
$grass9: rgb(70, 167, 88)
$grass-dark1: rgb(13, 25, 18)
$grass-dark10: rgb(85, 180, 103)
$grass-dark11: rgb(99, 193, 116)
$grass-dark12: rgb(229, 251, 235)
$grass-dark2: rgb(15, 30, 19)
$grass-dark3: rgb(19, 40, 25)
$grass-dark4: rgb(22, 48, 29)
$grass-dark5: rgb(25, 57, 33)
$grass-dark6: rgb(29, 68, 39)
$grass-dark7: rgb(36, 85, 48)
$grass-dark8: rgb(47, 110, 59)
$grass-dark9: rgb(70, 167, 88)
$green1: rgb(251, 254, 252)
$green10: rgb(41, 151, 100)
$green11: rgb(24, 121, 78)
$green12: rgb(21, 50, 38)
$green2: rgb(242, 252, 245)
$green3: rgb(233, 249, 238)
$green4: rgb(221, 243, 228)
$green5: rgb(204, 235, 215)
$green6: rgb(180, 223, 196)
$green7: rgb(146, 206, 172)
$green8: rgb(91, 185, 140)
$green9: rgb(48, 164, 108)
$green-dark1: rgb(13, 25, 18)
$green-dark10: rgb(60, 177, 121)
$green-dark11: rgb(76, 195, 138)
$green-dark12: rgb(229, 251, 235)
$green-dark2: rgb(12, 31, 23)
$green-dark3: rgb(15, 41, 30)
$green-dark4: rgb(17, 49, 35)
$green-dark5: rgb(19, 57, 41)
$green-dark6: rgb(22, 68, 48)
$green-dark7: rgb(27, 84, 58)
$green-dark8: rgb(35, 110, 74)
$green-dark9: rgb(48, 164, 108)
$h1-font-size: 72px
$h2-font-size: 48px
$h3-font-size: 32px
$h4-font-size: 24px
$h5-font-size: 20px
$h6-font-size: 16px
$indigo1: rgb(253, 253, 254)
$indigo10: rgb(58, 92, 204)
$indigo11: rgb(52, 81, 178)
$indigo12: rgb(16, 29, 70)
$indigo2: rgb(248, 250, 255)
$indigo3: rgb(240, 244, 255)
$indigo4: rgb(230, 237, 254)
$indigo5: rgb(217, 226, 252)
$indigo6: rgb(198, 212, 249)
$indigo7: rgb(174, 192, 245)
$indigo8: rgb(141, 164, 239)
$indigo9: rgb(62, 99, 221)
$indigo-dark1: rgb(19, 22, 32)
$indigo-dark10: rgb(83, 115, 231)
$indigo-dark11: rgb(132, 157, 255)
$indigo-dark12: rgb(238, 241, 253)
$indigo-dark2: rgb(21, 25, 45)
$indigo-dark3: rgb(25, 33, 64)
$indigo-dark4: rgb(28, 39, 79)
$indigo-dark5: rgb(31, 44, 92)
$indigo-dark6: rgb(34, 52, 110)
$indigo-dark7: rgb(39, 62, 137)
$indigo-dark8: rgb(47, 78, 178)
$indigo-dark9: rgb(62, 99, 221)
$inherited-border: rgba(0, 0, 0, 0.14)
$medium-font-size: 16px
$neutral-500: rgb(102, 109, 128)
$neutral-600: rgb(54, 57, 74)
$neutral-primary: rgb(27, 27, 24)
$neutral-secondary: rgb(112, 111, 108)
$normal-font-size: 12px
$orange1: rgb(254, 252, 251)
$orange10: rgb(237, 95, 0)
$orange11: rgb(189, 75, 0)
$orange12: rgb(69, 30, 17)
$orange2: rgb(254, 248, 244)
$orange3: rgb(255, 241, 231)
$orange4: rgb(255, 232, 215)
$orange5: rgb(255, 220, 195)
$orange6: rgb(255, 204, 167)
$orange7: rgb(255, 179, 129)
$orange8: rgb(250, 147, 78)
$orange9: rgb(247, 104, 8)
$orange-dark1: rgb(31, 18, 6)
$orange-dark10: rgb(255, 128, 43)
$orange-dark11: rgb(255, 139, 62)
$orange-dark12: rgb(254, 234, 221)
$orange-dark2: rgb(43, 20, 0)
$orange-dark3: rgb(57, 26, 3)
$orange-dark4: rgb(68, 31, 4)
$orange-dark5: rgb(79, 35, 5)
$orange-dark6: rgb(95, 42, 6)
$orange-dark7: rgb(118, 50, 5)
$orange-dark8: rgb(148, 62, 0)
$orange-dark9: rgb(247, 104, 8)
$panel-border: rgb(228,230,235)#E4E6EB
$pink1: rgb(255, 252, 254)
$pink10: rgb(210, 49, 151)
$pink11: rgb(205, 29, 141)
$pink12: rgb(59, 10, 42)
$pink2: rgb(255, 247, 252)
$pink3: rgb(254, 238, 248)
$pink4: rgb(252, 229, 243)
$pink5: rgb(249, 216, 236)
$pink6: rgb(243, 198, 226)
$pink7: rgb(236, 173, 212)
$pink8: rgb(227, 142, 195)
$pink9: rgb(214, 64, 159)
$pink-dark1: rgb(31, 18, 27)
$pink-dark10: rgb(227, 75, 169)
$pink-dark11: rgb(246, 92, 182)
$pink-dark12: rgb(254, 235, 247)
$pink-dark2: rgb(39, 20, 33)
$pink-dark3: rgb(58, 24, 47)
$pink-dark4: rgb(69, 26, 55)
$pink-dark5: rgb(80, 27, 63)
$pink-dark6: rgb(96, 29, 72)
$pink-dark7: rgb(122, 29, 90)
$pink-dark8: rgb(167, 24, 115)
$pink-dark9: rgb(214, 64, 159)
$plum1: rgb(254, 252, 255)
$plum10: rgb(164, 60, 180)
$plum11: rgb(156, 43, 173)
$plum12: rgb(52, 12, 59)
$plum2: rgb(255, 248, 255)
$plum3: rgb(252, 239, 252)
$plum4: rgb(249, 229, 249)
$plum5: rgb(243, 217, 244)
$plum6: rgb(235, 200, 237)
$plum7: rgb(223, 175, 227)
$plum8: rgb(207, 145, 216)
$plum9: rgb(171, 74, 186)
$plum-dark1: rgb(29, 19, 29)
$plum-dark10: rgb(189, 84, 198)
$plum-dark11: rgb(216, 100, 216)
$plum-dark12: rgb(251, 236, 252)
$plum-dark2: rgb(37, 20, 37)
$plum-dark3: rgb(52, 26, 52)
$plum-dark4: rgb(62, 29, 64)
$plum-dark5: rgb(72, 33, 75)
$plum-dark6: rgb(84, 38, 88)
$plum-dark7: rgb(105, 45, 111)
$plum-dark8: rgb(136, 56, 148)
$plum-dark9: rgb(171, 74, 186)
$purple1: rgb(254, 252, 254)
$purple10: rgb(132, 69, 188)
$purple11: rgb(121, 58, 175)
$purple12: rgb(43, 14, 68)
$purple2: rgb(253, 250, 255)
$purple3: rgb(249, 241, 254)
$purple4: rgb(243, 231, 252)
$purple5: rgb(237, 219, 249)
$purple6: rgb(227, 204, 244)
$purple7: rgb(211, 180, 237)
$purple8: rgb(190, 147, 228)
$purple9: rgb(142, 78, 198)
$purple-dark1: rgb(27, 20, 29)
$purple-dark10: rgb(157, 91, 210)
$purple-dark11: rgb(191, 122, 240)
$purple-dark12: rgb(247, 236, 252)
$purple-dark2: rgb(34, 21, 39)
$purple-dark3: rgb(48, 26, 58)
$purple-dark4: rgb(58, 30, 72)
$purple-dark5: rgb(67, 33, 85)
$purple-dark6: rgb(78, 38, 103)
$purple-dark7: rgb(95, 45, 132)
$purple-dark8: rgb(121, 56, 178)
$purple-dark9: rgb(142, 78, 198)
$red1: rgb(255, 252, 252)
$red10: rgb(220, 61, 67)
$red11: rgb(205, 43, 49)
$red12: rgb(56, 19, 22)
$red2: rgb(255, 248, 248)
$red3: rgb(255, 239, 239)
$red4: rgb(255, 229, 229)
$red5: rgb(253, 216, 216)
$red6: rgb(249, 198, 198)
$red7: rgb(243, 174, 175)
$red8: rgb(235, 144, 145)
$red9: rgb(229, 72, 77)
$red-dark1: rgb(31, 19, 21)
$red-dark10: rgb(242, 85, 90)
$red-dark11: rgb(255, 99, 105)
$red-dark12: rgb(254, 236, 238)
$red-dark2: rgb(41, 20, 21)
$red-dark3: rgb(60, 24, 26)
$red-dark4: rgb(72, 26, 29)
$red-dark5: rgb(84, 27, 31)
$red-dark6: rgb(103, 30, 34)
$red-dark7: rgb(130, 32, 37)
$red-dark8: rgb(170, 36, 41)
$red-dark9: rgb(229, 72, 77)
$sand1: rgb(253, 253, 252)
$sand10: rgb(134, 134, 130)
$sand11: rgb(112, 111, 108)
$sand12: rgb(27, 27, 24)
$sand2: rgb(249, 249, 248)
$sand3: rgb(243, 243, 242)
$sand4: rgb(238, 238, 236)
$sand5: rgb(233, 233, 230)
$sand6: rgb(227, 227, 224)
$sand7: rgb(219, 219, 215)
$sand8: rgb(200, 199, 193)
$sand9: rgb(144, 144, 140)
$sand-dark1: rgb(22, 22, 21)
$sand-dark10: rgb(127, 126, 119)
$sand-dark11: rgb(161, 160, 154)
$sand-dark12: rgb(237, 237, 236)
$sand-dark2: rgb(28, 28, 26)
$sand-dark3: rgb(35, 35, 32)
$sand-dark4: rgb(40, 40, 38)
$sand-dark5: rgb(46, 46, 43)
$sand-dark6: rgb(53, 52, 49)
$sand-dark7: rgb(62, 62, 58)
$sand-dark8: rgb(81, 80, 75)
$sand-dark9: rgb(113, 112, 105)
$sky1: rgb(249, 254, 255)
$sky10: rgb(95, 212, 244)
$sky11: rgb(0, 120, 161)
$sky12: rgb(0, 50, 66)
$sky2: rgb(241, 252, 255)
$sky3: rgb(228, 249, 255)
$sky4: rgb(213, 244, 253)
$sky5: rgb(193, 236, 249)
$sky6: rgb(164, 223, 241)
$sky7: rgb(121, 207, 234)
$sky8: rgb(46, 189, 229)
$sky9: rgb(104, 221, 253)
$sky-dark1: rgb(12, 24, 32)
$sky-dark10: rgb(138, 232, 255)
$sky-dark11: rgb(46, 200, 238)
$sky-dark12: rgb(234, 248, 255)
$sky-dark2: rgb(7, 29, 42)
$sky-dark3: rgb(8, 38, 54)
$sky-dark4: rgb(8, 45, 65)
$sky-dark5: rgb(8, 53, 76)
$sky-dark6: rgb(8, 62, 89)
$sky-dark7: rgb(6, 75, 107)
$sky-dark8: rgb(0, 93, 133)
$sky-dark9: rgb(104, 221, 253)
$teal1: rgb(250, 254, 253)
$teal10: rgb(14, 152, 136)
$teal11: rgb(6, 122, 111)
$teal12: rgb(16, 48, 43)
$teal2: rgb(241, 252, 250)
$teal3: rgb(231, 249, 245)
$teal4: rgb(217, 243, 238)
$teal5: rgb(199, 235, 229)
$teal6: rgb(175, 223, 215)
$teal7: rgb(141, 206, 195)
$teal8: rgb(83, 185, 171)
$teal9: rgb(18, 165, 148)
$teal-dark1: rgb(9, 25, 21)
$teal-dark10: rgb(16, 179, 163)
$teal-dark11: rgb(10, 197, 179)
$teal-dark12: rgb(225, 250, 244)
$teal-dark2: rgb(4, 32, 27)
$teal-dark3: rgb(6, 41, 35)
$teal-dark4: rgb(7, 49, 43)
$teal-dark5: rgb(8, 57, 50)
$teal-dark6: rgb(9, 68, 60)
$teal-dark7: rgb(11, 84, 74)
$teal-dark8: rgb(12, 109, 98)
$teal-dark9: rgb(18, 165, 148)
$text-inherited: rgb(112, 111, 108)
$text-set: rgb(27, 27, 24)
$text-unset: rgb(144, 144, 140)
$textboxdisabled: rgba(27, 27, 24, 0.5)
$textboxempty: rgb(112, 111, 108)
$tomato1: rgb(255, 252, 252)
$tomato10: rgb(219, 67, 36)
$tomato11: rgb(202, 50, 20)
$tomato12: rgb(52, 23, 17)
$tomato2: rgb(255, 248, 247)
$tomato3: rgb(255, 240, 238)
$tomato4: rgb(255, 230, 226)
$tomato5: rgb(253, 216, 211)
$tomato6: rgb(250, 199, 190)
$tomato7: rgb(243, 176, 162)
$tomato8: rgb(234, 146, 128)
$tomato9: rgb(229, 77, 46)
$tomato-dark1: rgb(29, 20, 18)
$tomato-dark10: rgb(236, 94, 65)
$tomato-dark11: rgb(241, 106, 80)
$tomato-dark12: rgb(254, 239, 236)
$tomato-dark2: rgb(42, 20, 16)
$tomato-dark3: rgb(59, 24, 19)
$tomato-dark4: rgb(72, 26, 20)
$tomato-dark5: rgb(84, 28, 21)
$tomato-dark6: rgb(101, 32, 22)
$tomato-dark7: rgb(127, 35, 21)
$tomato-dark8: rgb(164, 42, 18)
$tomato-dark9: rgb(229, 77, 46)
$transparent: rgba(255, 255, 255, 0)
$violet1: rgb(253, 252, 254)
$violet10: rgb(100, 79, 193)
$violet11: rgb(87, 70, 175)
$violet12: rgb(32, 19, 75)
$violet2: rgb(251, 250, 255)
$violet3: rgb(245, 242, 255)
$violet4: rgb(237, 233, 254)
$violet5: rgb(228, 222, 252)
$violet6: rgb(215, 207, 249)
$violet7: rgb(196, 184, 243)
$violet8: rgb(170, 153, 236)
$violet9: rgb(110, 86, 207)
$violet-dark1: rgb(23, 21, 31)
$violet-dark10: rgb(124, 102, 220)
$violet-dark11: rgb(158, 140, 252)
$violet-dark12: rgb(241, 238, 254)
$violet-dark2: rgb(28, 23, 43)
$violet-dark3: rgb(37, 30, 64)
$violet-dark4: rgb(44, 34, 80)
$violet-dark5: rgb(50, 39, 95)
$violet-dark6: rgb(57, 44, 114)
$violet-dark7: rgb(68, 53, 146)
$violet-dark8: rgb(88, 66, 195)
$violet-dark9: rgb(110, 86, 207)
$white: #FFFFFFrgb(255,255,255)rgb(255,255,255)rgb(255,255,255)rgb(255,255,255)rgb(255,255,255)
$white-a1: rgba(0, 0, 0, 0)
$white-a10: rgba(255, 255, 255, 0.45)
$white-a11: rgba(255, 255, 255, 0.59)
$white-a12: rgba(255, 255, 255, 0.92)
$white-a2: rgba(255, 255, 255, 0.01)
$white-a3: rgba(255, 255, 255, 0.03)
$white-a4: rgba(255, 255, 255, 0.06)
$white-a5: rgba(255, 255, 255, 0.09)
$white-a6: rgba(255, 255, 255, 0.12)
$white-a7: rgba(255, 255, 255, 0.18)
$white-a8: rgba(255, 255, 255, 0.25)
$white-a9: rgba(255, 255, 255, 0.39)
$yellow1: rgb(253, 253, 249)
$yellow10: rgb(247, 206, 0)
$yellow11: rgb(148, 104, 0)
$yellow12: rgb(53, 41, 15)
$yellow2: rgb(255, 252, 232)
$yellow3: rgb(255, 251, 209)
$yellow4: rgb(255, 248, 187)
$yellow5: rgb(254, 242, 164)
$yellow6: rgb(249, 230, 140)
$yellow7: rgb(239, 211, 108)
$yellow8: rgb(235, 188, 0)
$yellow9: rgb(245, 217, 10)
$yellow-dark1: rgb(28, 21, 0)
$yellow-dark10: rgb(255, 239, 92)
$yellow-dark11: rgb(240, 192, 0)
$yellow-dark12: rgb(255, 250, 209)
$yellow-dark2: rgb(34, 26, 0)
$yellow-dark3: rgb(44, 33, 0)
$yellow-dark4: rgb(53, 40, 0)
$yellow-dark5: rgb(62, 48, 0)
$yellow-dark6: rgb(73, 60, 0)
$yellow-dark7: rgb(89, 74, 5)
$yellow-dark8: rgb(112, 94, 0)
$yellow-dark9: rgb(245, 217, 10)
