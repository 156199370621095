.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.root___focusedWithin__focusVisibleWithin:focus-within {
  outline: none;
}
.root:hover:not(:focus-within) {
  outline: none;
}
.trigger {
  position: relative;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 7px;
  border: 1px solid var(--token-hoA5qaM-91G);
}
.trigger > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.trigger > :global(.__wab_flex-container) > *,
.trigger > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trigger > :global(.__wab_flex-container) > picture > img,
.trigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.triggerisOpen {
  background: #f5f5f5;
}
.triggerisDisabled {
  cursor: not-allowed;
}
.root:hover:not(:focus-within) .trigger {
  background: var(--token-bV4cCeIniS6);
  outline: none;
}
.root:focus-within .trigger___focusedWithin__focusVisibleWithin {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.slotTargetSelectedContent {
  white-space: pre;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: #8e8e8e;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-fVn5vRhXJxQ);
  flex-shrink: 0;
}
.root:hover:not(:focus-within) .dropdownIcon {
  color: var(--token-UunsGa2Y3t3);
  outline: none;
}
.root:focus-within .dropdownIcon___focusedWithin__focusVisibleWithin {
  color: var(--token-UunsGa2Y3t3);
  outline: none;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1000;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  overflow: auto;
}
.option__mdBtX:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.option___0Skue:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
