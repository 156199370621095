.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.freeBox__bnqn6 {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}
.text__aqvnZ {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  width: auto;
  height: auto;
  max-width: 800px;
  line-height: 1em;
}
.newDataSource:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .newDataSource:global(.__wab_instance) {
    position: relative;
  }
}
.newDataSourceviewer:global(.__wab_instance) {
  display: none;
}
.svg__cIasM {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-UunsGa2Y3t3);
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__cIasM {
    display: flex;
    position: relative;
    object-fit: cover;
    width: 16px;
    height: 16px;
    color: rgb(255, 255, 255);
  }
}
.svg__gDqeH {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.sources {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__sgxeZ {
  display: flex;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
}
.freeBoxviewer__sgxeZka1Nj {
  display: none;
}
.freeBoxshowApiKeys__sgxeZ0NBw {
  display: flex;
}
.text___8Xwoc {
  position: relative;
  font-weight: 700;
  font-size: 32px;
}
.newApiKey:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .newApiKey:global(.__wab_instance) {
    position: relative;
  }
}
.newApiKeyviewer:global(.__wab_instance) {
  display: none;
}
.svg__h7RPf {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(255, 255, 255);
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__h7RPf {
    display: flex;
    position: relative;
    object-fit: cover;
    width: 16px;
    height: 16px;
    color: rgb(255, 255, 255);
  }
}
.text___2VnNh {
  height: auto;
}
.svg__pMpmB {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.freeBox__zi9Y9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxshowApiKeys__zi9Y90NBw {
  display: flex;
}
