.root {
  display: inline-flex;
  position: relative;
  background: var(--token-O4S7RMTqZ3);
  min-height: 2rem;
  width: auto;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
  border-width: 0px;
}
.root > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
}
.roottype_primary {
  background: var(--token-D666zt2IZPL);
}
.roottype_link {
  background: var(--token-brSQU2ryS);
  cursor: pointer;
  height: auto;
  min-height: auto;
  border-radius: 2px;
  padding: 0px;
}
.roottype_backlitError {
  background: var(--token-iR8SeEwQZ);
}
.roottype_backlitInfo {
  background: var(--token-iR8SeEwQZ);
}
.roottype_toggleOn {
  background: var(--token-bV4cCeIniS6);
}
.roottype_toggleOff {
  background: var(--token-iR8SeEwQZ);
}
.roottype_clear {
  background: var(--token-brSQU2ryS);
  border: 0px solid #ffffff00;
}
.roottype_clearPrimary {
  background: var(--token-brSQU2ryS);
  border: 0px solid #ffffff00;
}
.roottype_seamless {
  background: var(--token-brSQU2ryS);
  min-height: 1rem;
  border-radius: 2px;
  padding: 0px;
}
.roottype_chip {
  border-radius: 16px;
  padding: 6px 12px;
}
.roottype_hasCaption {
  flex-direction: column;
}
.roottype_hasCaption > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.roottype_hasCaption > :global(.__wab_flex-container) > *,
.roottype_hasCaption
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.roottype_hasCaption > :global(.__wab_flex-container) > picture > img,
.roottype_hasCaption
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.roottype_switchOn {
  box-shadow: 0px 2px 4px 0px var(--token-JrjdlBU-a5Ju),
    0px 0px 0px 0px var(--token-zBV3PmIqbJ9F);
  background: var(--token-iR8SeEwQZ);
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 1.75rem;
  border-radius: 4px;
}
.roottype_bordered {
  border: 1px solid var(--token-eBt2ZgqRUCz);
}
.rootwithIcons_startIcon {
  padding-left: 6px;
}
.rootwithIcons_endIcon {
  padding-right: 6px;
  flex-direction: row;
}
.rootwithIcons_endIcon > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootwithIcons_endIcon > :global(.__wab_flex-container) > *,
.rootwithIcons_endIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootwithIcons_endIcon > :global(.__wab_flex-container) > picture > img,
.rootwithIcons_endIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.rootwithIcons_endIconOnHover {
  flex-direction: row;
}
.rootwithIcons_endIconOnHover > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootwithIcons_endIconOnHover > :global(.__wab_flex-container) > *,
.rootwithIcons_endIconOnHover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootwithIcons_endIconOnHover > :global(.__wab_flex-container) > picture > img,
.rootwithIcons_endIconOnHover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.rootsize_small {
  min-height: 1.75rem;
  padding: 6px 8px;
}
.rootsize_stretch {
  width: 100%;
  min-width: 0;
}
.rootsize_stretch > :global(.__wab_flex-container) {
  min-width: 0;
}
.rootsize_wide {
  padding-left: 12px;
  padding-right: 12px;
}
.rootsize_compact {
  padding-left: 6px;
  padding-right: 6px;
}
.rootsize_medium {
  padding-top: 6px;
  padding-bottom: 6px;
}
.rootdisabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.rootpointerCursor {
  cursor: pointer;
}
.rootcolor_blue {
  background: var(--token-yqAf_E0HIjU);
}
.rootcolor_green {
  background: var(--token-h-5XbcO2WhcA);
}
.rootcolor_red {
  background: var(--token-qYuX4XAEbRt);
}
.rootcolor_purple {
  background: var(--token-Cr1odk81N3Y);
}
.rootcolor_darkRed {
  background: var(--token-iDpByWoW0eF);
}
.rootsize_small_withIcons_startIcon {
  padding-left: 4px;
}
.rootsize_small_withIcons_endIcon {
  padding-right: 4px;
}
.rootsize_wide_withIcons_startIcon {
  padding-left: 6px;
}
.rootwithIcons_endIconOnHover_size_small {
  padding-right: 4px;
}
.rootwithIcons_endIcon_size_wide {
  padding-right: 6px;
}
.rootsize_compact_withIcons_startIcon {
  padding-left: 4px;
}
.rootwithIcons_endIcon_size_compact {
  padding-right: 4px;
}
.rootwithIcons_endIconOnHover_size_wide {
  padding-right: 6px;
}
.rootwithIcons_endIconOnHover_size_compact {
  padding-right: 4px;
}
.roottype_bordered_color_green {
  background: var(--token-iR8SeEwQZ);
  border-style: solid;
  border-color: var(--token-n4S9_H7k9rsR);
}
.roottype_bordered_color_red {
  background: var(--token-iR8SeEwQZ);
  border-style: solid;
  border-color: var(--token-yUAjM3D7ZnY);
}
.root:hover {
  background: var(--token-bV4cCeIniS6);
}
.roottype_primary:hover {
  background: var(--token-mu3x63xzJRW);
}
.roottype_link:hover {
  background: #ffffff00;
}
.roottype_backlitError:hover {
  background: var(--token-HKVCQ5ZKovK);
}
.roottype_backlitInfo:hover {
  background: var(--token-dqEx_KxIoYV);
}
.roottype_toggleOn:hover {
  border-style: solid;
  border-color: #dbdbd7;
}
.roottype_toggleOff:hover {
  background: var(--token-bV4cCeIniS6);
  border-style: solid;
  border-color: #dbdbd7;
}
.roottype_clearPrimary:hover {
  background: var(--token-dqEx_KxIoYV);
}
.roottype_seamless:hover {
  background: none;
}
.rootwithIcons_endIconOnHover:hover {
  padding-right: 6px;
  background: none;
}
.rootwithIcons_endIconOnHover:hover > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootwithIcons_endIconOnHover:hover > :global(.__wab_flex-container) > *,
.rootwithIcons_endIconOnHover:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootwithIcons_endIconOnHover:hover
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootwithIcons_endIconOnHover:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.rootcolor_blue:hover {
  background: var(--token-dqEx_KxIoYV);
}
.rootcolor_green:hover {
  background: var(--token-dv0BWWyaHl7H);
}
.rootcolor_red:hover {
  background: var(--token-HKVCQ5ZKovK);
}
.rootcolor_purple:hover {
  background: var(--token-oPrqrxbKHqk);
}
.rootcolor_darkRed:hover {
  background: var(--token-Y2CWh0ci95a);
}
.root:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.roottype_primary:focus {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.roottype_link:focus {
  outline: none;
}
.roottype_clearPrimary:focus {
  outline: none;
}
.rootwithIcons_endIconOnHover:focus {
  box-shadow: none;
  outline: none;
}
.root:active {
  background: var(--token-Ik3bdE1e1Uy);
}
.roottype_primary:active {
  background: var(--token-VUsIDivgUss);
}
.roottype_link:active {
  background: none;
}
.roottype_backlitError:active {
  background: var(--token-5kjtdCiiOPB);
}
.roottype_backlitInfo:active {
  background: var(--token-RhvOnhv_xIi);
}
.roottype_clearPrimary:active {
  background: var(--token-RhvOnhv_xIi);
}
.roottype_seamless:active {
  background: none;
}
.roottype_noPressed:active {
  background: none;
}
.rootcolor_blue:active {
  background: var(--token-RhvOnhv_xIi);
}
.rootcolor_green:active {
  background: var(--token-3AptjBfMqvPS);
}
.rootcolor_red:active {
  background: var(--token-5kjtdCiiOPB);
}
.rootcolor_purple:active {
  background: var(--token-I2zAJ678hbp);
}
.rootcolor_darkRed:active {
  background: var(--token-Y2CWh0ci95a);
}
.startIconContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  margin-right: 4px;
}
.slotTargetStartIcon {
  font-size: 1rem;
}
.slotTargetStartIcontype_primary {
  color: rgb(255, 255, 255);
}
.slotTargetStartIcontype_link {
  color: var(--token-VUsIDivgUss);
}
.slotTargetStartIcontype_backlitError {
  color: var(--token-Y2CWh0ci95a);
}
.slotTargetStartIcontype_backlitInfo {
  color: var(--token-VUsIDivgUss);
}
.slotTargetStartIconwithIcons_startIcon {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetStartIconfont_dim {
  color: rgb(122, 125, 130);
}
.slotTargetStartIconcolor_blue {
  color: var(--token-VUsIDivgUss);
}
.slotTargetStartIconcolor_green {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetStartIconcolor_red {
  color: var(--token-Y2CWh0ci95a);
}
.slotTargetStartIconcolor_purple {
  color: var(--token-UCbZU41E8Mj);
}
.slotTargetStartIcontype_primary_withIcons_startIcon {
  color: var(--token-iR8SeEwQZ);
}
.slotTargetStartIcontype_clearPrimary_withIcons_startIcon {
  color: var(--token-VUsIDivgUss);
}
.slotTargetStartIconsize_medium_withIcons_startIcon {
  font-size: 1.25rem;
}
.rootwithIcons_startIcon:hover .slotTargetStartIconwithIcons_startIcon {
  color: var(--token-0IloF6TmFvF);
}
.rootcolor_green:hover .slotTargetStartIconcolor_green {
  color: var(--token-hIvqQBCxyWwX);
}
.rootcolor_red:hover .slotTargetStartIconcolor_red {
  color: var(--token-Y2CWh0ci95a);
}
.roottype_backlitError_withIcons_startIcon:hover
  .slotTargetStartIcontype_backlitError_withIcons_startIcon {
  color: var(--token-Y2CWh0ci95a);
}
.roottype_clearPrimary_withIcons_startIcon:hover
  .slotTargetStartIcontype_clearPrimary_withIcons_startIcon {
  color: var(--token-VUsIDivgUss);
}
.rootwithIcons_endIconOnHover_withIcons_startIcon:hover
  .slotTargetStartIconwithIcons_endIconOnHover_withIcons_startIcon {
  color: var(--token-UunsGa2Y3t3);
}
.rootdisabled_withIcons_startIcon:hover
  .slotTargetStartIcondisabled_withIcons_startIcon {
  color: var(--token-UunsGa2Y3t3);
}
.svg__lWcVt {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.freeBoxtype_leftAligned {
  width: 100%;
  min-width: 0;
}
.slotTargetChildren {
  font-size: 12px;
  line-height: 1.25;
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildrentype_primary {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: rgb(255, 255, 255);
}
.slotTargetChildrentype_link {
  color: var(--token-VUsIDivgUss);
}
.slotTargetChildrentype_secondary {
  font-weight: 500;
}
.slotTargetChildrentype_backlitError {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-Y2CWh0ci95a);
}
.slotTargetChildrentype_backlitInfo {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-VUsIDivgUss);
}
.slotTargetChildrentype_toggleOn {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
}
.slotTargetChildrentype_clearPrimary {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-VUsIDivgUss);
}
.slotTargetChildrenfont_bold {
  font-weight: 500;
}
.slotTargetChildrenfont_dim {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetChildrensize_small {
  font-size: 11px;
}
.slotTargetChildrencolor_blue {
  color: var(--token-iP3LxEvLa0xh);
}
.slotTargetChildrencolor_green {
  color: var(--token-AKguBXlLTkKh);
}
.slotTargetChildrencolor_red {
  color: var(--token-iP3LxEvLa0xh);
}
.slotTargetChildrencolor_purple {
  color: var(--token-ZaJGjVCo3Ue);
}
.slotTargetChildrencolor_darkRed {
  color: var(--token-9jh0BkCENS);
}
.roottype_link:hover .slotTargetChildrentype_link > :global(.__wab_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.root:focus .slotTargetChildren > *,
.root:focus .slotTargetChildren > :global(.__wab_slot) > *,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetChildren > picture > img,
.root:focus .slotTargetChildren > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.roottype_primary:focus .slotTargetChildrentype_primary > *,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_primary:focus .slotTargetChildrentype_primary > picture > img,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_primary:focus
  .slotTargetChildrentype_primary
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.roottype_link:focus .slotTargetChildrentype_link > *,
.roottype_link:focus .slotTargetChildrentype_link > :global(.__wab_slot) > *,
.roottype_link:focus
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_link:focus
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_link:focus .slotTargetChildrentype_link > picture > img,
.roottype_link:focus
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_link:focus
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_link:focus
  .slotTargetChildrentype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.rootcolor_green:focus .slotTargetChildrencolor_green > *,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > *,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_green:focus .slotTargetChildrencolor_green > picture > img,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_green:focus
  .slotTargetChildrencolor_green
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.rootcolor_red:focus .slotTargetChildrencolor_red > *,
.rootcolor_red:focus .slotTargetChildrencolor_red > :global(.__wab_slot) > *,
.rootcolor_red:focus
  .slotTargetChildrencolor_red
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_red:focus
  .slotTargetChildrencolor_red
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_red:focus .slotTargetChildrencolor_red > picture > img,
.rootcolor_red:focus
  .slotTargetChildrencolor_red
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_red:focus
  .slotTargetChildrencolor_red
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_red:focus
  .slotTargetChildrencolor_red
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.rootcolor_purple:focus .slotTargetChildrencolor_purple > *,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > *,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.rootcolor_purple:focus .slotTargetChildrencolor_purple > picture > img,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.rootcolor_purple:focus
  .slotTargetChildrencolor_purple
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetCaption {
  font-size: 11px;
  line-height: 1.5;
  white-space: pre;
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetCaption > :global(.__wab_text),
.slotTargetCaption > :global(.__wab_expr_html_text),
.slotTargetCaption > :global(.__wab_slot-string-wrapper),
.slotTargetCaption > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetCaption > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetCaption > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetCaption > *,
.slotTargetCaption > :global(.__wab_slot) > *,
.slotTargetCaption > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetCaption > picture > img,
.slotTargetCaption > :global(.__wab_slot) > picture > img,
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetCaptiontype_primary {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: rgb(255, 255, 255);
}
.slotTargetCaptiontype_link {
  color: var(--token-VUsIDivgUss);
}
.slotTargetCaptiontype_secondary {
  font-weight: 500;
}
.slotTargetCaptiontype_backlitError {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-Y2CWh0ci95a);
}
.slotTargetCaptiontype_backlitInfo {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-VUsIDivgUss);
}
.slotTargetCaptiontype_toggleOn {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
}
.slotTargetCaptiontype_clearPrimary {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--token-VUsIDivgUss);
}
.slotTargetCaptionfont_bold {
  font-weight: 500;
}
.slotTargetCaptionfont_dim {
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetCaptionsize_small {
  font-size: 11px;
}
.slotTargetCaptioncolor_blue {
  color: var(--token-VUsIDivgUss);
}
.slotTargetCaptioncolor_green {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetCaptioncolor_red {
  color: var(--token-hIvqQBCxyWwX);
}
.slotTargetCaptioncolor_purple {
  color: var(--token-hIvqQBCxyWwX);
}
.roottype_link:hover .slotTargetCaptiontype_link > :global(.__wab_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.roottype_link:hover
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.root:focus .slotTargetCaption > *,
.root:focus .slotTargetCaption > :global(.__wab_slot) > *,
.root:focus
  .slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus
  .slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus .slotTargetCaption > picture > img,
.root:focus .slotTargetCaption > :global(.__wab_slot) > picture > img,
.root:focus
  .slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus
  .slotTargetCaption
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.roottype_link:focus .slotTargetCaptiontype_link > *,
.roottype_link:focus .slotTargetCaptiontype_link > :global(.__wab_slot) > *,
.roottype_link:focus
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_link:focus
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.roottype_link:focus .slotTargetCaptiontype_link > picture > img,
.roottype_link:focus
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_link:focus
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.roottype_link:focus
  .slotTargetCaptiontype_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.endIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  align-self: stretch;
}
.endIconContainerwithIcons_endIconOnHover {
  display: none;
}
.rootwithIcons_endIconOnHover:hover .endIconContainerwithIcons_endIconOnHover {
  display: flex;
}
.slotTargetEndIcon {
  font-size: 1rem;
  color: var(--token-fVn5vRhXJxQ);
}
.slotTargetEndIcontype_primary {
  color: rgb(255, 255, 255);
}
.slotTargetEndIcontype_link {
  color: var(--token-D666zt2IZPL);
}
.slotTargetEndIcontype_backlitError {
  color: var(--token-iDpByWoW0eF);
}
.slotTargetEndIcontype_backlitInfo {
  color: var(--token-D666zt2IZPL);
}
.slotTargetEndIcontype_clearPrimary {
  color: var(--token-D666zt2IZPL);
}
.slotTargetEndIconcolor_blue {
  color: var(--token-D666zt2IZPL);
}
.slotTargetEndIconcolor_green {
  color: var(--token-GEBK7U4Am62W);
}
.slotTargetEndIconcolor_red {
  color: var(--token-iDpByWoW0eF);
}
.slotTargetEndIconcolor_purple {
  color: var(--token-Izr3_jwKAp1);
}
.slotTargetEndIconsize_medium_withIcons_endIcon {
  font-size: 1.25rem;
}
.slotTargetEndIconsize_medium_withIcons_endIconOnHover {
  font-size: 1.25rem;
}
.svg__jsRJx {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
