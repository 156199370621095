.root {
  display: flex;
  position: relative;
  background: var(--token-9jh0BkCENS);
  width: 160px;
  flex-shrink: 0;
  flex-direction: column;
  border-right: 1px solid var(--token-hoA5qaM-91G);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 5px;
}
.rootisWide {
  width: 250px;
}
.runAllSteps:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.runAllStepspreviewSteps:global(.__wab_instance) {
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  color: var(--token-oI9RmKl5Rl_y);
}
.text {
  padding-bottom: 0px;
}
.svg__w7J7L {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-x: visible;
  overflow-y: auto;
  width: 100%;
  min-width: 0;
}
.dataPickerColumnItem__mnPLv:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dataPickerColumnItem__cKtme:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dataPickerColumnItem__jyz3V:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dataPickerColumnItem__dPje:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dataPickerColumnItem__x25Vl:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
