.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  padding: 1.5rem;
}
.rootisCollapsed {
  display: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.text__l8Prb {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
}
.actions {
  display: flex;
  position: relative;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: unset;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.newButton:global(.__wab_instance) {
  position: relative;
}
.svg___0MNgN {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__lPfIg {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 1em;
}
.filterSelect:global(.__wab_instance) {
  position: relative;
  width: 128px;
  flex-shrink: 0;
}
.option__egmzu:global(.__wab_instance) {
  position: relative;
}
.option___6MrRo:global(.__wab_instance) {
  position: relative;
}
.option__q1Ek5:global(.__wab_instance) {
  position: relative;
}
.option__oBlVc:global(.__wab_instance) {
  position: relative;
}
.option__xaQz:global(.__wab_instance) {
  position: relative;
}
.option___4VAek:global(.__wab_instance) {
  position: relative;
}
.option___7OsFo:global(.__wab_instance) {
  position: relative;
}
.svg___63J2T {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.memberSearch:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
  width: 200px;
  flex-shrink: 0;
}
.header {
  display: flex;
  position: relative;
  padding: 16px;
}
.header > :global(.__wab_flex-container) {
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text__cuGtX {
  position: relative;
  width: 30%;
}
.text___0Cf9 {
  position: relative;
  width: 15%;
}
.text__qi9E0 {
  position: relative;
  width: 15%;
}
.text__k2CEs {
  position: relative;
  width: 40%;
}
.teamMemberListItem__xV1F:global(.__wab_instance) {
  position: relative;
}
.text__glRe0 {
  font-size: 11px;
  line-height: 1.5;
}
.teamMemberListItem__df22M:global(.__wab_instance) {
  position: relative;
}
.text__f6B5V {
  height: auto;
}
.teamMemberListItem___9HzOo:global(.__wab_instance) {
  position: relative;
}
.text__j0Ij9 {
  height: auto;
}
.teamMemberListItem__blTpg:global(.__wab_instance) {
  position: relative;
}
.text__bf6Im {
  height: auto;
}
