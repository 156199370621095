.root {
  display: flex;
  flex-direction: row;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox {
  display: flex;
  flex-direction: row;
  position: relative;
  align-self: auto;
  width: auto;
  height: auto;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root:hover .freeBox > :global(.__wab_flex-container) {
  align-items: center;
}
.owner {
  position: relative;
  width: 128px;
  min-height: 2rem;
  flex-shrink: 0;
  padding: 8px;
}
.ownerrole_owner {
  color: var(--token-2kgsBg0W9R8);
}
.deleteBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.root:hover .deleteBtn:global(.__wab_instance) {
  display: flex;
}
.rootrole_owner:hover .deleteBtnrole_owner:global(.__wab_instance) {
  display: none;
}
.svg__oRYw {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svg___2OKoH {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-UunsGa2Y3t3);
  height: 1em;
}
.roleDropdown:global(.__wab_instance) {
  position: relative;
  width: 128px;
  flex-shrink: 0;
}
.option__clhZ3:global(.__wab_instance) {
  position: relative;
}
.option__wZq0D:global(.__wab_instance) {
  position: relative;
}
.option__xCsAc:global(.__wab_instance) {
  position: relative;
}
.option__ncavV:global(.__wab_instance) {
  position: relative;
}
.svg__ijqCo {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.svg__vmeQb {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
