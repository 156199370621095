.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.defaultLayout:global(.__wab_instance) {
  position: relative;
}
.projectList:global(.__wab_instance) {
  position: relative;
}
.img__zrryr {
  object-fit: cover;
  width: 24px;
  height: 24px;
  max-width: 24px;
  border-radius: 50%;
}
.img__zrryr > picture > img {
  object-fit: cover;
}
.navTeamSection:global(.__wab_instance) {
  position: relative;
}
.svg__bcoDd {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
.svg__ytJx3 {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}
