.root {
  display: flex;
  width: 250px;
  height: auto;
  flex-direction: column;
  overflow: visible;
  background: #ffffff;
  flex-column-gap: 0px;
  box-shadow: 0px 8px 32px -8px #00000038,
    0px 8px 20px -16px #00000024 /* plasmic-token: XeFw4MGauXBT, JrjdlBU-a5Ju */;
  position: relative;
  border-radius: 8px;
}
.leftSearchPanel:global(.__wab_instance) {
  position: relative;
}
.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: stretch;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 4px;
  min-width: 0;
}
.listSectionHeader__jL8:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg___79RLv {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__edwE:global(.__wab_instance) {
  position: relative;
}
.svg__cv6Ny {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem__gOkt:global(.__wab_instance) {
  position: relative;
}
.svg__dVxk9 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__ufmd0 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton___5Gli:global(.__wab_instance) {
  position: relative;
}
.svg__hFvRt {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__xTec {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__xTec > picture > img {
  object-fit: cover;
}
.img__xTec > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator__shd2:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__tPcP:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__wKpYo {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__gwGt:global(.__wab_instance) {
  position: relative;
}
.svg__cpQ3K {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem___7GMI:global(.__wab_instance) {
  position: relative;
}
.svg__lHrDq {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__qmCOn {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__xIblo:global(.__wab_instance) {
  position: relative;
}
.svg__nBiS7 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__hWsla {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__hWsla > picture > img {
  object-fit: cover;
}
.img__hWsla > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__jtA5P:global(.__wab_instance) {
  position: relative;
}
.svg__wc5Vo {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__rAa1T {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__wvc16:global(.__wab_instance) {
  position: relative;
}
.svg__hFxp {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__khVaZ {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__khVaZ > picture > img {
  object-fit: cover;
}
.img__khVaZ > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator__tnazP:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__fjgsH:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__iyE2G {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__dj1U2:global(.__wab_instance) {
  position: relative;
}
.svg___6TpUg {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem___3UHn:global(.__wab_instance) {
  position: relative;
}
.svg__h3Co5 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__hljV {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__biqL:global(.__wab_instance) {
  position: relative;
}
.svg__mr3Tg {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img___2EpwU {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___2EpwU > picture > img {
  object-fit: cover;
}
.img___2EpwU > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__g1BHu:global(.__wab_instance) {
  position: relative;
}
.svg___44ED3 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__mvhEt {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__fSn7A:global(.__wab_instance) {
  position: relative;
}
.svg__lsUuS {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__yv4GX {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__yv4GX > picture > img {
  object-fit: cover;
}
.img__yv4GX > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__ebXz:global(.__wab_instance) {
  position: relative;
}
.svg___9Yq8A {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg___7FEuJ {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__nCSai:global(.__wab_instance) {
  position: relative;
}
.svg__cZdP9 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__cpYaG {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__cpYaG > picture > img {
  object-fit: cover;
}
.img__cpYaG > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__bciD9:global(.__wab_instance) {
  position: relative;
}
.svg__mJisj {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__jOfi8 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__auUnh:global(.__wab_instance) {
  position: relative;
}
.svg__ry53C {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__sU7Hz {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__sU7Hz > picture > img {
  object-fit: cover;
}
.img__sU7Hz > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__mpPMh:global(.__wab_instance) {
  position: relative;
}
.svg__uhDox {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__oR8N {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__pC6Uv:global(.__wab_instance) {
  position: relative;
}
.svg__yXdU8 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__qyrgv {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__qyrgv > picture > img {
  object-fit: cover;
}
.img__qyrgv > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__ybvgR:global(.__wab_instance) {
  position: relative;
}
.svg___9RvcA {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__jYj9D {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__cnN2:global(.__wab_instance) {
  position: relative;
}
.svg___4NQy {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__rFqTa {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__rFqTa > picture > img {
  object-fit: cover;
}
.img__rFqTa > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator__axDp:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__dntNb:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__drKjq {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton___8P9Bz:global(.__wab_instance) {
  position: relative;
}
.svg__wD0Jw {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem___03X7:global(.__wab_instance) {
  position: relative;
}
.svg__mlEo5 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg___6MsiS {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__aZjZc:global(.__wab_instance) {
  position: relative;
}
.svg__fwOgv {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img___2Kt2R {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___2Kt2R > picture > img {
  object-fit: cover;
}
.img___2Kt2R > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__e8NG:global(.__wab_instance) {
  position: relative;
}
.svg__zwEw {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__tpMtp {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__evXdY:global(.__wab_instance) {
  position: relative;
}
.svg___7YOdY {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__nqom6 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__nqom6 > picture > img {
  object-fit: cover;
}
.img__nqom6 > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator___6Uip:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__mBXq2:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__sfZ8W {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__aZWf:global(.__wab_instance) {
  position: relative;
}
.svg__fq6YP {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem__d08Mt:global(.__wab_instance) {
  position: relative;
}
.svg__n2MbN {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__by05T {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__b4TF:global(.__wab_instance) {
  position: relative;
}
.svg__yt6S {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__jf5Tn {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__jf5Tn > picture > img {
  object-fit: cover;
}
.img__jf5Tn > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem___9DKx:global(.__wab_instance) {
  position: relative;
}
.svg__khew5 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__m3Em1 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__rimuA:global(.__wab_instance) {
  position: relative;
}
.svg__ukjw {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__sI4 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__sI4 > picture > img {
  object-fit: cover;
}
.img__sI4 > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem___2PtF9:global(.__wab_instance) {
  position: relative;
}
.svg__uV9ZL {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__diYeR {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__moS4:global(.__wab_instance) {
  position: relative;
}
.svg__iCrMg {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img___0A4Xc {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___0A4Xc > picture > img {
  object-fit: cover;
}
.img___0A4Xc > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__pQm3H:global(.__wab_instance) {
  position: relative;
}
.svg__h1UgB {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__as3Uc {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__qJupl:global(.__wab_instance) {
  position: relative;
}
.svg___9PSfz {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img___5ABvB {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___5ABvB > picture > img {
  object-fit: cover;
}
.img___5ABvB > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__mzba:global(.__wab_instance) {
  position: relative;
}
.svg__lczw {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__jMu0 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__xnoVz:global(.__wab_instance) {
  position: relative;
}
.svg__aJ1E6 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__rUtn5 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__rUtn5 > picture > img {
  object-fit: cover;
}
.img__rUtn5 > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__reimP:global(.__wab_instance) {
  position: relative;
}
.svg__obwhX {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__vlnmz {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__l4VQp:global(.__wab_instance) {
  position: relative;
}
.svg___1Zowh {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__v9RgC {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__v9RgC > picture > img {
  object-fit: cover;
}
.img__v9RgC > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator___5TREf:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__lFm1N:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__akKvv {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__pukfy:global(.__wab_instance) {
  position: relative;
}
.svg__i66Xp {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem___3FB9B:global(.__wab_instance) {
  position: relative;
}
.svg__gsMUc {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__kOet6 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.iconButton__zw5Ex:global(.__wab_instance) {
  position: relative;
}
.svg__tvOlh {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img___3B4ZT {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___3B4ZT > picture > img {
  object-fit: cover;
}
.img___3B4ZT > .__wab_img-spacer > img {
  object-fit: cover;
}
.listSectionSeparator__pHgDl:global(.__wab_instance) {
  position: relative;
  margin-top: 8px;
  flex-shrink: 0;
}
.listSectionHeader__xEmQc:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg___0A6SI {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: rgb(150, 153, 158);
}
.iconButton__odSi4:global(.__wab_instance) {
  position: relative;
}
.svg__lnIbj {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.addDrawerItem__aItZi:global(.__wab_instance) {
  position: relative;
}
.svg___3GI21 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__kkZrF {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton232222222222322:global(.__wab_instance) {
  position: relative;
}
.svg__tfSww {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__gKhIs {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__gKhIs > picture > img {
  object-fit: cover;
}
.img__gKhIs > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem___9WRqQ:global(.__wab_instance) {
  position: relative;
}
.svg___1ClMz {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__icLPl {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton2322222222223222:global(.__wab_instance) {
  position: relative;
}
.svg__oCx {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__mJt6E {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__mJt6E > picture > img {
  object-fit: cover;
}
.img__mJt6E > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__gu03X:global(.__wab_instance) {
  position: relative;
}
.svg__xQTs {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__eJtMs {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton23222222222232222:global(.__wab_instance) {
  position: relative;
}
.svg__ey6L0 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__gxhnB {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__gxhnB > picture > img {
  object-fit: cover;
}
.img__gxhnB > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__wMAol:global(.__wab_instance) {
  position: relative;
}
.svg___2AppX {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__wQjvn {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton232222222222322222:global(.__wab_instance) {
  position: relative;
}
.svg__sUnZ {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__bmZrC {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__bmZrC > picture > img {
  object-fit: cover;
}
.img__bmZrC > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__zeOZs:global(.__wab_instance) {
  position: relative;
}
.svg__bb0Oo {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__z48Ym {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton23222222222232:global(.__wab_instance) {
  position: relative;
}
.svg__l8Ht9 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__aGtDa {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__aGtDa > picture > img {
  object-fit: cover;
}
.img__aGtDa > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__aw21I:global(.__wab_instance) {
  position: relative;
}
.svg___5BeZm {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__p5CWf {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton232222222222:global(.__wab_instance) {
  position: relative;
}
.svg__dghs7 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__oUhu {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__oUhu > picture > img {
  object-fit: cover;
}
.img__oUhu > .__wab_img-spacer > img {
  object-fit: cover;
}
.addDrawerItem__gx59S:global(.__wab_instance) {
  position: relative;
}
.svg__c1VUw {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.svg__mHwI6 {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.appendButton22:global(.__wab_instance) {
  position: relative;
}
.svg__jTFvr {
  display: flex;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 16px;
  height: 16px;
}
.img__bdrO6 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__bdrO6 > picture > img {
  object-fit: cover;
}
.img__bdrO6 > .__wab_img-spacer > img {
  object-fit: cover;
}
