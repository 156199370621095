.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.top {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.toprelativePlacement_bottom {
  height: 2px;
  flex-shrink: 0;
}
.middle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  min-height: 0;
}
.left {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.leftrelativePlacement_right {
  width: 2px;
  flex-shrink: 0;
}
.main {
  box-shadow: 0px 8px 32px -8px var(--token-XeFw4MGauXBT),
    0px 8px 20px -16px var(--token-JrjdlBU-a5Ju);
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--token-iR8SeEwQZ);
  min-width: 0;
  border-radius: 4px;
}
.right {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.rightrelativePlacement_left {
  width: 1px;
  flex-shrink: 0;
}
.bottom {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.bottomrelativePlacement_top {
  height: 2px;
  flex-shrink: 0;
}
