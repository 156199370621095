.root {
  display: flex;
  flex-direction: column;
  position: relative;
}
.rootisDisabled {
  cursor: not-allowed;
}
.roottype_hugging {
  width: auto;
  display: inline-flex;
}
.roottype_seamless {
  width: auto;
  display: inline-flex;
}
.root:focus-within {
  outline: none;
}
.root___focusVisibleWithin {
  outline: none;
}
.trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px 6px 8px 8px;
  border: 0px solid var(--token-eBt2ZgqRUCz);
}
.triggerisOpen {
  background: var(--token-bV4cCeIniS6);
}
.triggerisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.triggertype_hugging {
  width: auto;
}
.triggertype_seamless {
  width: auto;
}
.triggertype_bordered {
  border-width: 1px;
}
.triggertype_wide {
  padding-left: 12px;
  padding-right: 8px;
}
.triggerhasIcon {
  padding-left: 8px;
}
.triggersize_small {
  padding: 6px 4px 6px 6px;
}
.triggersize_tiny {
  padding: 4px 2px 4px 4px;
}
.root:hover .trigger {
  background: var(--token-bV4cCeIniS6);
}
.roottype_bordered:hover .triggertype_bordered {
  border-color: var(--token-PTyaboLP9ZK);
}
.root:focus-within .trigger {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root .trigger___focusVisibleWithin {
  box-shadow: 0px 0px 0px 2px #0091ff80;
  outline: none;
}
.root:active .trigger {
  background: var(--token-Ik3bdE1e1Uy);
}
.root .trigger:active {
  background: var(--token-hoA5qaM-91G);
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.contentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.contentContainerhasIcon > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.contentContainerhasIcon > :global(.__wab_flex-container) > *,
.contentContainerhasIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.contentContainerhasIcon > :global(.__wab_flex-container) > picture > img,
.contentContainerhasIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.slotTargetIcon {
  color: var(--token-UunsGa2Y3t3);
}
.svg__dx11 {
  position: relative;
  object-fit: cover;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.slotTargetSelectedContent {
  font-size: 12px;
  line-height: 1.25;
  white-space: pre;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSelectedContentshowPlaceholder {
  color: rgb(188, 192, 196);
}
.slotTargetSelectedContentfont_bold {
  font-weight: 600;
}
.root:focus-within .slotTargetSelectedContent > *,
.root:focus-within .slotTargetSelectedContent > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetSelectedContent > picture > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: var(--token-UunsGa2Y3t3);
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.root:focus-within .slotTargetPlaceholder > *,
.root:focus-within .slotTargetPlaceholder > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetPlaceholder > picture > img,
.root:focus-within
  .slotTargetPlaceholder
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: 1rem;
  height: 1rem;
  color: var(--token-fVn5vRhXJxQ);
  flex-shrink: 0;
}
.dropdownIconisOpen {
  color: var(--token-UunsGa2Y3t3);
}
.dropdownIcontype_medium {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}
.root:focus-within .dropdownIcon {
  outline: none;
}
.root .dropdownIcon___focusVisibleWithin {
  outline: none;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  overflow: auto;
  cursor: default;
}
.optionsContainerisOpen {
  padding-top: 8px;
  padding-bottom: 8px;
}
.option__l4KlY:global(.__wab_instance) {
  position: relative;
}
.option__b77UA:global(.__wab_instance) {
  position: relative;
}
.optionGroup__seLyx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option___8WxB9:global(.__wab_instance) {
  position: relative;
}
.option___9KRws:global(.__wab_instance) {
  position: relative;
}
.optionGroup__xAYx9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__dvKOl:global(.__wab_instance) {
  position: relative;
}
.option___1IiGb:global(.__wab_instance) {
  position: relative;
}
.option__gEUdG:global(.__wab_instance) {
  position: relative;
}
