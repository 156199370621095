.root {
  width: 270px;
  height: 32px;
  display: block;
  position: relative;
  pointer-events: none;
}
.menuIndicator:global(.__wab_instance) {
  position: absolute;
  left: 100%;
  top: auto;
  transform-origin: 50% 50%;
  transform: translateX(0%) translateY(25%) translateZ(0px);
  right: auto;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.2s;
  bottom: 100%;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
}
.menuIndicatorinteractive:global(.__wab_instance) {
  opacity: 0.3;
  transform: translateX(0%) translateY(0%) translateZ(0px);
}
.menuIndicatoractive:global(.__wab_instance) {
  opacity: 1;
  transform: translateX(0%) translateY(0%) translateZ(0px);
}
.root:hover .menuIndicator:global(.__wab_instance) {
  opacity: 1;
  transform: translateX(0%) translateY(0%) translateZ(0px);
}
.freeBox {
  display: block;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.15s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.15s;
}
.freeBoxactive {
  opacity: 1;
}
.root:hover .freeBox {
  opacity: 1;
}
.boundingBoxHighlighter:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 0;
  right: 0;
  bottom: 0;
}
.root:hover .boundingBoxHighlighter:global(.__wab_instance) {
  opacity: 1;
}
