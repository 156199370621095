@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C700&display=swap");

.plasmic_default_styles {
  --mixin-qP3g6Hd5AdC_text-decoration-line: none;
  --mixin-qP3g6Hd5AdC_text-overflow: clip;
  --mixin-qP3g6Hd5AdC_font-family: "Inter";
  --mixin-qP3g6Hd5AdC_color: var(--token-0IloF6TmFvF);
  --mixin-qP3g6Hd5AdC_font-size: 11px;
  --mixin-qP3g6Hd5AdC_line-height: 16px;
  --mixin-m42MJsMLH_color: var(--token-D666zt2IZPL);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.img) {
  display: inline-block;
}
:where(.li) {
  display: list-item;
}
:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.address) {
  font-style: inherit;
}
:where(.a) {
  color: inherit;
}
:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.select) {
  padding: 2px 6px;
}
.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.root_reset {
  font-family: var(--mixin-qP3g6Hd5AdC_font-family);
  font-size: var(--mixin-qP3g6Hd5AdC_font-size);
  color: var(--mixin-qP3g6Hd5AdC_color);
  line-height: var(--mixin-qP3g6Hd5AdC_line-height);
  white-space: var(--mixin-qP3g6Hd5AdC_white-space);
}

:where(.root_reset .plasmic_default__a),
:where(.root_reset .a),
:where(.root_reset.plasmic_default__a) {
  color: var(--mixin-m42MJsMLH_color);
}

:where(.root_reset .plasmic_default__a:hover),
:where(.root_reset .a:hover),
:where(.root_reset.plasmic_default__a:hover) {
}
