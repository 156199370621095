.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: var(--token-aDa535tnF);
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  padding: 4px 8px;
}
.rootisSelected {
  background: var(--token-dqEx_KxIoYV);
}
.rootisHighlighted {
  background: var(--token-bV4cCeIniS6);
}
.rootisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.slotTargetChildren {
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetChildrenisSelected {
  font-weight: 500;
  color: var(--token-krbUYvO2lx2);
}
.slotTargetChildrenisUnsetOption {
  color: var(--token-4f35RzFNq);
}
