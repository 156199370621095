.root:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__bJFoi {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxshowInput__bJFoi6TmFq {
  display: flex;
}
.textbox:global(.__wab_instance) {
  max-width: 1100px;
  height: 40px;
}
.svg__cUAt {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg___6VJaF {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.freeBox___05Thu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  overflow: visible;
  width: 100%;
  max-width: 1100px;
  min-width: 0;
}
.cardPickerItem___7PX8D:global(.__wab_instance) {
  position: relative;
}
.img__kuaav {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: inline-block !important;
}
.img__kuaav > picture > img {
  object-fit: cover;
}
.img__kuaav > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.cardPickerItem__e44Y:global(.__wab_instance) {
  position: relative;
}
.img___6Yped {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: inline-block !important;
}
.img___6Yped > picture > img {
  object-fit: cover;
}
.img___6Yped > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.cardPickerItem__r8Mvp:global(.__wab_instance) {
  position: relative;
}
.img__oukL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: inline-block !important;
}
.img__oukL > picture > img {
  object-fit: cover;
}
.img__oukL > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.cardPickerItem__kadg:global(.__wab_instance) {
  position: relative;
}
.img__u6EpR {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: inline-block !important;
}
.img__u6EpR > picture > img {
  object-fit: cover;
}
.img__u6EpR > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.cancelButton:global(.__wab_instance) {
  position: relative;
}
.svg__srP7 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.text {
  color: var(--token-UunsGa2Y3t3);
}
.svg__qszen {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.saveButton:global(.__wab_instance) {
  position: relative;
  display: none;
}
.svg__vKndu {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
.svg__ovm4 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
}
