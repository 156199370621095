.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__dynXd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__dynXd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBox__dynXd > :global(.__wab_flex-container) > *,
.freeBox__dynXd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dynXd > :global(.__wab_flex-container) > picture > img,
.freeBox__dynXd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.actionBuilder__fWdQ4:global(.__wab_instance) {
  max-width: 100%;
}
.labeledItem__ps6Ir:global(.__wab_instance) {
  position: relative;
}
.text__ojzWh {
  padding: 0.5rem;
}
.textbox___0TYrx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wQVr8 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__zrbyP {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.text__u4N46 {
  padding: 0.5rem;
}
.textbox__uOqP3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zpTmz {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg___5HQcx {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.actionBuilder__cdJ1J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.labeledItem__eBEv:global(.__wab_instance) {
  position: relative;
}
.text__uZ7AV {
  padding: 0.5rem;
}
.textbox___3Vq4V:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kuax4 {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__amz4U {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.text__x8QxG {
  padding: 0.5rem;
}
.textbox__nz0Dz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__v3ZzK {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.svg__f9CcU {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
}
.freeBox__ysQx6 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px;
}
.freeBox__ysQx6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__ysQx6 > :global(.__wab_flex-container) > *,
.freeBox__ysQx6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ysQx6 > :global(.__wab_flex-container) > picture > img,
.freeBox__ysQx6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.addBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__u5Wg1 {
  display: flex;
  position: relative;
  object-fit: cover;
  color: var(--token-iR8SeEwQZ);
  height: 1em;
}
.svg__thuje {
  display: flex;
  position: relative;
  object-fit: cover;
  height: 1em;
}
